import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useAppDispatch } from 'src/js/slice/hooks'
import RouterController from 'src/js/router'

import { Box } from '@mui/material'
import { Amplify, Auth } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { ThemeProvider } from '@mui/material/styles'

import theme from './theme'
import Initialization from './Initialization'
import Message from './Message'
import SystemError from './SystemError'
import Backdrop from './Backdrop'
import SignInFooter from './SignInFooter'

import { systemError } from 'src/js/slice/App'

import '@aws-amplify/ui-react/styles.css'
import awsconfig from 'src/aws-exports'

// AWS-Amplifyコンフィグ
Amplify.configure(awsconfig)

const customerAuthComps = {
  // ヘッダー
  Header() {
    return <Box sx={{ pt: 23 }}></Box>
  },
  Footer() {
    return <SignInFooter />
  },
}

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(() => {
      Auth.signOut()
    })
  }, [])

  // エラー処理
  const onError = (error: Error, info: { componentStack: string }) => {
    // ログ出力
    console.log('error.message', error.message)
    console.log('info.componentStack:', info.componentStack)

    // エラー処理：ステータス更新
    dispatch(systemError())

    // sessionStorageクリーン
    window.sessionStorage.clear()
  }

  return (
    <Authenticator components={customerAuthComps}>
      <Initialization>
        <ThemeProvider theme={theme}>
          <ErrorBoundary FallbackComponent={SystemError} onError={onError}>
            <RouterController />
            <Backdrop />
            <Message />
          </ErrorBoundary>
        </ThemeProvider>
      </Initialization>
    </Authenticator>
  )
}

export default App
