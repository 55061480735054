import React from 'react'

import { setFilter } from 'src/js/slice/SYS0040'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { SYS0040 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* 名称 */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='name'
          label={RESOURCE.LBL_NAME}
          size='small'
          refer={{ funcId: FUNC.SYS0040, partId: 'filter', itemId: 'name' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* コメント */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='comment'
          label={RESOURCE.LBL_COMMENT}
          size='small'
          refer={{ funcId: FUNC.SYS0040, partId: 'filter', itemId: 'comment' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
