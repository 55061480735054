import React, { useEffect, useState, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'

import { RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import Filter from './Filter'
import Input from './Input'
import {
  search,
  input,
  initInput,
  gc,
  update,
  deleteById,
  cancelFilter,
  clearFilter,
} from 'src/js/slice/SYS0020'
import { isReadOnly, getFuncAuth } from 'src/js/util/utility'

import { SYS0020 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { AuthKbn as AuthKbnType } from 'src/js/type/base'

const SYS0020 = () => {
  const [open, setOpen] = useState<boolean>(false)
  const appAuth = useAppSelector((state) => state.app.auth)
  const funcAuth = useMemo(() => getFuncAuth(FUNC.SYS0020, appAuth), [appAuth]) as AuthKbnType
  const inputLoading = useAppSelector((state) => state.SYS0020.input.isLoading)
  const inputMode = useAppSelector((state) => state.SYS0020.input.mode)
  const dispatch = useAppDispatch()

  // 初期化
  useEffect(() => {
    // 検索処理
    dispatch(search())

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // 画面クローズ
  const _onClose = () => {
    setOpen(false)
  }

  // 登録
  const _onOK = () => {
    ;(async () => {
      await dispatch(update()) // 更新
      await dispatch(search()) // 再検索
    })()
    setOpen(false)
  }

  // 編集画面開く
  const _onOpenEdit = (row: TableRowType) => {
    setOpen(true)
    dispatch(input(row['id']))
  }

  // 新規画面開く
  const _onOpenNew = () => {
    setOpen(true)
    dispatch(initInput())
  }

  // 削除
  const _onDelete = () => {
    ;(async () => {
      await dispatch(deleteById()) // 削除
      await dispatch(search()) // 再検索
    })()
    setOpen(false)
  }

  // カラム定義
  const columns = [
    {
      key: 'id',
      label: RESOURCE.LBL_ID,
      align: 'left',
      type: 'button',
      onClick: (row) => {
        _onOpenEdit(row)
      },
      width: '10%',
    },
    { key: 'name', label: RESOURCE.LBL_NAME, align: 'left', width: '20%' },
    { key: 'comment', label: RESOURCE.LBL_COMMENT, align: 'left', width: '70%' },
  ] as TableColumnType[]

  return (
    <>
      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={RESOURCE.TITLE}
        Filter={<Filter />}
        columns={columns}
        refer={{ funcId: FUNC.SYS0020, itemId: 'result' }}
        onSearch={() => {
          dispatch(search())
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(clearFilter())
        }}
        addFlg={!isReadOnly(funcAuth)}
        onAdd={_onOpenNew}
      />

      {/* 入力ダイアログ */}
      <Input
        open={open}
        onClose={_onClose}
        onOK={_onOK}
        onDelete={_onDelete}
        isLoading={inputLoading}
        mode={isReadOnly(funcAuth) ? 'view' : inputMode}
      />
    </>
  )
}
export default SYS0020
