import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'src/js/slice/hooks'
import { Storage } from 'aws-amplify'

import { Download as DownloadIcon } from '@mui/icons-material'
import { FormDialog } from 'src/js/component/Base/Dialog'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { LinkButton } from 'src/js/component/Base/Button'

import { setEquipment } from 'src/js/slice/B0010'

import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { B0010 as RESOURCE } from 'src/resource'

import * as Type from 'src/js/type/base'

type Props = {
  open: boolean
  mode: Type.DialogStatus
  onClose: () => void
  onOK: () => void
  isLoading?: boolean
}
const Equipment = ({ open, mode, onClose, onOK, isLoading = true }: Props) => {
  const [url, setUrl] = useState<string>('')
  const filePath = useAppSelector((state) => state.B0010.equipment.filePath)

  // 初期化
  useEffect(() => {
    ;(async () => {
      let singnedUrl = ''
      if (!!filePath) {
        singnedUrl = await Storage.get(filePath, { level: 'public', download: false })
      }
      setUrl(singnedUrl)
    })()
  }, [filePath])

  return (
    <FormDialog
      title={RESOURCE.TITLE_EQUIPMENT}
      mode={mode}
      isLoading={isLoading}
      open={open}
      onOK={onOK}
      onCancel={onClose}
      delBtnFlg={false}
      custBtnFlg={!!url}
      CustBtn={
        <LinkButton
          sx={{ color: (theme) => theme.palette.secondary.main }}
          href={url}
          label={RESOURCE.LBL_EQU_SETTING_FILE_DOWNLOAD}
          variant='text'
          startIcon={<DownloadIcon />}
        />
      }
    >
      <FormCtnGrid>
        {/* PC OS */}
        <FormItmGrid>
          <StdTextFieldRef
            id='pcOS'
            label={RESOURCE.LBL_EQU_PC_OS}
            size='small'
            refer={{ funcId: FUNC.B0010, partId: 'equipment', itemId: 'pcOS' }}
            disabled
          />
        </FormItmGrid>

        {/* FM Pro Ver */}
        <FormItmGrid>
          <StdTextFieldRef
            id='fmProVer'
            label={RESOURCE.LBL_EQU_FM_PRO_VER}
            size='small'
            refer={{ funcId: FUNC.B0010, partId: 'equipment', itemId: 'fmProVer' }}
            disabled
          />
        </FormItmGrid>

        {/* FM Go Ver */}
        <FormItmGrid>
          <StdTextFieldRef
            id='fmGoVer'
            label={RESOURCE.LBL_EQU_FM_GO_VER}
            size='small'
            refer={{ funcId: FUNC.B0010, partId: 'equipment', itemId: 'fmGoVer' }}
            onChange={setEquipment}
            disabled={mode !== 'edit'}
          />
        </FormItmGrid>

        {/* iPad eUMS Ver */}
        <FormItmGrid>
          <StdTextFieldRef
            id='ipadEumsVer'
            label={RESOURCE.LBL_EQU_IPAD_VER}
            size='small'
            refer={{ funcId: FUNC.B0010, partId: 'equipment', itemId: 'ipadEumsVer' }}
            onChange={setEquipment}
            disabled={mode !== 'edit'}
          />
        </FormItmGrid>
      </FormCtnGrid>
    </FormDialog>
  )
}
export default Equipment
