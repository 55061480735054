import React from 'react'

import { Box, Link } from '@mui/material'

import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'

type Props = {
  data: { [key: string]: string }
  onClick: (row: { [key: string]: string }) => void
}
const ImoLabel = ({ data, onClick }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType
  return (
    <Box>
      <Link
        component='button'
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onClick(data)
        }}
      >
        {fileSumInfo.imoNo}
      </Link>
      <br />
      {fileSumInfo.vesselName}
    </Box>
  )
}

export default ImoLabel
