import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'
import { convertToYMDHyphenByUTC } from 'src/js/util/utility'
import { getDateTimeConditionInput } from 'src/js/util/graphql'

import * as GqlType from 'src/API'
import * as Type from './type'
import { GRAPHQL } from 'src/js/util/constants'

export const updateFileStatus = async (
  id: string,
  imoNo: string,
  fileStatus: string
): Promise<null> => {
  // ステータス更新
  await API.graphql({
    query: Mutations.updateFileInfo,
    variables: { input: { id: id, fileStatus: fileStatus } },
  })

  // ファイル集計情報取得
  const rsp = await API.graphql({
    query: Queries.getFileSumInfo,
    variables: { id: imoNo },
  })
  const fileSumInfo = lo.get(rsp, 'data.getFileSumInfo') as Type.FileSumInfo
  if (!!!fileSumInfo) return null

  // エラー件数更新
  const errorCount =
    !!fileSumInfo.numberOfError && fileSumInfo.numberOfError > 0 ? fileSumInfo.numberOfError - 1 : 0
  await API.graphql({
    query: Mutations.updateNumberOfErrorByImoNo,
    variables: { input: { id: imoNo, numberOfError: errorCount } },
  })

  return null
}

export const updateSNConfirm = async (
  version: string,
  confirmFlag: string,
  userId: string
): Promise<null> => {
  // 既存情報取得
  const rsp = await API.graphql({
    query: Queries.getSNConfirm,
    variables: { version: version },
  })

  if (!!!lo.get(rsp, 'data.getSNConfirm')) {
    // 新規
    await API.graphql({
      query: Mutations.createSNConfirm,
      variables: {
        input: {
          version: version,
          confirmFlg: confirmFlag,
          userId: userId,
        },
      },
    })
  } else {
    // 更新
    await API.graphql({
      query: Mutations.updateSNConfirm,
      variables: {
        input: {
          version: version,
          confirmFlg: confirmFlag,
          userId: userId,
        },
      },
    })
  }

  return null
}

// 検索処理
export const search = async (imoNo: string, filter: Type.Filter): Promise<Type.FileInfo[]> => {
  // FileInfoデータ検索
  let list = [] as Type.FileInfo[]
  let nextToken = null
  do {
    const rsp = await API.graphql({
      query: Queries.getFileInfoByImoNo,
      variables: {
        imoNo: imoNo,
        createdAt: getDateTimeConditionInput(filter.uploadedDateF, filter.uploadedDateT),
        filter: _makeFilterInput(filter),
        sortDirection: 'DESC',
        limit: GRAPHQL.MAX_CNT,
        nextToken: nextToken,
      },
    })
    const items = lo.get(rsp, 'data.getFileInfoByImoNo.items') as Type.FileInfo[]
    nextToken = lo.get(rsp, 'data.getFileInfoByImoNo.nextToken') as string | null
    if (!!items) {
      list = lo.union(list, items)
    }
  } while (!!nextToken)

  if (!!!list) return []

  // const rsp = await API.graphql({
  //   query: Queries.getFileInfoByImoNo,
  //   variables: {
  //     imoNo: imoNo,
  //     createdAt: getDateTimeConditionInput(filter.uploadedDateF, filter.uploadedDateT),
  //     filter: _makeFilterInput(filter),
  //     sortDirection: 'DESC',
  //     limit: GRAPHQL.MAX_CNT,
  //   },
  // })

  // const list = lo.get(rsp, 'data.getFileInfoByImoNo.items') as Type.FileInfo[]
  // if (!!!list) return []

  return list
}

// 検索条件
const _makeFilterInput = (filter: Type.Filter): GqlType.ModelFileInfoFilterInput => {
  const filterInput: GqlType.ModelFileInfoFilterInput = {}

  // File Name
  if (!!filter.fileName) {
    filterInput.fileName = { contains: filter.fileName }
  }

  // File Name
  if (!!filter.version) {
    filterInput.version = { contains: filter.version }
  }

  // File Type
  if (!!filter.fileType) {
    filterInput.fileType = { eq: filter.fileType }
  }

  // File Status
  if (!!filter.status) {
    filterInput.fileStatus = { eq: filter.status }
  }

  // Checked Date
  if (!!filter.checkedDate) {
    filterInput.fileDate = { ge: convertToYMDHyphenByUTC(new Date(filter.checkedDate)) }
  }

  return filterInput
}
