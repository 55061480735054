import React, { useMemo } from 'react'

import { Grid } from '@mui/material'

type Props = {
  isDraw?: boolean
  children: React.ReactNode
}
const FilterItmGrid = ({ isDraw = false, children }: Props) => {
  const size = useMemo(() => {
    return isDraw ? { lg: 12 } : { lg: 3, sm: 6, md: 4, xs: 12 }
  }, [isDraw])

  return (
    <Grid item {...size}>
      {children}
    </Grid>
  )
}

export default FilterItmGrid
