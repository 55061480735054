import React from 'react'

import { setFilter } from 'src/js/slice/B0010'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { HanyoSelectRef } from 'src/js/component/Base/Select'
// import { StdDateRef } from 'src/js/component/Base/DatePicker'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { HANYO_KBN } from 'src/js/util/constants'
import { B0010 as RESOURCE } from 'src/resource'
import { TARGET_SHIP_KBN } from 'src/js/util/constants'

type Props = {
  targetShipKbn: string | undefined
}
const Filter = ({ targetShipKbn }: Props) => {
  return (
    <FilterCtnGrid>
      {/* Uploaded Date From */}
      {/* <DrawerItmGrid>
        <StdDateRef
          id={'uploadedDateF'}
          label={RESOURCE.LBL_UPLOADED_DATE_FROM}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'uploadedDateF' }}
          onChange={setFilter}
        />
      </DrawerItmGrid> */}

      {/* Uploaded Date To */}
      {/* <DrawerItmGrid>
        <StdDateRef
          id={'uploadedDateT'}
          label={RESOURCE.LBL_UPLOADED_DATE_TO}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'uploadedDateT' }}
          onChange={setFilter}
        />
      </DrawerItmGrid> */}

      {/* IMO No. */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='imoNo'
          label={RESOURCE.LBL_IMO_NO}
          size='small'
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'imoNo' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Vessel Name */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='vesselName'
          label={RESOURCE.LBL_VESSEL_NAME}
          size='small'
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'vesselName' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Comment */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='comment'
          label={RESOURCE.LBL_COMMENT}
          size='small'
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'comment' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Ship Manager */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'shipManager'}
          label={RESOURCE.LBL_SHIP_MANAGER}
          hanyoKbn={HANYO_KBN.SHIP_MANAGER}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'shipManager' }}
          onChange={setFilter}
          disabled={
            targetShipKbn !== TARGET_SHIP_KBN.ALL && targetShipKbn !== TARGET_SHIP_KBN.ALL_ALIVE
          }
        />
      </DrawerItmGrid>

      {/* Checked Date From */}
      {/* <DrawerItmGrid>
        <StdDateRef
          id={'checkedDateF'}
          label={RESOURCE.LBL_CHECKED_DATE_FROM}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'checkedDateF' }}
          onChange={setFilter}
        />
      </DrawerItmGrid> */}

      {/* Checked Date To */}
      {/* <DrawerItmGrid>
        <StdDateRef
          id={'checkedDateT'}
          label={RESOURCE.LBL_CHECKED_DATE_TO}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'checkedDateT' }}
          onChange={setFilter}
        />
      </DrawerItmGrid> */}

      {/* Alive Status */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'aliveStatus'}
          label={RESOURCE.LBL_ALIVE_STATUS}
          hanyoKbn={HANYO_KBN.ALIVE_STATUS}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'aliveStatus' }}
          onChange={setFilter}
          disabled={targetShipKbn !== TARGET_SHIP_KBN.ALL}
        />
      </DrawerItmGrid>

      {/* eUMS On Board */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'eumsOnBoard'}
          label={RESOURCE.LBL_EUMS_ON_BOARD}
          hanyoKbn={HANYO_KBN.EUMS_ON_BOARD}
          refer={{ funcId: 'B0010', partId: 'filter', itemId: 'eumsOnBoard' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
