import React from 'react'

import { setFilter } from 'src/js/slice/B0012'
import { StdDateRef } from 'src/js/component/Base/DatePicker'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { B0012 as RESOURCE } from 'src/resource'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* Checked Date From */}
      <DrawerItmGrid>
        <StdDateRef
          id={'createDateF'}
          label={RESOURCE.LBL_CREATED_DATE_FROM}
          refer={{ funcId: 'B0012', partId: 'filter', itemId: 'createDateF' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Checked Date To */}
      <DrawerItmGrid>
        <StdDateRef
          id={'createDateT'}
          label={RESOURCE.LBL_CREATED_DATE_TO}
          refer={{ funcId: 'B0012', partId: 'filter', itemId: 'createDateT' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
