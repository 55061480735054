import React from 'react'

import { S3DownloadLink } from 'src/js/component/Base/Link'
import { FileInfo as FileInfoType } from 'src/js/slice/B0011/type'

type Props = {
  data: { [key: string]: string }
}
const FileNameLink = ({ data }: Props) => {
  const fileInfo = data as unknown as FileInfoType
  return (
    <S3DownloadLink
      label={fileInfo.fileNameShort ? fileInfo.fileNameShort : ''}
      path={fileInfo.filePath ? fileInfo.filePath : ''}
    />
  )
}

export default FileNameLink
