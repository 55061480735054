import { Theme } from '@mui/material/styles'
import { colors } from '@mui/material'

export const table = (theme: Theme) =>
  ({
    '& thead': {
      backgroundColor: theme.palette.primary.main,
      '& th': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
  } as const)

export const headerLabel = {
  color: colors.common.white + '!important',
  '& path': {
    color: colors.common.white,
  },
}
