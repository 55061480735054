import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'
import { getDateTimeConditionInput, getStrDateConditionInput } from 'src/js/util/graphql'
import { convertToYMD } from 'src/js/util/utility'

import * as GqlType from 'src/API'
import * as Type from './type'
import { GRAPHQL } from 'src/js/util/constants'

export const updateOperStatus = async (id: string, operationStatus: string): Promise<null> => {
  await API.graphql({
    query: Mutations.updateFileSumInfo,
    variables: { input: { id: id, operationStatus: operationStatus } },
  })
  return null
}

export const updateEquipment = async (input: Type.Equipment): Promise<null> => {
  await API.graphql({
    query: Mutations.updateEquipment,
    variables: { input: { id: input.id, fmGoVer: input.fmGoVer, ipadEumsVer: input.ipadEumsVer } },
  })

  return null
}

export const search = async (filter: Type.Filter): Promise<Type.FileSumInfo[]> => {
  // FileSumInfoデータ検索
  const rsp = await API.graphql({
    query: Queries.listFileSumInfos,
    variables: { filter: _makeFilterInput(filter), limit: GRAPHQL.MAX_CNT },
  })
  const list = lo.get(rsp, 'data.listFileSumInfos.items') as Type.FileSumInfo[]
  if (!!!list) return []
  return lo.orderBy(list, ['lastCheckedDate'], ['asc'])
}

const _makeFilterInput = (filter: Type.Filter): GqlType.ModelFileSumInfoFilterInput => {
  const filterInput: GqlType.ModelFileSumInfoFilterInput = {}

  // Imo No
  if (!!filter.imoNo) {
    filterInput.imoNo = { beginsWith: filter.imoNo }
  }

  // Vessel Name
  if (!!filter.vesselName) {
    filterInput.vesselName = { contains: filter.vesselName }
  }

  // Ship Manager
  if (!!filter.shipManager) {
    filterInput.shipManagerShort = { eq: filter.shipManager }
  }

  // Comment
  if (!!filter.comment) {
    filterInput.lastCommentContent = { contains: filter.comment }
  }

  // Alive Status
  if (!!filter.aliveStatus) {
    filterInput.aliveStatus = { eq: filter.aliveStatus }
  }

  // Uploaded Date
  if (!!filter.uploadedDateF || !!filter.uploadedDateT) {
    filterInput.uploadedDate = getDateTimeConditionInput(filter.uploadedDateF, filter.uploadedDateT)
  }

  // Last Checked Date
  if (!!filter.checkedDateF || !!filter.checkedDateT) {
    filterInput.lastCheckedDate = getStrDateConditionInput(
      convertToYMD(new Date(filter.checkedDateF)),
      convertToYMD(new Date(filter.checkedDateT))
    )
  }

  // eUMS On Board
  if (!!filter.eumsOnBoard) {
    filterInput.eumsUnavailableFlag = { eq: filter.eumsOnBoard }
  }

  return filterInput
}

// コメント取得
export const getEquipmentById = async (id: string): Promise<GqlType.Equipment> => {
  let rsp = await API.graphql({
    query: Queries.getEquipment,
    variables: { id: id },
  })
  const equipment = lo.get(rsp, 'data.getEquipment') as GqlType.Equipment
  if (!!equipment) return equipment

  // 新規登録
  rsp = await API.graphql({
    query: Mutations.createEquipment,
    variables: {
      input: {
        id: id,
        imoNo: id,
        fmGoVer: '',
        ipadEumsVer: '',
        settingId: id,
      },
    },
  })
  return lo.get(rsp, 'data.createEquipment') as GqlType.Equipment
}
