import React from 'react'

import { CircleNotifications as CircleNotificationsIcon } from '@mui/icons-material'
import { colors } from '@mui/material'

import { FileSumInfo as FileSumInfoType } from 'src/js/slice/B0010/type'

type Props = {
  data: { [key: string]: string }
}
const SNAlert = ({ data }: Props) => {
  const fileSumInfo = data as unknown as FileSumInfoType
  if (!!!fileSumInfo.snConfirm || fileSumInfo.snConfirm.confirmFlg !== '1')
    return <CircleNotificationsIcon sx={{ color: colors.red[500] }} />
  return <div></div>
}

export default SNAlert
