import React from 'react'

import { Box } from '@mui/material'

import { FileInfo as FileInfoType } from 'src/js/slice/B0011/type'

type Props = {
  data: { [key: string]: string }
}
const SenderLabel = ({ data }: Props) => {
  const fileInfo = data as unknown as FileInfoType
  return (
    <Box>
      {fileInfo.sender !== 'HTTP' && fileInfo.shipAppFlg !== '1' ? '*' : ''}
      {fileInfo.sender}
    </Box>
  )
}

export default SenderLabel
