import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { setFilterCount } from 'src/js/slice/App'
import { query, mutation } from 'src/js/util/graphql'
import * as Api from './api'

import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { countFilter } from 'src/js/util/utility'
import * as Type from './type'

// 非同期処理定義
// 更新処理
type UpdateRemovedFlgProps = {
  id: string
  value: boolean
}
export const updateRemovedFlg = createAsyncThunk<
  UpdateRemovedFlgProps,
  UpdateRemovedFlgProps,
  { state: RootState }
>(`${FUNC.M0010}/updateRemovedFlg`, async (param, thunkAPI) => {
  try {
    const id = new String(param.id).replace('eumsUnavailableFlag_', '')

    return await mutation<UpdateRemovedFlgProps, UpdateRemovedFlgProps>(
      thunkAPI.dispatch,
      { id: id, value: param.value },
      async (dispatch, param) => {
        // 更新処理
        await Api.updateRemovedFlg(param.id, param.value ? '1' : '0')
        return param
      }
    )
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

// 検索処理
export const search = createAsyncThunk<Type.MstVessel[], undefined, { state: RootState }>(
  `${FUNC.M0010}/search`,
  async (_, thunkAPI) => {
    try {
      return await query<Type.Filter, Type.MstVessel[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().M0010.filter,
        async (dispatch, filter) => {
          // フィルターカウンター
          dispatch(setFilterCount(countFilter(filter)))

          // 検索処理
          return await Api.search(filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// Slice定義
const getInitialState = (): Type.State => ({
  // 検索条件
  filter: {
    imoNo: '',
    vesselName: '',
    shipManager: '',
    aliveStatus: '',
  },

  // 明細
  input: {
    isLoading: false,
    mode: 'new',

    imoNo: '',
    vesselName: '',
    classification: '',
    classificationSub: '',
    shipManagerShort: '',
    managementScheme: '',
    aliveStatus: '',
    eumsUnavailableFlag: '0',
    mailBox: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
  },
})
export const Slice = createSlice({
  name: FUNC.MM0010,
  initialState: getInitialState(),
  reducers: {
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    setInput: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.input, id, value)
    },
    initInput: (state) => {
      state.input = getInitialState().input
      state.input.mode = 'new'
    },
    clearFilter: (state) => {
      state.filter = getInitialState().filter
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.filter = initState.filter
      state.input = initState.input
      state.result = initState.result
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // 入力更新
      .addCase(updateRemovedFlg.pending, () => {
        // 処理なし
      })
      .addCase(updateRemovedFlg.fulfilled, (state, action) => {
        const { id, value } = action.payload
        const data = lo.cloneDeep(state.result.data)
        const vessel = lo.find(data, (row) => row.id === id)
        if (vessel) {
          vessel.eumsUnavailableFlag = value ? '1' : '0'
        }
        state.result.data = data
      })
      .addCase(updateRemovedFlg.rejected, () => {
        // 処理なし
      })
  },
})

export const { setFilter, setInput, initInput, cancelFilter, clearFilter, gc } = Slice.actions

export default Slice.reducer
