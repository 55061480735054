import React from 'react'

import { setInput, setRoleOption } from 'src/js/slice/SYS0030'
import { FormDialog } from 'src/js/component/Base/Dialog'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { StdTextFieldRef, RegExpTextFieldRef } from 'src/js/component/Base/TextField'
import { StdAutocompleteRef } from 'src/js/component/Base/Autocomplete'
import { HanyoSelectRef } from 'src/js/component/Base/Select'
import { FUNCTION_ID as FUNC, REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS0030 as RESOURCE, SYS_MSG } from 'src/resource'
import { HANYO_KBN } from 'src/js/util/constants'

import * as Type from 'src/js/type/base'

type Props = {
  open: boolean
  mode: Type.DialogStatus
  onOK: () => void
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}
const Input = ({ open, mode, onOK, onClose, onDelete, isLoading = true }: Props) => {
  return (
    <FormDialog
      title={RESOURCE.TITLE_INPUT}
      mode={mode}
      isLoading={isLoading}
      open={open}
      onCancel={onClose}
      onOK={onOK}
      onDelete={onDelete}
    >
      <FormCtnGrid>
        {/* ID */}
        <FormItmGrid>
          <RegExpTextFieldRef
            id='id'
            label={RESOURCE.LBL_ID}
            size='small'
            regExp={REGEX.HALF_N_E}
            errMsg={SYS_MSG.ERRSS005}
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'id' }}
            onChange={setInput}
            disabled={mode === 'edit'}
            required
          />
        </FormItmGrid>

        {/* ユーザー名 */}
        <FormItmGrid>
          <StdTextFieldRef
            id='name'
            label={RESOURCE.LBL_NAME}
            size='small'
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'name' }}
            onChange={setInput}
            required
          />
        </FormItmGrid>

        {/* ロール */}
        <FormItmGrid>
          <StdAutocompleteRef
            id='roleId'
            label={RESOURCE.LBL_ROLE_ID}
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'roleId' }}
            referOpts={{ funcId: FUNC.SYS0030, itemId: 'roleOpts' }}
            onChange={setRoleOption}
            required
          />
        </FormItmGrid>

        {/* Ship Manager */}
        <FormItmGrid>
          <HanyoSelectRef
            id={'shipManagerShort'}
            label={RESOURCE.LBL_SHIP_MANAGER}
            hanyoKbn={HANYO_KBN.SHIP_MANAGER}
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'shipManagerShort' }}
            onChange={setInput}
            required
          />
        </FormItmGrid>

        {/* MailBox */}
        <FormItmGrid>
          <StdTextFieldRef
            id='mailBox'
            label={RESOURCE.LBL_MAILBOX}
            size='small'
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'mailBox' }}
            onChange={setInput}
          />
        </FormItmGrid>

        {/* Target Ship Kbn */}
        <FormItmGrid>
          <HanyoSelectRef
            id={'targetShipKbn'}
            label={RESOURCE.LBL_TARGETSHIP_KBN}
            hanyoKbn={HANYO_KBN.TARGET_SHIP}
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'targetShipKbn' }}
            onChange={setInput}
            required
          />
        </FormItmGrid>

        {/* SN Comfirmed Kbn */}
        <FormItmGrid>
          <HanyoSelectRef
            id={'liveCheckKbn'}
            label={RESOURCE.LBL_LIVECHECK_KBN}
            hanyoKbn={HANYO_KBN.LIVE_CHECK}
            refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'liveCheckKbn' }}
            onChange={setInput}
            required
          />
        </FormItmGrid>
      </FormCtnGrid>

      {/* メモ */}
      <FormItmGrid>
        <StdTextFieldRef
          id='memo'
          label={RESOURCE.LBL_MEMO}
          size='small'
          refer={{ funcId: FUNC.SYS0030, partId: 'input', itemId: 'memo' }}
          onChange={setInput}
        />
      </FormItmGrid>
    </FormDialog>
  )
}
export default Input
