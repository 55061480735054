import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { query } from 'src/js/util/graphql'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import * as Api from './api'

import * as GqlType from 'src/API'
import * as Type from './type'

// 非同期処理定義
// 検索処理
export const search = createAsyncThunk<GqlType.Role[], undefined, { state: RootState }>(
  `${FUNC.SYS0040}/search`,
  async (_, thunkAPI) => {
    try {
      return await query<Type.Filter, GqlType.Role[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().SYS0040.filter,
        async (dispatch, filter) => {
          return await Api.search(filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// ページセッション保存
export const restorePageSession = createAsyncThunk<
  Type.State | undefined,
  undefined,
  { state: RootState }
>(`${FUNC.SYS0040}/restorePageSession`, async (_, thunkAPI) => {
  try {
    if (lo.has(thunkAPI.getState().app.pageSession, FUNC.SYS0040))
      return lo.get(thunkAPI.getState().app.pageSession, FUNC.SYS0040) as Type.State
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

const getInitialState = (): Type.State => ({
  // 検索条件
  filter: {
    name: '',
    comment: '',
  },

  // 明細
  input: {
    isLoading: false,
    mode: 'new',
    id: '',
    name: '',
    comment: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
  },
})
export const Slice = createSlice({
  name: FUNC.SYS0040,
  initialState: getInitialState(),
  reducers: {
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    clearFilter: (state) => {
      state.filter = getInitialState().filter
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.filter = initState.filter
      state.input = initState.input
      state.result = initState.result
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // ページセッション復元
      .addCase(restorePageSession.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(restorePageSession.fulfilled, (state, action) => {
        if (action.payload) state.filter = action.payload.filter
        state.result.isLoading = false
      })
      .addCase(restorePageSession.rejected, (state) => {
        state.result = getInitialState().result
      })
  },
})

export const { setFilter, clearFilter, cancelFilter, gc } = Slice.actions

export default Slice.reducer
