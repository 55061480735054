import React from 'react'

import { setFilter } from 'src/js/slice/SYS0030'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { SYS0030 as RESOURCE } from 'src/resource'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* ロールID */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='roleId'
          label={RESOURCE.LBL_ROLE_ID}
          size='small'
          refer={{ funcId: 'SYS0030', partId: 'filter', itemId: 'roleId' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
