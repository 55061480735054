import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { query, mutation } from 'src/js/util/graphql'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import * as Api from './api'

import * as BaseType from 'src/js/type/base'
import * as GqlType from 'src/API'
import * as Type from './type'

// 非同期処理定義
// ヘッダー情報取得
export const header = createAsyncThunk<GqlType.Role, string, { state: RootState }>(
  `${FUNC.SYS0041}/header`,
  async (pRoleId, thunkAPI) => {
    try {
      return await query<string, GqlType.Role>(
        thunkAPI.dispatch,
        pRoleId,
        async (dispatch, pRoleId) => {
          return await Api.findRoleByKey(pRoleId)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// コンテナ集計情報取得
export const detail = createAsyncThunk<GqlType.RoleAuth[], string, { state: RootState }>(
  `${FUNC.SYS0041}/detail`,
  async (pRoleId, thunkAPI) => {
    try {
      return await query<string, GqlType.RoleAuth[]>(
        thunkAPI.dispatch,
        pRoleId,
        async (dispatch, pRoleId) => {
          return await Api.listRoleAuths(pRoleId)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// ロールオプション初期化
export const initPageOpts = createAsyncThunk<BaseType.Option[], undefined, { state: RootState }>(
  `${FUNC.SYS0041}/initPageOpts`,
  async (_, thunkAPI) => {
    try {
      return await query<undefined, BaseType.HanyoItem>(thunkAPI.dispatch, undefined, async () => {
        return await Api.listPages()
      })
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 明細表示
export const input = createAsyncThunk<GqlType.RoleAuth, string, { state: RootState }>(
  `${FUNC.SYS0041}/input`,
  async (pAuthId, thunkAPI) => {
    try {
      return await query<string, GqlType.RoleAuth>(
        thunkAPI.dispatch,
        pAuthId,
        async (dispath, pAuthId) => {
          return await Api.findAuthByKey(pAuthId)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 更新処理
type UpdateParamType = {
  roleId: string
  input: Type.Input
}
export const update = createAsyncThunk<GqlType.RoleAuth, string, { state: RootState }>(
  `${FUNC.SYS0041}/update`,
  async (pRoleId, thunkAPI) => {
    try {
      return await mutation<UpdateParamType, GqlType.RoleAuth>(
        thunkAPI.dispatch,
        { roleId: pRoleId, input: thunkAPI.getState().SYS0041.input },
        async (dispatch, param) => {
          // 有効性チェック
          const errMsg = await Api.validation(param.roleId, param.input)
          if (!lo.isEmpty(errMsg)) {
            throw errMsg
          }

          // 更新処理
          return param.input.mode == 'new'
            ? await Api.create(param.roleId, param.input)
            : await Api.update(param.input)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 削除処理
export const deleteById = createAsyncThunk<void, undefined, { state: RootState }>(
  `${FUNC.SYS0041}/deleteById`,
  async (_, thunkAPI) => {
    try {
      return await mutation<string, void>(
        thunkAPI.dispatch,
        thunkAPI.getState().SYS0041.input.id,
        async (dispatch, id) => {
          await Api.deleteById(id)
          return
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

const getInitialState = (): Type.State => ({
  // 基本情報
  header: {
    isLoading: false,
    id: '',
    name: '',
    comment: '',
  },

  // コンテナ一覧情報
  detail: {
    isLoading: false,
    data: [],
  },

  // コンテナ明細情報
  input: {
    isLoading: false,
    mode: 'new',
    id: '',
    pageId: '',
    roleType: '',
    order: '',
  },

  pageOpts: [],
})
export const Slice = createSlice({
  name: FUNC.SYS0041,
  initialState: getInitialState(),
  reducers: {
    setInput: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.input, id, value)
    },
    setPageOption: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.input, id, value.value)
    },
    initInput: (state) => {
      state.input.isLoading = false
      state.input.mode = 'new'
      state.input.id = ''
      state.input.pageId = ''
      state.input.roleType = ''
      state.input.order = ''
    },
    gc: (state) => {
      const initState = getInitialState()
      state.header = initState.header
      state.detail = initState.detail
    },
  },
  extraReducers: (builder) => {
    builder
      // ヘッダー
      .addCase(header.pending, (state) => {
        state.header.isLoading = true
      })
      .addCase(header.fulfilled, (state, action) => {
        state.header.isLoading = false
        const role = action.payload
        state.header.id = role.id
        state.header.name = role.name
        state.header.comment = role.comment
      })
      .addCase(header.rejected, (state) => {
        state.header = getInitialState().header
      })

      // 明細
      .addCase(detail.pending, (state) => {
        state.detail.isLoading = true
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.detail.isLoading = false
        state.detail.data = action.payload
      })
      .addCase(detail.rejected, (state) => {
        state.detail = getInitialState().detail
      })

      // ロールオプション初期化
      .addCase(initPageOpts.pending, () => {
        // 処理なし
      })
      .addCase(initPageOpts.fulfilled, (state, action) => {
        state.pageOpts = action.payload
      })
      .addCase(initPageOpts.rejected, () => {
        // 処理なし
      })

      // コンテナ明細情報
      .addCase(input.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(input.fulfilled, (state, action) => {
        state.input.isLoading = false
        state.input.mode = 'edit'

        const roleAuth = action.payload
        state.input.id = roleAuth.id
        state.input.pageId = roleAuth.pageId
        state.input.roleType = roleAuth.roleType
        state.input.order = roleAuth.order
      })
      .addCase(input.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 更新
      .addCase(update.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(update.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 削除
      .addCase(deleteById.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(deleteById.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(deleteById.rejected, (state) => {
        state.input = getInitialState().input
      })
  },
})

export const { setInput, setPageOption, initInput, gc } = Slice.actions

export default Slice.reducer
