import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import { useLocation } from 'react-router-dom'

import { RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType } from 'src/js/component/Base/Table'
import Filter from './Filter'
import SNConfirmedLabel from './SNConfirmedLabel'
import FileNameLink from './FileNameLink'
import SenderLabel from './SenderLabel'
import FileStatusLabel from './FileStatusLabel'
import SendingPGVerLabel from './SendingPGVerLabel'

import { initFilter, search, gc, cancelFilter, clearFilter } from 'src/js/slice/B0011'

import { B0011 as RESOURCE } from 'src/resource'
import { formatDateByUSByJST, formatDateTimeByUS } from 'src/js/util/utility'
import { FUNCTION_ID as FUNC, LIVE_CHECK_KBN } from 'src/js/util/constants'

const B0011 = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const liveCheckKbn = useAppSelector((state) => state.app.user?.liveCheckKbn)

  const imoNo = (location.state as { p1: string }).p1
  const vesselName = (location.state as { p2: string }).p2

  // 初期化
  useEffect(() => {
    ;(async () => {
      // 検索条件初期化
      await dispatch(initFilter())

      // 検索処理
      await dispatch(search(imoNo))
    })()

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // カラム定義
  const columns = [
    {
      key: 'snConfirmed',
      label: RESOURCE.LBL_SN_CONFIRMED,
      align: 'left',
      type: liveCheckKbn === LIVE_CHECK_KBN.NG ? 'hidden' : 'custom',
      custom: (row: { [key: string]: string }, index: number) => (
        <SNConfirmedLabel data={row} index={index} userLiveCheckKbn={liveCheckKbn} />
      ),
    },
    {
      key: 'fileNameShort',
      label: RESOURCE.LBL_FILE_NAME,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <FileNameLink data={row} />,
    },
    { key: 'version', label: RESOURCE.LBL_VERSION, align: 'left' },
    {
      key: 'fileDate',
      label: RESOURCE.LBL_CHECKED_DATE,
      align: 'left',
      format: (value: string) => formatDateByUSByJST(value),
    },
    {
      key: 'startTime',
      label: RESOURCE.LBL_START_TIME,
      align: 'left',
      format: (value: string) => formatDateTimeByUS(value, 'UTC'),
    },
    {
      key: 'endTime',
      label: RESOURCE.LBL_END_TIME,
      align: 'left',
      format: (value: string) => formatDateTimeByUS(value, 'UTC'),
    },
    { key: 'numOfItems', label: RESOURCE.LBL_NUMBER_OF_ITEMS, align: 'left' },
    { key: 'countItems', label: RESOURCE.LBL_NUMBER_OF_CHECKED_ITEMS, align: 'left' },
    {
      key: 'createdAt',
      label: RESOURCE.LBL_UPLOADED_DATE,
      align: 'left',
      format: (value: string) => formatDateTimeByUS(value, 'UTC'),
    },
    {
      key: 'shipAppVersion',
      label: RESOURCE.LBL_APP_VER,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <SendingPGVerLabel data={row} />,
    },
    {
      key: 'sender',
      label: RESOURCE.LBL_SENDER,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <SenderLabel data={row} />,
    },
    {
      key: 'fileStatus',
      label: RESOURCE.LBL_STATUS,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }, index: number) => (
        <FileStatusLabel index={index} data={row} />
      ),
    },
  ] as TableColumnType[]

  const csvInfo = {
    fileName: 'dataList.csv',
    keys: [
      'fileName',
      'version',
      'fileDate',
      'startTime',
      'endTime',
      'numOfItems',
      'countItems',
      'createdAt',
      'sender',
      'fileStatus',
    ],
    title: [
      RESOURCE.LBL_FILE_NAME,
      RESOURCE.LBL_VERSION,
      RESOURCE.LBL_CHECKED_DATE,
      RESOURCE.LBL_START_TIME,
      RESOURCE.LBL_END_TIME,
      RESOURCE.LBL_NUMBER_OF_ITEMS,
      RESOURCE.LBL_NUMBER_OF_CHECKED_ITEMS,
      RESOURCE.LBL_UPLOADED_DATE,
      RESOURCE.LBL_SENDER,
      RESOURCE.LBL_STATUS,
    ],
  }

  return (
    <>
      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={`${RESOURCE.TITLE} (${imoNo} ${vesselName})`}
        Filter={<Filter />}
        columns={columns}
        refer={{ funcId: FUNC.B0011, itemId: 'result' }}
        onSearch={() => {
          dispatch(search(imoNo))
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(clearFilter())
        }}
        filterKey={{ funcId: FUNC.B0011, filterId: 'filter' }}
        displayMode='page'
        tblStickyHeader={true}
        exportFlg={true}
        csv={csvInfo}
      />
    </>
  )
}
export default B0011
