import lo from 'lodash'
import { API } from 'aws-amplify'

import * as GqlQueries from 'src/graphql/queries'
import * as GqlMutations from 'src/graphql/mutations'

import * as MenuConfig from 'src/config/menu'
import * as GqlType from 'src/API'

// ページ初期化
export const initPage = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listPages })
  const pages = lo.get(rsp, 'data.listPages.items') as GqlType.Page[]
  for (const page of pages) {
    await API.graphql({ query: GqlMutations.deletePage, variables: { input: { id: page.id } } })
  }

  // 新データ作成
  for (const page of MenuConfig.PAGE_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createPage, variables: { input: page } })
  }
}

// メニュー初期化
export const initMenu = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listMenus })
  const menus = lo.get(rsp, 'data.listMenus.items') as GqlType.Menu[]
  for (const menu of menus) {
    await API.graphql({ query: GqlMutations.deleteMenu, variables: { input: { id: menu.id } } })
  }

  // 新データ作成
  for (const menu of MenuConfig.MENU_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createMenu, variables: { input: menu } })
  }
}

// メニューアイテム初期化
export const initMenuItem = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listMenuItems })
  const menuitems = lo.get(rsp, 'data.listMenuItems.items') as GqlType.MenuItem[]
  for (const item of menuitems) {
    await API.graphql({ query: GqlMutations.deleteMenuItem, variables: { input: { id: item.id } } })
  }

  // 新データ作成
  for (const item of MenuConfig.MENUITEM_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createMenuItem, variables: { input: item } })
  }
}

// ロール初期化
export const initRole = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listRoles })
  const roles = lo.get(rsp, 'data.listRoles.items') as GqlType.Role[]
  for (const role of roles) {
    await API.graphql({ query: GqlMutations.deleteRole, variables: { input: { id: role.id } } })
  }

  // 新データ作成
  for (const role of MenuConfig.ROLE_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createRole, variables: { input: role } })
  }
}

// ロール権限初期化
export const initRoleAuth = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listRoleAuths })
  const roleAuths = lo.get(rsp, 'data.listRoleAuths.items') as GqlType.RoleAuth[]
  for (const auth of roleAuths) {
    await API.graphql({ query: GqlMutations.deleteRoleAuth, variables: { input: { id: auth.id } } })
  }

  // 新データ作成
  for (const auth of MenuConfig.ROLE_AUTH_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createRoleAuth, variables: { input: auth } })
  }
}

// ユーザーロール初期化
export const initUser = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listUsers })
  const users = lo.get(rsp, 'data.listUsers.items') as GqlType.User[]
  for (const user of users) {
    await API.graphql({ query: GqlMutations.deleteUser, variables: { input: { id: user.id } } })
  }

  // 新データ作成
  for (const user of MenuConfig.ROLE_USER_INIT_CONFIG) {
    await API.graphql({ query: GqlMutations.createUser, variables: { input: user } })
  }
}
