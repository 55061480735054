/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCodeMasters = /* GraphQL */ `
  query ListCodeMasters(
    $id: ID
    $filter: ModelCodeMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodeMasters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        kbn
        code
        name
        order
      }
    }
  }
`
