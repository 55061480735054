import React from 'react'

import { updateRemovedFlg } from 'src/js/slice/M0010'
import { StdCheckBox } from 'src/js/component/Base/CheckBox'
import { M0010 as RESOURCE } from 'src/resource'
import { MstVessel as MstVesselType } from 'src/js/slice/M0010/type'

type Props = {
  data: { [key: string]: string }
  disabled: boolean
}
const RemovedCheckBox = ({ data, disabled = true }: Props) => {
  const vessel = data as unknown as MstVesselType

  return (
    <StdCheckBox
      id={`eumsUnavailableFlag_${vessel.id}`}
      label={RESOURCE.LBL_EUMS_REMOVED}
      value={vessel.eumsUnavailableFlag ? vessel.eumsUnavailableFlag === '1' : false}
      onChange={updateRemovedFlg}
      disabled={disabled}
      size='small'
      checkBoxSize='small'
      labelSize='0.875rem'
    />
  )
}
export default RemovedCheckBox
