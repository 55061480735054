import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'

import { isMatch } from 'src/js/util/utility'
import { REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS_MSG } from 'src/resource'

import * as BaseType from 'src/js/type/base'
import * as GqlType from 'src/API'
import * as Type from './type'

// ロール権限データ取得
export const findRoleByKey = async (pRoleId: string): Promise<GqlType.Role> => {
  const rsp = await API.graphql({
    query: Queries.getRole,
    variables: { id: pRoleId },
  })
  return lo.get(rsp, 'data.getRole') as GqlType.Role
}

// ロール権限データ取得
export const listRoleAuths = async (pRoleId: string): Promise<GqlType.RoleAuth[]> => {
  const rsp = await API.graphql({
    query: Queries.listRoleAuths,
    variables: { filter: { roleId: { eq: pRoleId } } },
  })
  const roleAuths = lo.get(rsp, 'data.listRoleAuths.items') as GqlType.RoleAuth[]

  return !!roleAuths ? lo.orderBy(roleAuths, ['pageId'], ['asc']) : []
}

// ロールオプション検索
export const listPages = async (): Promise<BaseType.Option[]> => {
  // 全ロール取得
  const rsp = await API.graphql({
    query: Queries.listPages,
  })
  const pages = lo.get(rsp, 'data.listPages.items') as GqlType.Page[]
  if (!pages) return []

  // オプション作成
  const options = pages.map((page) => ({
    label: page.name,
    value: page.id,
  }))
  return lo.sortBy(options, ['label'])
}

// ロール権限データ取得
export const findAuthByKey = async (pAuthId: string): Promise<GqlType.RoleAuth> => {
  const rsp = await API.graphql({
    query: Queries.getRoleAuth,
    variables: { id: pAuthId },
  })
  return lo.get(rsp, 'data.getRoleAuth') as GqlType.RoleAuth
}

// ページ取得
export const findPageByKey = async (pPageId: string): Promise<GqlType.Page> => {
  const rsp = await API.graphql({
    query: Queries.getPage,
    variables: { id: pPageId },
  })
  return lo.get(rsp, 'data.getPage') as GqlType.Page
}

// 検証
export const validation = async (pRoleId: string, pInput: Type.Input): Promise<string[]> => {
  const errMsg = [] as string[]

  // Id
  if (!pInput.id) errMsg.push(`id:${SYS_MSG.ERRSS001}`)
  if (!!pInput.id && !isMatch(pInput.id, REGEX.HALF_N_E)) errMsg.push(`id:${SYS_MSG.ERRSS005}`)

  // ページID
  if (!pInput.pageId) errMsg.push(`name:${SYS_MSG.ERRSS001}`)
  if (!!pInput.pageId && !isMatch(pInput.pageId, REGEX.HALF_N_E))
    errMsg.push(`name:${SYS_MSG.ERRSS005}`)
  const page = await findPageByKey(pInput.pageId)
  if (!page) errMsg.push(`ページ:${SYS_MSG.ERRSS006}`)

  // ロールタイプ
  if (!pInput.roleType) errMsg.push(`ロールタイプ:${SYS_MSG.ERRSS001}`)

  // オーダー
  if (!pInput.order) errMsg.push(`オーダー:${SYS_MSG.ERRSS001}`)
  if (!!pInput.order && !isMatch(pInput.order, REGEX.HALF_N))
    errMsg.push(`オーダー:${SYS_MSG.ERRSS007}`)

  // 新規処理有効性チェック
  if (pInput.mode === 'new') {
    // 既存IDチェック
    const auth = await findAuthByKey(pInput.id)
    if (auth) errMsg.push(`ページ権限:${SYS_MSG.ERRSS006}`)

    // 既存ページチェック
    const roleAuths = _listRoleAuthsByPageId(pRoleId, pInput.pageId)
    if (!lo.isEmpty(roleAuths)) errMsg.push(`ページ権限:${SYS_MSG.ERRSS006}`)
  }

  return errMsg
}

// 新規作成
export const create = async (pRoleId: string, pInput: Type.Input): Promise<GqlType.RoleAuth> => {
  await API.graphql({
    query: Mutations.createRoleAuth,
    variables: {
      input: {
        id: pInput.id,
        roleId: pRoleId,
        pageId: pInput.pageId,
        roleType: pInput.roleType,
        order: pInput.order,
      },
    },
  })
  return await findAuthByKey(pInput.id)
}

// 新規作成
export const update = async (pInput: Type.Input): Promise<GqlType.RoleAuth> => {
  await API.graphql({
    query: Mutations.updateRoleAuth,
    variables: {
      input: {
        id: pInput.id,
        pageId: pInput.pageId,
        roleType: pInput.roleType,
        order: pInput.order,
      },
    },
  })
  return await findAuthByKey(pInput.id)
}

// 削除
export const deleteById = async (pId: string): Promise<void> => {
  await API.graphql({
    query: Mutations.deleteRoleAuth,
    variables: { input: { id: pId } },
  })
  return
}

// ページIDにより、ロール権限取得
const _listRoleAuthsByPageId = async (
  pRoleId: string,
  pPageId: string
): Promise<GqlType.RoleAuth[]> => {
  const rsp = await API.graphql({
    query: Queries.listRoleAuths,
    variables: { roleId: pRoleId, pageId: pPageId },
  })
  const roleAuths = lo.get(rsp, 'data.listRoleAuths.items') as GqlType.RoleAuth[]

  return !!roleAuths ? roleAuths : []
}
