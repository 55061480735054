/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      comment
      createdAt
      updatedAt
    }
  }
`
export const listRoleAuths = /* GraphQL */ `
  query ListRoleAuths(
    $id: ID
    $filter: ModelRoleAuthFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoleAuths(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roleId
        pageId
        page {
          id
          name
        }
        roleType
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listPages = /* GraphQL */ `
  query ListPages(
    $id: ID
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getRoleAuth = /* GraphQL */ `
  query GetRoleAuth($id: ID!) {
    getRoleAuth(id: $id) {
      id
      roleId
      pageId
      roleType
      order
      createdAt
      updatedAt
    }
  }
`
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
    }
  }
`
