import lo from 'lodash'
import { API } from 'aws-amplify'

import * as GqlQueries from 'src/graphql/queries'
import * as GqlType from 'src/API'
import * as Type from 'src/js/type/base'

export const getUser = async (pUserId: string): Promise<Type.User> => {
  const rsp = await API.graphql({ query: GqlQueries.getUser, variables: { id: pUserId } })
  const user = lo.get(rsp, 'data.getUser') as GqlType.User
  if (!user)
    return {
      userId: pUserId,
      userNm: '',
      email: '',
      org: '',
      roleId: '',
      roleDesc: '',
      shipManagerShort: '',
      targetShipKbn: '',
      liveCheckKbn: '',
    }

  return {
    userId: pUserId,
    userNm: user.id,
    email: user.name,
    org: '',
    roleId: user.role.id,
    roleDesc: user.role.name,
    shipManagerShort: user.shipManagerShort,
    targetShipKbn: user.targetShipKbn,
    liveCheckKbn: user.liveCheckKbn,
  }
}

export const getAuths = async (pUserId: string): Promise<Type.Auth[]> => {
  const rsp = await API.graphql({ query: GqlQueries.getUser, variables: { id: pUserId } })
  const user = lo.get(rsp, 'data.getUser') as GqlType.User
  if (!user || !user.role.roleAuth) return []

  return user.role.roleAuth.items.map((item) => ({
    funcId: item?.page.funcId || '',
    auth: _getRoleType(item?.roleType),
  }))
}

const _getRoleType = (pRoleType: string | undefined): Type.AuthKbn => {
  if (!!pRoleType && (pRoleType === 'RW' || pRoleType === 'RO' || pRoleType === 'NG'))
    return pRoleType
  return 'NG'
}
