/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFileInfoByImoNo = /* GraphQL */ `
  query GetFileInfoByImoNo(
    $imoNo: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileInfoByImoNo(
      imoNo: $imoNo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imoNo
        vesselName
        fileName
        fileNameShort
        filePath
        fileType
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
          }
          createdAt
          updatedAt
        }
        numberOfRecord
        startTime
        endTime
        fileDate
        createdAt
        updatedAt
        sender
        shipAppFlg
        shipAppVersion
        fileStatus
        operationStatus
        numOfItems
        countItems
        reserve1
        reserve2
        reserve3
        fileSumId
      }
      nextToken
    }
  }
`
export const getSNConfirm = /* GraphQL */ `
  query GetSNConfirm($version: String!) {
    getSNConfirm(version: $version) {
      version
      confirmFlg
      userId
      createdAt
      updatedAt
    }
  }
`
export const getFileSumInfo = /* GraphQL */ `
  query GetFileSumInfo($id: ID!) {
    getFileSumInfo(id: $id) {
      id
      numberOfError
    }
  }
`
