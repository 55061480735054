/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listFileSumInfos = /* GraphQL */ `
  query ListFileSumInfos(
    $id: ID
    $filter: ModelFileSumInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileSumInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        shipManagerShort
      }
      nextToken
    }
  }
`
