/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
    createComment(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      fileSumInfo
    }
  }
`

export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
    updateComment(input: $input, condition: $condition) {
      id
      comment
    }
  }
`

export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!, $condition: ModelCommentConditionInput) {
    deleteComment(input: $input, condition: $condition) {
      id
    }
  }
`

export const updateFileSumInfo = /* GraphQL */ `
  mutation UpdateFileSumInfo(
    $input: UpdateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    updateFileSumInfo(input: $input, condition: $condition) {
      id
      lastCommentId
      lastCommentContent
    }
  }
`
