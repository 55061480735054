import React from 'react'
import { Button } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  sx?: SxProps<Theme>
  label: string
  href: string
  variant?: 'text' | 'outlined' | 'contained'
  disabled?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  startIcon?: React.ReactNode
}
const LinkButton = ({
  sx,
  label,
  href,
  variant = 'contained',
  disabled = false,
  color = 'primary',
  startIcon,
}: Props) => {
  return (
    <Button
      sx={sx}
      variant={variant}
      href={href}
      disabled={disabled}
      color={color}
      startIcon={startIcon}
    >
      {label}
    </Button>
  )
}

export default LinkButton
