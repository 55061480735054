import React from 'react'

import { updateOperStatus } from 'src/js/slice/B0010'
import { HanyoSelect } from 'src/js/component/Base/Select'
import { HANYO_KBN } from 'src/js/util/constants'
import { B0010 as RESOURCE } from 'src/resource'
import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'

type Props = {
  data: { [key: string]: string }
}
const OperationStatusSelect2 = ({ data }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType

  return (
    <HanyoSelect
      id={`operationStatus_${fileSumInfo.id}`}
      label={RESOURCE.LBL_OPERATION_STATUS}
      hanyoKbn={HANYO_KBN.OPERATION_STATUS}
      value={fileSumInfo.operationStatus || ''}
      onChange={updateOperStatus}
      tableInputMode={true}
      hasNoneOpt={true}
    />
  )
}
export default OperationStatusSelect2
