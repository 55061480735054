import React, { useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'

import { Auth } from 'aws-amplify'
import { useAppDispatch } from 'src/js/slice/hooks'
import { systemError } from 'src/js/slice/App'

const Authentication = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // パスの初期設定
  useEffect(() => {
    if (location.pathname !== '/') navigate('/')
  }, [])

  // 画面パス変更場合の権限チェック
  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      dispatch(systemError())
    })
  }, [location])

  return (
    <>
      <Outlet />
    </>
  )
}
export default Authentication
