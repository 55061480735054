import React, { useState } from 'react'
// import lo from 'lodash'

import { Paper, Box, Collapse, CircularProgress } from '@mui/material'

import TitleBar from './TitleBar'
// import * as Type from 'src/js/type/base'

type Props = {
  title: string // タイトル
  isLoading: boolean // ローディング
  children: React.ReactNode // 子コンポネント

  okFlg?: boolean // 新規イベント有無フラグ
  onOk?: () => void // イベント：新規
}
const StandardPanel = ({ title, isLoading, children, okFlg = false, onOk }: Props) => {
  const [clpFlg, setClpFlg] = useState<boolean>(true)

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TitleBar
          // 基本プロパティ
          title={title}
          isLoading={isLoading}
          clpFlg={clpFlg}
          onCollapse={setClpFlg}
          okFlg={okFlg}
          onOk={onOk}
        />

        {/* 結果テーブル */}
        <Box sx={{ pt: 1, px: 2, pb: 4 }}>
          <Collapse in={clpFlg} timeout={{ enter: 500, exit: 500 }}>
            {isLoading && (
              <Box sx={{ textAlign: 'center', pt: 1 }}>
                <CircularProgress />
              </Box>
            )}
            {!isLoading && children}
          </Collapse>
        </Box>
      </Paper>
    </>
  )
}

export default StandardPanel
