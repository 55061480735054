import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import Menu from './Menu'
import App from './App'
import Hanyo from './Hanyo'
import SYS0010 from './SYS0010'
import SYS0020 from './SYS0020'
import SYS0030 from './SYS0030'
import SYS0040 from './SYS0040'
import SYS0041 from './SYS0041'

import B0010 from './B0010'
import B0011 from './B0011'
import B0012 from './B0012'
import M0010 from './M0010'

export const store = configureStore({
  //Reducer
  reducer: {
    // システム
    app: App, // システムグローバル管理
    menu: Menu, // メニュー
    SYS0010: SYS0010, // システム設定
    SYS0020: SYS0020, // ロールマスタ
    SYS0030: SYS0030, // ユーザーマスタ
    SYS0040: SYS0040, // ロール権限マスタ
    SYS0041: SYS0041, // ロール権限マスタ

    // 共通機能
    hanyo: Hanyo, // 汎用マスタ

    // // 個別機能
    B0010: B0010, //Vessel List
    B0011: B0011, //Data List
    B0012: B0012, //Comment
    M0010: M0010, //船マスタ
  },

  //Middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(logger)
      .concat(thunk),
  // middleware: [
  //   // thunk,
  //   // routerMiddleware(history),
  //   // logger,
  // ],
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
