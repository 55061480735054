import React from 'react'

import { Link } from '@mui/material'

import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'
import { formatDateTimeByUSShort } from 'src/js/util/utility'
import { B0010 as RESOURCE } from 'src/resource'

type Props = {
  data: { [key: string]: string }
  onClick: (row: { [key: string]: string }) => void
}
const LastCommentLink = ({ data, onClick }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType

  if (!!!fileSumInfo.lastComment) {
    return (
      <Link
        component='button'
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onClick(data)
        }}
      >
        {RESOURCE.LBL_COMMENT_LINK}
      </Link>
    )
  }

  return (
    <>
      <Link
        component='button'
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onClick(data)
        }}
      >
        {fileSumInfo.lastComment.comment}
      </Link>
      <br />
      <Link
        component='button'
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onClick(data)
        }}
      >
        {formatDateTimeByUSShort(fileSumInfo.lastComment.createdAt)}
      </Link>
    </>
  )
}

export default LastCommentLink
