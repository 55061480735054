import React from 'react'

import { Box } from '@mui/material'

type Props = {
  index: number
}
const SeqNoLabel = ({ index }: Props) => {
  return <Box>{index}</Box>
}

export default SeqNoLabel
