import React from 'react'

import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { FilterCtnGrid, FilterItmGrid } from 'src/js/component/Base/Grid'
import { SYS0040 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

const Header = () => {
  return (
    <FilterCtnGrid>
      {/* ID */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='id'
          label={RESOURCE.LBL_ID}
          size='small'
          disabled
          refer={{ funcId: FUNC.SYS0041, partId: 'header', itemId: 'id' }}
        />
      </FilterItmGrid>

      {/* 名称 */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='name'
          label={RESOURCE.LBL_NAME}
          size='small'
          disabled
          refer={{ funcId: FUNC.SYS0041, partId: 'header', itemId: 'name' }}
        />
      </FilterItmGrid>

      {/* コメント */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='comment'
          label={RESOURCE.LBL_COMMENT}
          size='small'
          disabled
          refer={{ funcId: FUNC.SYS0041, partId: 'header', itemId: 'comment' }}
        />
      </FilterItmGrid>
    </FilterCtnGrid>
  )
}
export default Header
