/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      comment
      createdAt
      updatedAt
    }
  }
`
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $id: ID
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
