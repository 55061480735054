import React from 'react'

import { Grid } from '@mui/material'

type Props = {
  children: React.ReactNode
}
const DrawerItmGrid = ({ children }: Props) => {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  )
}

export default DrawerItmGrid
