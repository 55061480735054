import React from 'react'

import { setFilter } from 'src/js/slice/B0011'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { HanyoSelectRef } from 'src/js/component/Base/Select'
import { StdDateRef } from 'src/js/component/Base/DatePicker'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { HANYO_KBN } from 'src/js/util/constants'
import { B0011 as RESOURCE } from 'src/resource'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* Uploaded Date From */}
      <DrawerItmGrid>
        <StdDateRef
          id={'uploadedDateF'}
          label={RESOURCE.LBL_UPLOADED_DATE_FROM}
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'uploadedDateF' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Uploaded Date To */}
      <DrawerItmGrid>
        <StdDateRef
          id={'uploadedDateT'}
          label={RESOURCE.LBL_UPLOADED_DATE_TO}
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'uploadedDateT' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* File Type */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'fileType'}
          label={RESOURCE.LBL_FILE_TYPE}
          hanyoKbn={HANYO_KBN.FILE_TYPE}
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'fileType' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* File Status */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'status'}
          label={RESOURCE.LBL_STATUS}
          hanyoKbn={HANYO_KBN.FILE_STATUS}
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'status' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* File Name */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='fileName'
          label={RESOURCE.LBL_FILE_NAME}
          size='small'
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'fileName' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* File Name */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='version'
          label={RESOURCE.LBL_VERSION}
          size='small'
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'version' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Checked Date From */}
      <DrawerItmGrid>
        <StdDateRef
          id={'checkedDate'}
          label={RESOURCE.LBL_CHECKED_DATE}
          refer={{ funcId: 'B0011', partId: 'filter', itemId: 'checkedDate' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
