/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      comment
    }
  }
`
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
    deleteRole(input: $input, condition: $condition) {
      id
    }
  }
`
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
    createRole(input: $input, condition: $condition) {
      id
      name
      comment
    }
  }
`
