import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'

import * as GqlType from 'src/API'
import * as Type from './type'

export const search = async (pFilter: Type.Filter): Promise<GqlType.Role[]> => {
  const rsp = await API.graphql({
    query: Queries.listRoles,
    variables: { filter: _makeFilterInput(pFilter) },
  })
  const roles = lo.get(rsp, 'data.listRoles.items') as GqlType.Role[]

  return !!roles ? roles : []
}

const _makeFilterInput = (pFilter: Type.Filter): GqlType.ModelRoleFilterInput => {
  const filter: GqlType.ModelRoleFilterInput = {}

  if (!!pFilter.name) {
    filter.name = { contains: pFilter.name }
  }

  if (!!pFilter.comment) {
    filter.comment = { contains: pFilter.comment }
  }

  return filter
}
