import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'

import { Link } from '@mui/material'

type Props = {
  label: string
  path: string
  level?: 'private' | 'protected' | 'public'
}
const S3DownloadLink = ({ label, path, level = 'public' }: Props) => {
  const [url, setUrl] = useState<string>('')

  // 初期化
  useEffect(() => {
    ;(async () => {
      const singnedUrl = await Storage.get(path, { level: level, download: false })
      setUrl(singnedUrl)
    })()
  }, [label, path])

  return <Link href={url}>{label}</Link>
}

export default S3DownloadLink
