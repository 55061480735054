import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Fade, List, ListItemButton, ListItemText, Collapse, colors } from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'

import * as Style from './style'
import * as Type from 'src/js/type/base'

type ContentProps = {
  menu: Type.Menu
  curIdx: number
  activeIdx: number
  setActiveIdx: (index: number) => void
  openFlg: boolean
  setOpenFlg: (openFlg: boolean) => void
}
const Content = ({ menu, curIdx, activeIdx, setActiveIdx, openFlg, setOpenFlg }: ContentProps) => {
  const [selectIdx, setSelectIdx] = React.useState<number>(-1)
  const navigate = useNavigate()

  const _onClickGrpMenu = () => {
    setActiveIdx(curIdx === activeIdx ? -1 : curIdx)
  }

  const _goto = (pathname: string, itemIdx: number) => {
    setSelectIdx(itemIdx)
    navigate(pathname)
  }

  const _onMenuIconClick = () => {
    setOpenFlg(true)
  }

  return (
    <>
      {/* メニュー */}
      <ListItemButton sx={Style.menuHeader} onClick={_onClickGrpMenu}>
        {/* メニューアイコン */}
        {menu.Icon && <menu.Icon sx={{ mr: 1 }} onClick={_onMenuIconClick} />}

        {/* メニュー名 */}
        <Fade in={openFlg} timeout={{ exit: 500, enter: 800 }}>
          <ListItemText>{menu.name}</ListItemText>
        </Fade>

        {/* グループメニュー折り畳みアイコン制御 */}
        <Fade in={openFlg} timeout={{ exit: 500, enter: 800 }}>
          <ChevronRightIcon
            sx={[
              Style.expandIcon,
              curIdx === activeIdx ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0)' },
            ]}
          />
        </Fade>
      </ListItemButton>

      {/* メニューアイテム */}
      <Collapse in={curIdx === activeIdx && openFlg} timeout={{ enter: 500, exit: 500 }}>
        <List component='div' disablePadding>
          {menu.menuItem.items.map((item, itemIdx) => (
            // メニュ－
            <ListItemButton
              key={itemIdx}
              sx={Style.menuItem}
              selected={itemIdx === selectIdx}
              onClick={() => {
                _goto(`/${item.page.funcId}`, itemIdx)
              }}
            >
              {/* メニュー名 */}
              <Fade in={openFlg} timeout={{ exit: 500, enter: 500 }}>
                <ListItemText>{item.page.name}</ListItemText>
              </Fade>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

type Props = {
  openFlg: boolean
  setOpenFlg: (openFlg: boolean) => void
  data: Type.Menu[]
}
const Menu = ({ openFlg, setOpenFlg, data }: Props) => {
  const [activeIdx, setActiveIdx] = useState<number>(-1)
  return (
    <List sx={{ backgroundColor: (theme) => theme.palette.primary.dark, color: colors.grey[50] }}>
      {data.map((menu, menuIdx) => (
        <Content
          key={menuIdx}
          menu={menu}
          curIdx={menuIdx}
          activeIdx={activeIdx}
          setActiveIdx={setActiveIdx}
          openFlg={openFlg}
          setOpenFlg={setOpenFlg}
        />
      ))}
    </List>
  )
}

export default Menu
