import React from 'react'

import { setInput } from 'src/js/slice/SYS0020'
import { FormDialog } from 'src/js/component/Base/Dialog'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { StdTextFieldRef, RegExpTextFieldRef } from 'src/js/component/Base/TextField'
import { FUNCTION_ID as FUNC, REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS0020 as RESOURCE, SYS_MSG } from 'src/resource'

import * as Type from 'src/js/type/base'

type Props = {
  open: boolean
  mode: Type.DialogStatus
  onOK: () => void
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}
const Input = ({ open, mode, onOK, onClose, onDelete, isLoading = true }: Props) => {
  return (
    <FormDialog
      title={RESOURCE.TITLE_INPUT}
      mode={mode}
      isLoading={isLoading}
      open={open}
      onCancel={onClose}
      onOK={onOK}
      onDelete={onDelete}
    >
      <FormCtnGrid>
        {/* ID */}
        <FormItmGrid>
          <RegExpTextFieldRef
            id='id'
            label={RESOURCE.LBL_ID}
            size='small'
            regExp={REGEX.HALF_N_E}
            errMsg={SYS_MSG.ERRSS005}
            refer={{ funcId: FUNC.SYS0020, partId: 'input', itemId: 'id' }}
            onChange={setInput}
            disabled={mode === 'edit'}
            required
          />
        </FormItmGrid>

        {/* 名称 */}
        <FormItmGrid>
          <RegExpTextFieldRef
            id='name'
            label={RESOURCE.LBL_NAME}
            size='small'
            regExp={REGEX.HALF_N_E}
            errMsg={SYS_MSG.ERRSS005}
            refer={{ funcId: FUNC.SYS0020, partId: 'input', itemId: 'name' }}
            onChange={setInput}
            required
          />
        </FormItmGrid>

        {/* コメント */}
        <FormItmGrid>
          <StdTextFieldRef
            id='comment'
            label={RESOURCE.LBL_COMMENT}
            size='small'
            refer={{ funcId: FUNC.SYS0020, partId: 'input', itemId: 'comment' }}
            onChange={setInput}
          />
        </FormItmGrid>
      </FormCtnGrid>
    </FormDialog>
  )
}
export default Input
