import { Theme } from '@mui/material/styles'
import { colors } from '@mui/material'

const DRAWER_WIDTH = 240

export const appBar = (theme: Theme) =>
  ({
    bgcolor: colors.common.white,
    color: colors.blueGrey[700],
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen + 300,
    }),
  } as const)

export const appBarShift = (theme: Theme) =>
  ({
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
  } as const)

export const appBarClps = (theme: Theme) =>
  ({
    paddingLeft: theme.spacing(7),
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
  } as const)

export const menuIcon = (theme: Theme) =>
  ({
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
  } as const)

export const menuIconOpen = { transform: 'rotate(0)' } as const

export const menuIconClps = { transform: 'rotate(-90deg)' } as const

export const menuBtnDesktop = (theme: Theme) =>
  ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  } as const)

export const menuBtnMobile = (theme: Theme) =>
  ({
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  } as const)
