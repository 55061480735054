import React from 'react'
import { useAppDispatch } from 'src/js/slice/hooks'

import { FormControl, FormControlLabel, Checkbox, Box } from '@mui/material'

type Props = {
  id: string
  label: string
  value: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function // イベント：値変更
  dispatchFlg?: boolean
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default'
  disabled?: boolean
  size?: 'small' | 'medium'
  checkBoxSize?: 'small' | 'medium'
  labelSize?: string
}
const StandardCheckBox = ({
  id,
  label,
  value,
  onChange,
  color = 'secondary',
  disabled = false,
  dispatchFlg = true,
  size = 'medium',
  checkBoxSize = 'medium',
  labelSize,
}: Props) => {
  const dispatch = useAppDispatch()

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (dispatchFlg) {
      dispatch(onChange({ id: id, value: checked }))
    } else {
      onChange({ id: id, value: checked })
    }
  }

  const _convertValue = (value: boolean | number | string): boolean => {
    if (typeof value === 'boolean') return value
    if (typeof value === 'string') return value === '1'
    return value === 1
  }
  return (
    <FormControl component='fieldset'>
      <FormControlLabel
        sx={size === 'medium' ? { mb: '0.8rem' } : { mb: '0rem' }}
        control={
          <Checkbox
            id={id}
            checked={_convertValue(value)}
            disabled={disabled}
            onChange={_onChange}
            color={color}
            size={checkBoxSize}
          />
        }
        label={<Box sx={[labelSize ? { fontSize: labelSize } : {}]}>{label}</Box>}
        labelPlacement='end'
      />
    </FormControl>
  )
}

export default StandardCheckBox
