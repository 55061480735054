import React, { useState } from 'react'
import { Action } from '@reduxjs/toolkit'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import lo from 'lodash'

import { Autocomplete, TextField } from '@mui/material'

import { SYS_MSG } from 'src/resource'
import * as Type from 'src/js/type/base'

type Props = {
  id: string
  label: string
  refer: Type.Reference
  referOpts: Type.Reference
  onChange: (item: { id: string; value: Type.Option | null }) => Action
  required?: boolean
  helperText?: string
  loadingText?: string
  disabled?: boolean
  onError?: (id: string) => void
}
const StandardAutocompleteRef = ({
  id,
  label,
  refer,
  referOpts,
  onChange,
  required = false,
  helperText = ' ',
  loadingText = 'Loading...',
  disabled = false,
  onError,
}: Props) => {
  const [state, setState] = useState<{ isError: boolean; msg: string }>({
    isError: false,
    msg: '',
  })
  const value = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as string
  const options = useAppSelector((state) =>
    referOpts.partId
      ? lo.get(state, `${referOpts.funcId}.${referOpts.partId}.${referOpts.itemId}`)
      : lo.get(state, `${referOpts.funcId}.${referOpts.itemId}`)
  ) as Type.Option[]

  const dispatch = useAppDispatch()

  const _onChange = (event: React.SyntheticEvent, value: Type.Option | null) => {
    //必須入力エラーの場合
    if (required && (!value || lo.isEmpty(value))) {
      setState({ isError: true, msg: SYS_MSG.ERRSS001 })
      onError && onError(id)
      dispatch(onChange({ id: id, value: null }))
      return
    }

    //通常
    setState({ isError: false, msg: '' })
    dispatch(onChange({ id: id, value: value }))
  }

  const _getValue = () => {
    if (!value || lo.isEmpty(options)) return null

    const rst = lo.find(options, { value: value })
    return !rst || lo.isEmpty(rst) ? null : rst
  }

  return (
    <Autocomplete
      disablePortal
      autoHighlight
      loading
      loadingText={loadingText}
      disabled={disabled}
      id={id}
      options={options}
      value={_getValue()}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label={label}
          error={state.isError}
          required={required}
          helperText={state.isError ? state.msg : helperText}
          fullWidth={true}
          margin='none'
        />
      )}
      onChange={_onChange}
    />
  )
}

export default StandardAutocompleteRef
