import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import MainContent from './MainContent'
import Footer from './Footer'
import * as Style from './style'

const Frame = () => {
  const [sbOpenFlg, setSbOpenFlg] = useState<boolean>(false)

  return (
    <Box sx={{ display: 'flex' }}>
      {/* トップナビバー */}
      <Navbar openFlg={sbOpenFlg} setOpenFlg={setSbOpenFlg} />

      {/* サイドメニュー */}
      <Sidebar openFlg={sbOpenFlg} setOpenFlg={setSbOpenFlg} />

      {/* コンテンツ */}
      <Box sx={[Style.content, sbOpenFlg ? Style.contentShift : Style.contentClps]}>
        {/* メイン */}
        <MainContent>
          <Outlet />
        </MainContent>

        {/* フッター */}
        <Footer />
      </Box>
    </Box>
  )
}
export default Frame
