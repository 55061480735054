import { Theme } from '@mui/material/styles'
import { colors } from '@mui/material'

const DRAWER_WIDTH = 240

export const sidebar = {
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
} as const

export const sidebarShift = (theme: Theme) =>
  ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
    backgroundColor: theme.palette.primary.dark,
  } as const)

export const sidebarClps = (theme: Theme) =>
  ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    backgroundColor: theme.palette.primary.dark,
  } as const)

export const toolbar = (theme: Theme) =>
  ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.dark,
    height: 56,
  } as const)

export const menuHeader = {
  color: colors.grey[50],
  '& svg': {
    color: colors.grey[50],
    verticalAlign: 'middle',
  },
  '& span': {
    fontSize: '1rem',
  },
} as const

export const expandIcon = (theme: Theme) =>
  ({
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard + 300,
    }),
  } as const)

export const menuItem = (theme: Theme) =>
  ({
    paddingLeft: theme.spacing(6),
    '& span': {
      fontSize: '0.8rem',
    },
  } as const)
