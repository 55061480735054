import React from 'react'
import { Breadcrumbs as MuiBreadcrumbs, Typography, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'src/js/slice/hooks'
// import { AccountCircle as AccountCircleIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material'
// import { SYS_CMN } from 'src/resource'

import { LocationMark as LocationMarkType } from 'src/js/type/base'

const Breadcrumbs = () => {
  const footmark = useAppSelector((state) => state.app.footmark)
  const navigate = useNavigate()

  const _goto = (mark: LocationMarkType) => {
    navigate(mark.pathname)
  }

  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      {footmark.map((mark, index) => {
        if (index === footmark.length - 1) {
          return (
            <Typography color='textPrimary' key={index}>
              {mark.label}
            </Typography>
          )
        } else {
          return (
            <Link
              key={index}
              color='inherit'
              component='button'
              onClick={() => {
                _goto(mark)
              }}
            >
              {mark.label}
            </Link>
          )
        }
      })}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
