/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateFileSumInfo = /* GraphQL */ `
  mutation UpdateFileSumInfo(
    $input: UpdateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    updateFileSumInfo(input: $input, condition: $condition) {
      id
      eumsUnavailableFlag
    }
  }
`
export const updateMstVessel = /* GraphQL */ `
  mutation UpdateMstVessel(
    $input: UpdateMstVesselInput!
    $condition: ModelMstVesselConditionInput
  ) {
    updateMstVessel(input: $input, condition: $condition) {
      id
      eumsUnavailableFlag
    }
  }
`
