import React from 'react'
import { Button } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  sx?: SxProps<Theme>
  label: string
  variant?: 'text' | 'outlined' | 'contained'
  disabled?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  onClick: () => void
  startIcon?: React.ReactNode
}
const CustomButton = ({
  sx,
  label,
  variant = 'contained',
  disabled = false,
  color = 'primary',
  onClick,
  startIcon,
}: Props) => {
  return (
    <Button
      sx={sx}
      variant={variant}
      disabled={disabled}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
    >
      {label}
    </Button>
  )
}

export default CustomButton
