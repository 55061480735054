import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { query, mutation } from 'src/js/util/graphql'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import * as Api from './api'

import * as GqlType from 'src/API'
import * as Type from './type'

// 非同期処理定義
// 検索処理
export const search = createAsyncThunk<GqlType.Role[], undefined, { state: RootState }>(
  `${FUNC.SYS0020}/search`,
  async (_, thunkAPI) => {
    try {
      return await query<Type.Filter, GqlType.Role[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().SYS0020.filter,
        async (dispatch, filter) => {
          return await Api.search(filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 明細表示
export const input = createAsyncThunk<GqlType.Role, string, { state: RootState }>(
  `${FUNC.SYS0020}/input`,
  async (id, thunkAPI) => {
    try {
      return await query<string, GqlType.Role>(thunkAPI.dispatch, id, async (dispath, id) => {
        return await Api.findByKey(id)
      })
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 更新処理
export const update = createAsyncThunk<GqlType.Role, undefined, { state: RootState }>(
  `${FUNC.SYS0020}/update`,
  async (_, thunkAPI) => {
    try {
      return await mutation<Type.Input, GqlType.Role>(
        thunkAPI.dispatch,
        thunkAPI.getState().SYS0020.input,
        async (dispatch, input) => {
          // 有効性チェック
          const errMsg = await Api.validation(input)
          if (!lo.isEmpty(errMsg)) {
            throw errMsg
          }

          // 更新処理
          return input.mode == 'new' ? await Api.create(input) : await Api.update(input)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 更新処理
export const deleteById = createAsyncThunk<void, undefined, { state: RootState }>(
  `${FUNC.SYS0020}/deleteById`,
  async (_, thunkAPI) => {
    try {
      return await mutation<string, void>(
        thunkAPI.dispatch,
        thunkAPI.getState().SYS0020.input.id,
        async (dispatch, id) => {
          await Api.deleteById(id)
          return
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

const getInitialState = (): Type.State => ({
  // 検索条件
  filter: {
    name: '',
    comment: '',
  },

  // 明細
  input: {
    isLoading: false,
    mode: 'new',
    id: '',
    name: '',
    comment: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
  },
})
export const Slice = createSlice({
  name: FUNC.SYS0020,
  initialState: getInitialState(),
  reducers: {
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    setInput: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.input, id, value)
    },
    initInput: (state) => {
      state.input = getInitialState().input
      state.input.mode = 'new'
    },
    clearFilter: (state) => {
      state.filter = getInitialState().filter
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.filter = initState.filter
      state.input = initState.input
      state.result = initState.result
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // 入力画面初期化
      .addCase(input.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(input.fulfilled, (state, action) => {
        state.input = {
          isLoading: false,
          mode: 'edit',
          id: action.payload.id,
          name: action.payload.name,
          comment: action.payload.comment || '',
        }
      })
      .addCase(input.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 入力更新
      .addCase(update.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(update.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 削除
      .addCase(deleteById.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(deleteById.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(deleteById.rejected, (state) => {
        state.input = getInitialState().input
      })
  },
})

export const { setFilter, setInput, initInput, gc, clearFilter, cancelFilter } = Slice.actions

export default Slice.reducer
