import React from 'react'

import { Toolbar, Typography, Box, Button, Pagination, Badge } from '@mui/material'
import {
  FilterList as FilterListIcon,
  AddCircle as AddCircleIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material'

import { SYS_CMN } from 'src/resource'
import * as Style from './style'
import * as Type from 'src/js/type/base'

type Props = {
  title: string // タイトル
  setOpenFlg: (flg: boolean) => void // ファンクション：Openフラグ設定
  isLoading: boolean // ローディングフラグ
  inputFilterCount?: number

  paging: Type.Pagging | undefined // ページング対象
  onPaging: (pageNum: number) => void // イベント：ページング

  addFlg?: boolean // 新規イベント有無フラグ
  onAdd?: () => void // イベント：新規

  exportFlg?: boolean // エクスポート有無フラグ
  csv?: { fileName: string | undefined; getCsv: () => string }

  custBtnFlg?: boolean // カスタマイズボタン有無フラグ
  CustBtn: React.ReactNode // コンポ：カスタマイズボタン
}
const TitleBar = ({
  title,
  setOpenFlg,
  isLoading,
  inputFilterCount = 0,
  paging,
  onPaging,
  addFlg = false,
  onAdd,
  exportFlg = false,
  csv,
  custBtnFlg = false,
  CustBtn,
}: Props) => {
  // CSV出力処理
  const _export = (e: React.MouseEvent) => {
    e.preventDefault()

    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, csv ? csv.getCsv() : ''], { type: 'text/csv' })

    const a = document.createElement('a')
    a.download = csv && csv.fileName ? csv.fileName : 'fileName.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return (
    <Toolbar sx={{ px: 2, justifyContent: 'flex-start' }} variant='dense'>
      <Typography
        // sx={{ flex: '1 1 70%', color: (theme) => theme.palette.primary.main }}
        sx={{ flexGrow: 1, color: (theme) => theme.palette.primary.main }}
        variant='h6'
        component='div'
      >
        {title}
      </Typography>

      <Box>
        {/* フィルター */}
        <Badge badgeContent={inputFilterCount} color='info'>
          <Button
            sx={Style.toobarBtn}
            size='small'
            startIcon={<FilterListIcon />}
            onClick={() => {
              setOpenFlg(true)
            }}
          >
            {SYS_CMN.BTN_FILTER}
          </Button>
        </Badge>

        {/* 新規ボタン */}
        {addFlg && onAdd && (
          <Button
            sx={Style.toobarBtn}
            size='small'
            startIcon={<AddCircleIcon />}
            onClick={() => {
              onAdd()
            }}
          >
            {SYS_CMN.BTN_ADD}
          </Button>
        )}

        {/* エクスポートボタン */}
        {exportFlg && (
          <Button sx={Style.toobarBtn} size='small' startIcon={<GetAppIcon />} onClick={_export}>
            {SYS_CMN.BTN_EXPORT}
          </Button>
        )}

        {custBtnFlg && CustBtn}
      </Box>

      {/* ページング */}
      <Box>
        {!isLoading && (
          <Badge badgeContent={paging?.total} color='info' max={9999}>
            <Pagination
              count={paging?.count}
              page={paging?.page}
              color='primary'
              size='small'
              shape='rounded'
              showFirstButton
              showLastButton
              onChange={(e, page) => {
                onPaging(page)
              }}
            />
          </Badge>
        )}
      </Box>
    </Toolbar>
  )
}

export default TitleBar
