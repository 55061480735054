import React from 'react'

import { Box } from '@mui/material'

type Props = {
  value: string
}
const SendingPGVerLabel = ({ value }: Props) => {
  return <Box>{value.replace('eUMSDataSending', '')}</Box>
}

export default SendingPGVerLabel
