export default ((env) => {
  // TODO
  console.log(env)
  const URIContext = 'http://localhost:3000/'
  return {
    application: {
      auth: {
        debug: false,
      },
      tokenId: 'XSRF-TOKEN',
      defaultLanguage: 'ja',
      context: URIContext,
    },
    resource: {
      frontVersion: 'Ver1.0.0',
      copyright: {
        year: '2023',
        desc: 'NYK',
        url: '#',
      },
    },
    api: {
      url: URIContext,
      mock: false,
    },
  }
})(process.env.NODE_ENV)
