import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'
// import { convertToYMDHyphen } from 'src/js/util/utility'

import * as GqlType from 'src/API'
import * as Type from './type'
import { GRAPHQL } from 'src/js/util/constants'

export const updateRemovedFlg = async (id: string, eumsUnavailableFlag: string): Promise<null> => {
  // 船舶マスタ情報更新
  await API.graphql({
    query: Mutations.updateMstVessel,
    variables: { input: { id: id, eumsUnavailableFlag: eumsUnavailableFlag } },
  })

  // FileSumInfo情報更新
  await API.graphql({
    query: Mutations.updateFileSumInfo,
    variables: { input: { id: id, eumsUnavailableFlag: eumsUnavailableFlag } },
  })
  return null
}

export const search = async (filter: Type.Filter): Promise<Type.MstVessel[]> => {
  // MstVesselデータ検索
  const rsp = await API.graphql({
    query: Queries.listFileSumInfos,
    variables: { filter: _makeFilterInput(filter), limit: GRAPHQL.MAX_CNT },
  })
  const fileSumList = lo.get(rsp, 'data.listFileSumInfos.items') as Type.FileSumInfo[]
  if (!!!fileSumList) return []

  const vesselList = [] as Type.MstVessel[]
  fileSumList.forEach((e) => {
    if (!!e.vessel) {
      vesselList.push(e.vessel)
    }
  })
  return lo.orderBy(vesselList, ['vesselName'], ['asc'])
}

const _makeFilterInput = (filter: Type.Filter): GqlType.ModelMstVesselFilterInput => {
  const filterInput: GqlType.ModelMstVesselFilterInput = {}

  // Imo No
  if (!!filter.imoNo) {
    filterInput.imoNo = { beginsWith: filter.imoNo }
  }

  // Vessel Name
  if (!!filter.vesselName) {
    filterInput.vesselName = { contains: filter.vesselName }
  }

  // Ship Manager
  if (!!filter.shipManager) {
    filterInput.shipManagerShort = { eq: filter.shipManager }
  }

  // Ship Manager
  if (!!filter.aliveStatus) {
    filterInput.aliveStatus = { eq: filter.aliveStatus }
  }
  return filterInput
}

// // コメント取得
// export const findEquipmentById = async (id: string): Promise<GqlType.Equipment> => {
//   const rsp = await API.graphql({
//     query: Queries.getEquipment,
//     variables: { id: id },
//   })
//   return lo.get(rsp, 'data.getEquipment') as GqlType.Equipment
// }
