import React from 'react'
import { Box, Alert, AlertTitle } from '@mui/material'

const PasswordPolicyMessage = () => {
  return (
    <Box sx={{ flexDirection: 'column' }}>
      <AlertTitle>PASSWORD POLICY</AlertTitle>
      <Box>
        <li>At least 8 characters.</li>
        <li>
          Contain at least 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter.
        </li>
      </Box>
    </Box>
  )
}

const SignInFooter = () => {
  return (
    <Box sx={{ pt: 1 }}>
      <Alert severity='info'>
        <PasswordPolicyMessage />
      </Alert>
    </Box>
  )
}
export default SignInFooter
