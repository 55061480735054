import React from 'react'
import { Menu, MenuItem, IconButton, PopoverProps } from '@mui/material'
import { AccountCircle as AccountCircleIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material'
import { SYS_CMN } from 'src/resource'

type Props = {
  menuId: string
  anchorEl: PopoverProps['anchorEl']
  isMenuOpen: boolean
  handleMenuClose: () => void
}
const MobileMenu = ({ menuId, anchorEl, isMenuOpen, handleMenuClose }: Props) => {
  // TODO
  const user = {
    userNm: 'userNm',
    roleDesc: 'roleDesc',
  }
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <IconButton aria-label='user information' color='inherit'>
          <AccountCircleIcon />
        </IconButton>
        {user.userNm}
      </MenuItem>
      <MenuItem>
        <IconButton aria-label='system logout' color='inherit'>
          <ExitToAppIcon />
        </IconButton>
        {SYS_CMN.LBL_LOGOUT}
      </MenuItem>
    </Menu>
  )
}

export default MobileMenu
