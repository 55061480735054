/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateFileSumInfo = /* GraphQL */ `
  mutation UpdateFileSumInfo(
    $input: UpdateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    updateFileSumInfo(input: $input, condition: $condition) {
      id
      operationStatus
    }
  }
`
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      fmGoVer
      ipadEumsVer
    }
  }
`
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
    }
  }
`
