import React from 'react'

import { IconButton, Tooltip, Zoom, colors } from '@mui/material'
import { Refresh as RefreshIcon } from '@mui/icons-material'

import { SYS_CMN } from 'src/resource'

type Props = {
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  onClick: () => void
}
const ClearIconButton = ({ size = 'medium', disabled = false, onClick }: Props) => {
  return (
    <Tooltip title={SYS_CMN.BTN_TIP_CLEAR} aria-label='clear' arrow TransitionComponent={Zoom}>
      <IconButton
        sx={(theme) => ({
          color: colors.brown[600],
          pl: theme.spacing(1),
          pr: theme.spacing(1),
        })}
        size={size}
        disabled={disabled}
        onClick={onClick}
      >
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ClearIconButton
