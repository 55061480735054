import React from 'react'

import { Grid } from '@mui/material'

type Props = {
  children: React.ReactNode
}
const FormCtnGrid = ({ children }: Props) => {
  return (
    <Grid container spacing={2} alignContent='space-between' alignItems='center'>
      {children}
    </Grid>
  )
}

export default FormCtnGrid
