/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateSNConfirm = /* GraphQL */ `
  mutation UpdateSNConfirm(
    $input: UpdateSNConfirmInput!
    $condition: ModelSNConfirmConditionInput
  ) {
    updateSNConfirm(input: $input, condition: $condition) {
      version
      confirmFlg
      userId
    }
  }
`
export const createSNConfirm = /* GraphQL */ `
  mutation CreateSNConfirm(
    $input: CreateSNConfirmInput!
    $condition: ModelSNConfirmConditionInput
  ) {
    createSNConfirm(input: $input, condition: $condition) {
      version
      confirmFlg
      userId
    }
  }
`
export const updateFileInfo = /* GraphQL */ `
  mutation UpdateFileInfo($input: UpdateFileInfoInput!, $condition: ModelFileInfoConditionInput) {
    updateFileInfo(input: $input, condition: $condition) {
      id
      fileStatus
    }
  }
`
export const updateNumberOfErrorByImoNo = /* GraphQL */ `
  mutation UpdateNumberOfErrorByImoNo(
    $input: UpdateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    updateFileSumInfo(input: $input, condition: $condition) {
      id
      numberOfError
    }
  }
`
