import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import lo from 'lodash'

import { callSearchApi } from 'src/js/util/axios'
import { query } from 'src/js/util/graphql'
import * as Api from './api'

import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import * as Type from 'src/js/type/base'

// 非同期処理定義
// 検索処理
export const initHanyo = createAsyncThunk<Type.HanyoItem, string>(
  `${FUNC.HANYO}/init`,
  async (kbn, thunkAPI) => {
    try {
      // コードマスタ情報取得
      const rspQuery = await query<string, Type.HanyoItem>(
        thunkAPI.dispatch,
        kbn,
        async (dispath, kbn) => {
          return await Api.initCodeMaster(kbn)
        }
      )
      if (rspQuery) return rspQuery

      // モックデータ利用
      const rsp = await callSearchApi(`${FUNC.HANYO}/get`, { kbn: kbn }, thunkAPI)
      return rsp.data.data as Type.HanyoItem
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// Slice定義
const initialState: { [key: string]: Type.HanyoItem } = {}
export const Slice = createSlice({
  name: FUNC.HANYO,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(initHanyo.pending, () => {
        // Do Nothing
      })
      .addCase(initHanyo.fulfilled, (state, action) => {
        state[action.meta.arg] = action.payload
      })
      .addCase(initHanyo.rejected, (state) => {
        state = {}
        console.log(state)
      })
  },
})

export const {} = Slice.actions

export default Slice.reducer
