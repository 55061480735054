import React from 'react'
import { FallbackProps } from 'react-error-boundary'

const SystemError = ({ error }: FallbackProps) => {
  return (
    <div>
      <h1 className='text-center'>システムエラー</h1>
      <pre>{error.message}</pre>
    </div>
  )
}

export default SystemError
