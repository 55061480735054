import React, { useEffect } from 'react'
import { useAppDispatch } from 'src/js/slice/hooks'
import { useNavigate } from 'react-router-dom'

import { RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import Filter from './Filter'
import { search, cancelFilter, clearFilter, gc, restorePageSession } from 'src/js/slice/SYS0040'
import { savePageSession } from 'src/js/slice/App'

import { SYS0040 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

const SYS0040 = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // 初期化
  useEffect(() => {
    ;(async () => {
      // ページセッション復元
      await dispatch(restorePageSession())
      // 検索処理
      await dispatch(search())
    })()

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  const _gotoSYS0041 = (row: TableRowType) => {
    // ステータス保存
    dispatch(savePageSession(FUNC.SYS0040))

    // 画面遷移
    navigate('/SYS0041', { state: { p1: row.id } })
  }

  // カラム定義
  const columns = [
    {
      key: 'id',
      label: RESOURCE.LBL_ID,
      align: 'left',
      type: 'button',
      onClick: (row) => {
        _gotoSYS0041(row)
      },
      width: '10%',
    },
    { key: 'name', label: RESOURCE.LBL_NAME, align: 'left', width: '20%' },
    { key: 'comment', label: RESOURCE.LBL_COMMENT, align: 'left', width: '70%' },
  ] as TableColumnType[]

  return (
    <>
      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={RESOURCE.TITLE}
        Filter={<Filter />}
        columns={columns}
        refer={{ funcId: FUNC.SYS0040, itemId: 'result' }}
        onSearch={() => {
          dispatch(search())
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(clearFilter())
        }}
      />
    </>
  )
}
export default SYS0040
