import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'src/js/slice/hooks'
import lo from 'lodash'

import { Paper, Box } from '@mui/material'

import { StandardTable, TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import TitleBar from './TitleBar'
import { GotoTopButton } from 'src/js/component/Base/Button'

import * as Type from 'src/js/type/base'

type Props = {
  title: string // タイトル
  columns: TableColumnType[] // カラム定義
  refer: Type.Reference // 参照項目

  addFlg?: boolean // 新規イベント有無フラグ
  onAdd?: () => void // イベント：新規

  custBtnFlg?: boolean // カスタマイズボタン有無フラグ
  CustBtn?: React.ReactNode // コンポ：カスタマイズボタン

  pageCount?: number
}
const RichTablePanelRef = ({
  title,
  columns,
  refer,
  addFlg = false,
  onAdd,
  custBtnFlg = false,
  CustBtn,
  pageCount = 20,
}: Props) => {
  const [tblData, setTblData] = useState<TableRowType[]>([])
  const [paging, setPaging] = useState<Type.Pagging>({ page: 0, count: 0, total: 0 })
  const state = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as Type.ResultTable<TableRowType[]>

  useEffect(() => {
    // 検索処理
    setTblData(lo.slice(state.data, 0, pageCount))
    setPaging({
      page: 1,
      count: state.data.length < pageCount ? 1 : lo.ceil(state.data.length / pageCount),
      total: state.data.length,
    })
  }, [state])

  const _onPaging = (pPageNo: number) => {
    const startIdx = pPageNo === 1 ? 0 : (pPageNo - 1) * pageCount + 1 - 1
    setTblData(lo.slice(state.data, startIdx, startIdx + pageCount))
    setPaging({
      page: pPageNo,
      count: state.data.length < pageCount ? 1 : lo.ceil(state.data.length / pageCount),
      total: state.data.length,
    })
  }

  return (
    <Paper sx={{ width: 1, mb: 2 }}>
      <TitleBar
        // 基本プロパティ
        title={title}
        isLoading={state.isLoading}
        addFlg={addFlg}
        onAdd={onAdd} // 新規
        custBtnFlg={custBtnFlg}
        CustBtn={CustBtn} // カスタマイズボタン
        paging={paging}
        onPaging={_onPaging} // ページング
      />

      {/* トップ行戻り */}
      <GotoTopButton />

      {/* 結果テーブル */}
      <Box sx={{ pt: 1, px: 2, pb: 4 }}>
        <StandardTable columns={columns} isLoading={state.isLoading} data={tblData} />
      </Box>
    </Paper>
  )
}

export default RichTablePanelRef
