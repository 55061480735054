import React from 'react'

import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { FilterCtnGrid, FilterItmGrid } from 'src/js/component/Base/Grid'
import { B0012 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

const Header = () => {
  return (
    <FilterCtnGrid>
      {/* Imo No */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='imoNo'
          label={RESOURCE.LBL_IMO_NO}
          size='small'
          disabled
          refer={{ funcId: FUNC.B0012, partId: 'header', itemId: 'imoNo' }}
        />
      </FilterItmGrid>

      {/* Vessel Name */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='vesselName'
          label={RESOURCE.LBL_VESSEL_NAME}
          size='small'
          disabled
          refer={{ funcId: FUNC.B0012, partId: 'header', itemId: 'vesselName' }}
        />
      </FilterItmGrid>

      {/* Ship Manager Short */}
      <FilterItmGrid>
        <StdTextFieldRef
          id='shipManagerShort'
          label={RESOURCE.LBL_SHIP_MANAGER}
          size='small'
          disabled
          refer={{ funcId: FUNC.B0012, partId: 'header', itemId: 'shipManagerShort' }}
        />
      </FilterItmGrid>
    </FilterCtnGrid>
  )
}
export default Header
