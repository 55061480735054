import {
  Apps as AppsIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Input as InputIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
  SettingsApplications as SettingsApplicationsIcon,
  DirectionsBoat as DirectionsBoatIcon,
} from '@mui/icons-material'
import SvgIcon from '@mui/material/SvgIcon'
import { Menu as MenuType } from 'src/js/type/base'
import * as GqlType from 'src/API'

type SvgIconComponent = typeof SvgIcon
type IconsType = { id: string; Icon: SvgIconComponent }

// メニューアイコン
export const MenuIcons = [
  { id: 'Apps', Icon: AppsIcon },
  { id: 'InsertDriveFile', Icon: InsertDriveFileIcon },
  { id: 'Input', Icon: InputIcon },
  { id: 'Home', Icon: HomeIcon },
  { id: 'Settings', Icon: SettingsIcon },
  { id: 'SettingsApplications', Icon: SettingsApplicationsIcon },
  { id: 'DirectionsBoat', Icon: DirectionsBoatIcon },
] as IconsType[]

// デフォルトメニュー
export const DEFAULT_MENU_CONFIG = [
  // {
  //   id: '1',
  //   name: 'システム管理',
  //   order: '1',
  //   Icon: undefined,
  //   iconId: 'Apps',
  //   menuItem: {
  //     items: [
  //       {
  //         id: '1',
  //         menuId: '',
  //         name: '',
  //         order: '',
  //         pageId: '',
  //         page: {
  //           id: 'SYS0010',
  //           name: 'システム設定',
  //           funcId: 'SYS0010',
  //           action: '',
  //           order: '',
  //         },
  //       },
  //     ],
  //   },
  // },
] as MenuType[]

// メニュー関連
export const PAGE_INIT_CONFIG = [
  { id: 'SYS0010', name: 'システム設定', funcId: 'SYS0010', action: 'ZZZZZ', order: '10' },
  { id: 'SYS0020', name: 'ロールマスタ', funcId: 'SYS0020', action: 'ZZZZZ', order: '10' },
  { id: 'SYS0030', name: 'ユーザーマスタ', funcId: 'SYS0030', action: 'ZZZZZ', order: '10' },
  { id: 'SYS0040', name: 'ロール権限マスタ', funcId: 'SYS0040', action: 'ZZZZZ', order: '10' },
  { id: 'MM0010', name: '船社マスタ', funcId: 'MM0010', action: 'ZZZZZ', order: '10' },
  { id: 'IE0010', name: '取込み一覧', funcId: 'IE0010', action: 'ZZZZZ', order: '10' },
  { id: 'B0010', name: 'VESSEL LIST', funcId: 'B0010', action: 'ZZZZZ', order: '10' },
  { id: 'B0010', name: 'VESSEL MASTER LIST', funcId: 'M0010', action: 'ZZZZZ', order: '10' },
] as GqlType.Page[]

export const MENU_INIT_CONFIG = [
  { id: '101', name: 'データ取込み', order: '10', icon: 'InsertDriveFile' },
  { id: '102', name: 'MASTER', order: '20', icon: 'Settings' },
  { id: '103', name: 'MONITOR', order: '10', icon: 'DirectionsBoat' },
  { id: '901', name: 'システム管理', order: '30', icon: 'Apps' },
] as GqlType.Menu[]

export const MENUITEM_INIT_CONFIG = [
  { id: '9011', name: 'システム管理', order: '10', menuId: '901', pageId: 'SYS0010' },
  { id: '9012', name: 'システム管理', order: '20', menuId: '901', pageId: 'SYS0020' },
  { id: '9013', name: 'システム管理', order: '30', menuId: '901', pageId: 'SYS0030' },
  { id: '9014', name: 'システム管理', order: '40', menuId: '901', pageId: 'SYS0040' },
  { id: '1011', name: 'データ取込み', order: '50', menuId: '101', pageId: 'IE0010' },
  { id: '1021', name: 'MASTER', order: '60', menuId: '102', pageId: 'M0010' },
  { id: '1031', name: 'VESSEL LIST', order: '70', menuId: '102', pageId: 'B0010' },
] as GqlType.MenuItem[]

export const ROLE_INIT_CONFIG = [
  { id: '1', name: 'SYSTEM', comment: 'システム' },
  { id: '2', name: 'ADMIN', comment: '管理者' },
  { id: '3', name: 'MASTER', comment: 'マスター' },
  { id: '4', name: 'EDITOR', comment: '編集者' },
  { id: '5', name: 'VIEWER', comment: '閲覧者' },
] as GqlType.Role[]

export const ROLE_AUTH_INIT_CONFIG = [
  // SYSTEM
  { id: 'S1', order: '10', roleId: '1', pageId: 'SYS0010', roleType: 'RW' },
  { id: 'S2', order: '20', roleId: '1', pageId: 'SYS0020', roleType: 'RW' },
  { id: 'S3', order: '30', roleId: '1', pageId: 'SYS0030', roleType: 'RW' },
  { id: 'S4', order: '40', roleId: '1', pageId: 'SYS0040', roleType: 'RW' },
  { id: 'S5', order: '50', roleId: '1', pageId: 'MM0010', roleType: 'RW' },
  { id: 'S6', order: '60', roleId: '1', pageId: 'IE0010', roleType: 'RW' },
  { id: 'S7', order: '70', roleId: '1', pageId: 'B0010', roleType: 'RW' },
  { id: 'S8', order: '80', roleId: '1', pageId: 'B0011', roleType: 'RW' },
  { id: 'S9', order: '90', roleId: '1', pageId: 'B0012', roleType: 'RW' },
  { id: 'S10', order: '100', roleId: '1', pageId: 'M0010', roleType: 'RW' },

  // ADMIN
  { id: 'A1', order: '10', roleId: '2', pageId: 'SYS0010', roleType: 'NG' },
  { id: 'A2', order: '20', roleId: '2', pageId: 'SYS0020', roleType: 'NG' },
  { id: 'A3', order: '30', roleId: '2', pageId: 'SYS0030', roleType: 'NG' },
  { id: 'A4', order: '40', roleId: '2', pageId: 'SYS0040', roleType: 'NG' },
  { id: 'A5', order: '50', roleId: '2', pageId: 'MM0010', roleType: 'RW' },
  { id: 'A6', order: '60', roleId: '2', pageId: 'IE0010', roleType: 'RW' },
  { id: 'A7', order: '70', roleId: '2', pageId: 'B0010', roleType: 'RW' },
  { id: 'A8', order: '80', roleId: '2', pageId: 'B0011', roleType: 'RW' },
  { id: 'A9', order: '90', roleId: '2', pageId: 'B0012', roleType: 'RW' },
  { id: 'A10', order: '100', roleId: '2', pageId: 'M0010', roleType: 'RW' },

  // MASTER
  { id: 'M1', order: '10', roleId: '3', pageId: 'SYS0010', roleType: 'NG' },
  { id: 'M2', order: '20', roleId: '3', pageId: 'SYS0020', roleType: 'NG' },
  { id: 'M3', order: '30', roleId: '3', pageId: 'SYS0030', roleType: 'NG' },
  { id: 'M4', order: '40', roleId: '3', pageId: 'SYS0040', roleType: 'NG' },
  { id: 'M5', order: '50', roleId: '3', pageId: 'MM0010', roleType: 'RW' },
  { id: 'M6', order: '60', roleId: '3', pageId: 'IE0010', roleType: 'RW' },
  { id: 'M7', order: '70', roleId: '3', pageId: 'B0010', roleType: 'RW' },
  { id: 'M8', order: '80', roleId: '3', pageId: 'B0011', roleType: 'RW' },
  { id: 'M9', order: '90', roleId: '3', pageId: 'B0012', roleType: 'RW' },
  { id: 'M10', order: '100', roleId: '3', pageId: 'M0010', roleType: 'RW' },

  // EDITOR
  { id: 'E1', order: '10', roleId: '4', pageId: 'SYS0010', roleType: 'NG' },
  { id: 'E2', order: '20', roleId: '4', pageId: 'SYS0020', roleType: 'NG' },
  { id: 'E3', order: '30', roleId: '4', pageId: 'SYS0030', roleType: 'NG' },
  { id: 'E4', order: '40', roleId: '4', pageId: 'SYS0040', roleType: 'NG' },
  { id: 'E5', order: '50', roleId: '4', pageId: 'MM0010', roleType: 'NG' },
  { id: 'E6', order: '60', roleId: '4', pageId: 'IE0010', roleType: 'RW' },
  { id: 'E7', order: '70', roleId: '4', pageId: 'B0010', roleType: 'RW' },
  { id: 'E8', order: '80', roleId: '4', pageId: 'B0011', roleType: 'RW' },
  { id: 'E9', order: '90', roleId: '4', pageId: 'B0012', roleType: 'RW' },
  { id: 'E10', order: '100', roleId: '4', pageId: 'M0010', roleType: 'NG' },

  // VIEWER
  { id: 'V1', order: '10', roleId: '5', pageId: 'SYS0010', roleType: 'NG' },
  { id: 'V2', order: '20', roleId: '5', pageId: 'SYS0020', roleType: 'NG' },
  { id: 'V3', order: '30', roleId: '5', pageId: 'SYS0030', roleType: 'NG' },
  { id: 'V4', order: '40', roleId: '5', pageId: 'SYS0040', roleType: 'NG' },
  { id: 'V5', order: '50', roleId: '5', pageId: 'MM0010', roleType: 'NG' },
  { id: 'V6', order: '60', roleId: '5', pageId: 'IE0010', roleType: 'RO' },
  { id: 'V7', order: '70', roleId: '5', pageId: 'B0010', roleType: 'RO' },
  { id: 'V8', order: '80', roleId: '5', pageId: 'B0011', roleType: 'RO' },
  { id: 'V9', order: '90', roleId: '5', pageId: 'B0012', roleType: 'RO' },
  { id: 'V10', order: '100', roleId: '5', pageId: 'M0010', roleType: 'NG' },
] as GqlType.RoleAuth[]

export const ROLE_USER_INIT_CONFIG = [{ id: 'zj.xin', roleId: '1' }] as GqlType.User[]
