import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'

import { getDateTimeConditionInput } from 'src/js/util/graphql'
import { SYS_MSG } from 'src/resource'

import * as GqlType from 'src/API'
import * as Type from './type'
import { GRAPHQL } from 'src/js/util/constants'

// Master Vessel取得
export const findMstVesselByKey = async (imoNo: string): Promise<GqlType.MstVessel> => {
  const rsp = await API.graphql({
    query: Queries.getMstVessel,
    variables: { id: imoNo },
  })
  return lo.get(rsp, 'data.getMstVessel') as GqlType.MstVessel
}

// 検索処理
export const search = async (imoNo: string, filter: Type.Filter): Promise<Type.Comment[]> => {
  // Commentデータ検索
  const rsp = await API.graphql({
    query: Queries.getCommentsbyImoNo,
    variables: {
      imoNo: imoNo,
      createdAt: getDateTimeConditionInput(filter.createDateF, filter.createDateT),
      sortDirection: 'DESC',
      limit: GRAPHQL.MAX_CNT,
    },
  })
  const list = lo.get(rsp, 'data.getCommentsbyImoNo.items') as Type.Comment[]
  if (!!!list) return []

  return list
}

// 検索処理
export const getLastComment = async (imoNo: string): Promise<Type.Comment | null> => {
  // Commentデータ検索
  const rsp = await API.graphql({
    query: Queries.getCommentsbyImoNo,
    variables: { imoNo: imoNo, limit: 1, sortDirection: 'DESC' },
  })
  const list = lo.get(rsp, 'data.getCommentsbyImoNo.items') as Type.Comment[]
  if (!!!list) return null

  return list[0]
}

// 検証
export const validation = async (imoNo: string, input: Type.Input): Promise<string[]> => {
  const errMsg = [] as string[]

  // IMO No.
  if (!imoNo) errMsg.push(`IMO No.:${SYS_MSG.ERRSS001}`)

  // Comment
  // if (!input.comment) errMsg.push(`Comment:${SYS_MSG.ERRSS001}`)
  console.log(input)

  return errMsg
}

// コメント取得
export const findCommentById = async (id: string): Promise<GqlType.Comment> => {
  const rsp = await API.graphql({
    query: Queries.getComment,
    variables: { id: id },
  })
  return lo.get(rsp, 'data.getComment') as GqlType.Comment
}

// 新規作成
export const create = async (imoNo: string, userId: string, input: Type.Input): Promise<string> => {
  const rsp = await API.graphql({
    query: Mutations.createComment,
    variables: {
      input: {
        imoNo: imoNo,
        comment: input.comment,
        createdAt: new Date(),
        userId: userId,
        fileSumInfo: imoNo,
      },
    },
  })
  const comment = lo.get(rsp, 'data.createComment') as GqlType.Comment
  return comment.id
}

export const update = async (input: Type.Input): Promise<string> => {
  await API.graphql({
    query: Mutations.updateComment,
    variables: { input: { id: input.id, comment: input.comment } },
  })

  return input.id
}

export const updateFileSumInfo = async (
  imoNo: string,
  commentId: string,
  comment: string
): Promise<void> => {
  await API.graphql({
    query: Mutations.updateFileSumInfo,
    variables: { input: { id: imoNo, lastCommentId: commentId, lastCommentContent: comment } },
  })

  return
}

export const deleteById = async (id: string): Promise<void> => {
  await API.graphql({
    query: Mutations.deleteComment,
    variables: { input: { id: id } },
  })
  return
}
