import React from 'react'

import { Box } from '@mui/material'

import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'

type Props = {
  data: { [key: string]: string }
}
const SenderLabel = ({ data }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType
  return (
    <Box>
      {fileSumInfo.sender !== 'HTTP' && fileSumInfo.shipAppFlg !== '1' ? '*' : ''}
      {fileSumInfo.sender}
    </Box>
  )
}

export default SenderLabel
