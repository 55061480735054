import React, { useMemo } from 'react'

import { Grid } from '@mui/material'

type Props = {
  size?: 'sm' | 'lg' | 'xl'
  children?: React.ReactNode
}
const FormItmGrid = ({ size = 'sm', children }: Props) => {
  const itemSize = useMemo(() => {
    if (size === 'sm') return { lg: 12 }
    if (size === 'lg') return { lg: 6 }
    if (size === 'xl') return { lg: 3 }
    return { lg: 12 }
  }, [size])

  return (
    <Grid item {...itemSize}>
      {children}
    </Grid>
  )
}

export default FormItmGrid
