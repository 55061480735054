import React, { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom'

import { useAppDispatch } from 'src/js/slice/hooks'
import { footmark } from 'src/js/slice/App'

const Footmark = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(footmark(location.pathname))
  }, [location])

  return (
    <>
      <Outlet />
    </>
  )
}
export default Footmark
