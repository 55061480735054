import React, { useState } from 'react'
import { useAppDispatch } from 'src/js/slice/hooks'
import { AppBar, Toolbar, IconButton, Box, PopoverProps } from '@mui/material'
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  MoreHoriz as MoreIcon,
} from '@mui/icons-material'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

import { ConfirmDialog } from 'src/js/component/Base/Dialog'
import UserCard from './UserCard'
import MobileMenu from './MobileMenu'
import * as Style from './style'
import Breadcrumbs from './Breadcrumbs'
import { clearSession } from 'src/js/slice/App'

import { SYS_MSG } from 'src/resource'

type Props = {
  openFlg: boolean
  setOpenFlg: (openFlg: boolean) => void
}
const Navbar = ({ openFlg, setOpenFlg }: Props) => {
  const [cfmOpen, setCfmOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl'] | undefined>(undefined)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const mobileMenuId = 'primary-search-account-menu-mobile'

  const _signOut = () => {
    // ダイアログクローズ
    setCfmOpen(false)
    ;(async () => {
      // セッションクリア
      await dispatch(clearSession())

      // ログアウト
      await Auth.signOut()

      // ログイン画面へ遷移
      await navigate('/')
    })()
  }

  const _onOpenSignOutCfm = () => {
    setCfmOpen(true)
  }

  const _onCloseSignOutCfm = () => {
    setCfmOpen(false)
  }

  return (
    <div>
      <AppBar position='fixed' sx={[Style.appBar, openFlg ? Style.appBarShift : Style.appBarClps]}>
        <Toolbar variant='dense'>
          {/* 折り畳みボタン */}
          <IconButton
            edge='start'
            sx={{ marginRight: 1 }}
            color='inherit'
            aria-label='open drawer'
            onClick={() => {
              setOpenFlg(!openFlg)
            }}
          >
            <MenuIcon sx={[Style.menuIcon, openFlg ? Style.menuIconOpen : Style.menuIconClps]} />
          </IconButton>

          {/* パンくず */}
          <Breadcrumbs />

          {/* スペース */}
          <Box sx={{ flexGrow: 1 }} />

          {/* メニューボタン */}
          <Box sx={Style.menuBtnDesktop}>
            <UserCard />
            <IconButton color='inherit' onClick={_onOpenSignOutCfm}>
              <ExitToAppIcon />
            </IconButton>
          </Box>

          {/* メニューボタンモバイルバージョン */}
          <Box sx={Style.menuBtnMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={(event) => {
                setAnchorEl(event.currentTarget)
              }}
              color='inherit'
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* メニューボタンモバイルメニュー定義 */}
      <MobileMenu
        menuId={mobileMenuId}
        anchorEl={anchorEl}
        isMenuOpen={!!anchorEl}
        handleMenuClose={() => {
          setAnchorEl(undefined)
        }}
      />

      {/* 確認ダイアログ */}
      <ConfirmDialog
        isOpen={cfmOpen}
        text={SYS_MSG.INFO_SIGN_OUT}
        onOK={_signOut}
        onClose={_onCloseSignOutCfm}
      />
    </div>
  )
}

export default Navbar
