import React from 'react'

import { Box } from '@mui/material'
import { colors } from '@mui/material'

import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'
import { formatDateTimeByUSShort, toDateByYMD } from 'src/js/util/utility'

type Props = {
  data: { [key: string]: string }
}
const LastCheckedDateLabel = ({ data }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType

  const _getLimitDate = (): Date => {
    const now = new Date()
    const limitDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    limitDate.setDate(limitDate.getDate() - 13)
    return limitDate
  }

  if (!!!fileSumInfo.lastCheckedDate) return <></>

  const checkDate = toDateByYMD(fileSumInfo.lastCheckedDate)
  return (
    <Box sx={!!checkDate && checkDate < _getLimitDate() ? { color: colors.red[500] } : undefined}>
      {formatDateTimeByUSShort(fileSumInfo.lastCheckedDate)}
    </Box>
  )
}

export default LastCheckedDateLabel
