import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'

import { Paper, Toolbar, Typography, Box } from '@mui/material'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { StdCard } from 'src/js/component/Base/Card'
import { init, test1, gc } from 'src/js/slice/SYS0010'

import { SYS0010 as RESOURCE } from 'src/resource'

type TilteBarProps = {
  title: string
}
const TitleBar = ({ title }: TilteBarProps) => {
  return (
    <Toolbar sx={{ px: 2, justifyContent: 'flex-start' }} variant='dense'>
      <Typography
        sx={{ color: (theme) => theme.palette.primary.main }}
        variant='h6'
        component='div'
      >
        {title}
      </Typography>
    </Toolbar>
  )
}

// type Props = {
//   children: React.ReactNode
// }
const SYS0010 = () => {
  const isLoading = useAppSelector((state) => state.SYS0010.isLoading)
  const dispatch = useAppDispatch()

  // 初期化
  useEffect(() => {
    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // 処理：メニュー処理化
  const _init = () => {
    dispatch(init())
  }

  // 処理：テストモジュール１
  const _test1 = () => {
    dispatch(test1())
  }

  return (
    <Paper sx={{ width: 1, mb: 2 }}>
      <TitleBar title={RESOURCE.TITLE} />
      <Box sx={{ width: 1, pt: 1, pb: 4, px: 2 }}>
        <FormCtnGrid>
          {/* 初期化処理 */}
          <FormItmGrid size='xl'>
            <StdCard
              title={RESOURCE.F1_TITLE}
              subheader={RESOURCE.F1_SUB_TITLE}
              btnOkNm={RESOURCE.BTN_OK_NM}
              onOk={_init}
              isLoading={isLoading}
            >
              {RESOURCE.F1_DESC}
            </StdCard>
          </FormItmGrid>

          {/* テストモジュール１ */}
          <FormItmGrid size='xl'>
            <StdCard
              title={RESOURCE.T1_TITLE}
              subheader={RESOURCE.T1_SUB_TITLE}
              btnOkNm={RESOURCE.BTN_OK_NM}
              onOk={_test1}
              isLoading={isLoading}
            >
              {RESOURCE.T1_DESC}
            </StdCard>
          </FormItmGrid>
          <FormItmGrid size='xl'></FormItmGrid>
          <FormItmGrid size='xl'></FormItmGrid>
          <FormItmGrid size='xl'></FormItmGrid>
        </FormCtnGrid>
      </Box>
    </Paper>
  )
}
export default SYS0010
