import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import lo from 'lodash'

import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

import * as Api from './api'
import * as BizTestApi from './bizTestApi'

// 非同期処理定義
// 検索処理
export const init = createAsyncThunk(`${FUNC.SYS0010}/init`, async (_, thunkAPI) => {
  try {
    // ページ初期化
    await Api.initPage()

    // メニュー初期化
    await Api.initMenu()

    // メニューアイテム初期化
    await Api.initMenuItem()

    // ページ初期化
    await Api.initRole()

    // メニュー初期化
    await Api.initRoleAuth()

    // ユーザー初期化
    await Api.initUser()

    return
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

// テストモジュール１処理
export const test1 = createAsyncThunk(`${FUNC.SYS0010}/test1`, async (_, thunkAPI) => {
  try {
    // await BizTestApi.initMstVessel()
    // await BizTestApi.initFileSumInfo()
    // await BizTestApi.initCodeMaster()
    // await BizTestApi.initComment()
    await BizTestApi.initFileInfo()
    // await BizTestApi.testS3Action()
    // await BizTestApi.initEquipment()
    // await BizTestApi.initSetting()
    return
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

type State = {
  isLoading: boolean
}

// Slice定義
const getInitialState = (): State => ({
  isLoading: false,
})
export const Slice = createSlice({
  name: FUNC.SYS0010,
  initialState: getInitialState(),
  reducers: {
    gc: (state) => {
      state.isLoading = getInitialState().isLoading
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(init.pending, (state) => {
        state.isLoading = true
      })
      .addCase(init.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(init.rejected, (state) => {
        state.isLoading = getInitialState().isLoading
      })
      // テスト処理１
      .addCase(test1.pending, (state) => {
        state.isLoading = true
      })
      .addCase(test1.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(test1.rejected, (state) => {
        state.isLoading = getInitialState().isLoading
      })
  },
})

export const { gc } = Slice.actions

export default Slice.reducer
