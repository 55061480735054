/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      name
      order
      icon
      menuItem {
        items {
          id
          menuId
          name
          order
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      name
      order
      icon
      menuItem {
        items {
          id
          menuId
          name
          order
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      name
      order
      icon
      menuItem {
        items {
          id
          menuId
          name
          order
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem(
    $input: CreateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    createMenuItem(input: $input, condition: $condition) {
      id
      menuId
      name
      order
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem(
    $input: UpdateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    updateMenuItem(input: $input, condition: $condition) {
      id
      menuId
      name
      order
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItem = /* GraphQL */ `
  mutation DeleteMenuItem(
    $input: DeleteMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    deleteMenuItem(input: $input, condition: $condition) {
      id
      menuId
      name
      order
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      name
      funcId
      action
      order
      createdAt
      updatedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      name
      funcId
      action
      order
      createdAt
      updatedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      name
      funcId
      action
      order
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      comment
      roleAuth {
        items {
          id
          roleId
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          roleType
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      comment
      roleAuth {
        items {
          id
          roleId
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          roleType
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      comment
      roleAuth {
        items {
          id
          roleId
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          roleType
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRoleAuth = /* GraphQL */ `
  mutation CreateRoleAuth(
    $input: CreateRoleAuthInput!
    $condition: ModelRoleAuthConditionInput
  ) {
    createRoleAuth(input: $input, condition: $condition) {
      id
      roleId
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      roleType
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateRoleAuth = /* GraphQL */ `
  mutation UpdateRoleAuth(
    $input: UpdateRoleAuthInput!
    $condition: ModelRoleAuthConditionInput
  ) {
    updateRoleAuth(input: $input, condition: $condition) {
      id
      roleId
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      roleType
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoleAuth = /* GraphQL */ `
  mutation DeleteRoleAuth(
    $input: DeleteRoleAuthInput!
    $condition: ModelRoleAuthConditionInput
  ) {
    deleteRoleAuth(input: $input, condition: $condition) {
      id
      roleId
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      roleType
      order
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      shipManagerShort
      mailBox
      memo
      targetShipKbn
      liveCheckKbn
      roleId
      role {
        id
        name
        comment
        roleAuth {
          items {
            id
            roleId
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            roleType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      shipManagerShort
      mailBox
      memo
      targetShipKbn
      liveCheckKbn
      roleId
      role {
        id
        name
        comment
        roleAuth {
          items {
            id
            roleId
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            roleType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      shipManagerShort
      mailBox
      memo
      targetShipKbn
      liveCheckKbn
      roleId
      role {
        id
        name
        comment
        roleAuth {
          items {
            id
            roleId
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            roleType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMstVessel = /* GraphQL */ `
  mutation CreateMstVessel(
    $input: CreateMstVesselInput!
    $condition: ModelMstVesselConditionInput
  ) {
    createMstVessel(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      classification
      classificationSub
      shipManagerShort
      managementScheme
      aliveStatus
      eumsUnavailableFlag
      mailBox
      businessSection
      createdAt
      updatedAt
    }
  }
`;
export const updateMstVessel = /* GraphQL */ `
  mutation UpdateMstVessel(
    $input: UpdateMstVesselInput!
    $condition: ModelMstVesselConditionInput
  ) {
    updateMstVessel(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      classification
      classificationSub
      shipManagerShort
      managementScheme
      aliveStatus
      eumsUnavailableFlag
      mailBox
      businessSection
      createdAt
      updatedAt
    }
  }
`;
export const deleteMstVessel = /* GraphQL */ `
  mutation DeleteMstVessel(
    $input: DeleteMstVesselInput!
    $condition: ModelMstVesselConditionInput
  ) {
    deleteMstVessel(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      classification
      classificationSub
      shipManagerShort
      managementScheme
      aliveStatus
      eumsUnavailableFlag
      mailBox
      businessSection
      createdAt
      updatedAt
    }
  }
`;
export const createFileSumInfo = /* GraphQL */ `
  mutation CreateFileSumInfo(
    $input: CreateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    createFileSumInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      operationStatus
      lastCheckedDate
      uploadedDate
      sender
      shipAppFlg
      shipAppVersion
      numberOfRecord
      numberOfError
      confirmedFlag
      comments {
        items {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        nextToken
      }
      lastCommentId
      lastComment {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      lastCommentContent
      equipmentId
      equipment {
        id
        imoNo
        fmGoVer
        ipadEumsVer
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      lastVersion
      vesselId
      vessel {
        id
        imoNo
        vesselName
        classification
        classificationSub
        shipManagerShort
        managementScheme
        aliveStatus
        eumsUnavailableFlag
        mailBox
        businessSection
        createdAt
        updatedAt
      }
      aliveStatus
      businessSection
      shipManagerShort
      classification
      classificationSub
      eumsUnavailableFlag
      createdAt
      updatedAt
    }
  }
`;
export const updateFileSumInfo = /* GraphQL */ `
  mutation UpdateFileSumInfo(
    $input: UpdateFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    updateFileSumInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      operationStatus
      lastCheckedDate
      uploadedDate
      sender
      shipAppFlg
      shipAppVersion
      numberOfRecord
      numberOfError
      confirmedFlag
      comments {
        items {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        nextToken
      }
      lastCommentId
      lastComment {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      lastCommentContent
      equipmentId
      equipment {
        id
        imoNo
        fmGoVer
        ipadEumsVer
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      lastVersion
      vesselId
      vessel {
        id
        imoNo
        vesselName
        classification
        classificationSub
        shipManagerShort
        managementScheme
        aliveStatus
        eumsUnavailableFlag
        mailBox
        businessSection
        createdAt
        updatedAt
      }
      aliveStatus
      businessSection
      shipManagerShort
      classification
      classificationSub
      eumsUnavailableFlag
      createdAt
      updatedAt
    }
  }
`;
export const deleteFileSumInfo = /* GraphQL */ `
  mutation DeleteFileSumInfo(
    $input: DeleteFileSumInfoInput!
    $condition: ModelFileSumInfoConditionInput
  ) {
    deleteFileSumInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      operationStatus
      lastCheckedDate
      uploadedDate
      sender
      shipAppFlg
      shipAppVersion
      numberOfRecord
      numberOfError
      confirmedFlag
      comments {
        items {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        nextToken
      }
      lastCommentId
      lastComment {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      lastCommentContent
      equipmentId
      equipment {
        id
        imoNo
        fmGoVer
        ipadEumsVer
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      lastVersion
      vesselId
      vessel {
        id
        imoNo
        vesselName
        classification
        classificationSub
        shipManagerShort
        managementScheme
        aliveStatus
        eumsUnavailableFlag
        mailBox
        businessSection
        createdAt
        updatedAt
      }
      aliveStatus
      businessSection
      shipManagerShort
      classification
      classificationSub
      eumsUnavailableFlag
      createdAt
      updatedAt
    }
  }
`;
export const createFileInfo = /* GraphQL */ `
  mutation CreateFileInfo(
    $input: CreateFileInfoInput!
    $condition: ModelFileInfoConditionInput
  ) {
    createFileInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      fileName
      fileNameShort
      filePath
      fileType
      numberOfRecord
      startTime
      endTime
      fileDate
      createdAt
      updatedAt
      sender
      shipAppFlg
      shipAppVersion
      fileStatus
      operationStatus
      confirmedFlag
      numOfItems
      countItems
      snFlgUpdateDate
      reserve1
      reserve2
      reserve3
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumId
      fileSum {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          nextToken
        }
        lastCommentId
        lastComment {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        lastCommentContent
        equipmentId
        equipment {
          id
          imoNo
          fmGoVer
          ipadEumsVer
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        lastVersion
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        aliveStatus
        businessSection
        shipManagerShort
        classification
        classificationSub
        eumsUnavailableFlag
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFileInfo = /* GraphQL */ `
  mutation UpdateFileInfo(
    $input: UpdateFileInfoInput!
    $condition: ModelFileInfoConditionInput
  ) {
    updateFileInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      fileName
      fileNameShort
      filePath
      fileType
      numberOfRecord
      startTime
      endTime
      fileDate
      createdAt
      updatedAt
      sender
      shipAppFlg
      shipAppVersion
      fileStatus
      operationStatus
      confirmedFlag
      numOfItems
      countItems
      snFlgUpdateDate
      reserve1
      reserve2
      reserve3
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumId
      fileSum {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          nextToken
        }
        lastCommentId
        lastComment {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        lastCommentContent
        equipmentId
        equipment {
          id
          imoNo
          fmGoVer
          ipadEumsVer
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        lastVersion
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        aliveStatus
        businessSection
        shipManagerShort
        classification
        classificationSub
        eumsUnavailableFlag
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFileInfo = /* GraphQL */ `
  mutation DeleteFileInfo(
    $input: DeleteFileInfoInput!
    $condition: ModelFileInfoConditionInput
  ) {
    deleteFileInfo(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      fileName
      fileNameShort
      filePath
      fileType
      numberOfRecord
      startTime
      endTime
      fileDate
      createdAt
      updatedAt
      sender
      shipAppFlg
      shipAppVersion
      fileStatus
      operationStatus
      confirmedFlag
      numOfItems
      countItems
      snFlgUpdateDate
      reserve1
      reserve2
      reserve3
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumId
      fileSum {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          nextToken
        }
        lastCommentId
        lastComment {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        lastCommentContent
        equipmentId
        equipment {
          id
          imoNo
          fmGoVer
          ipadEumsVer
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        lastVersion
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        aliveStatus
        businessSection
        shipManagerShort
        classification
        classificationSub
        eumsUnavailableFlag
        createdAt
        updatedAt
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumInfo
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumInfo
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumInfo
      updatedAt
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment(
    $input: DeleteEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    deleteEquipment(input: $input, condition: $condition) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      imoNo
      systemVersion
      applicationVersion
      osVersion
      filePath
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      imoNo
      systemVersion
      applicationVersion
      osVersion
      filePath
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      imoNo
      systemVersion
      applicationVersion
      osVersion
      filePath
      createdAt
      updatedAt
    }
  }
`;
export const createSpasDaliy = /* GraphQL */ `
  mutation CreateSpasDaliy(
    $input: CreateSpasDaliyInput!
    $condition: ModelSpasDaliyConditionInput
  ) {
    createSpasDaliy(input: $input, condition: $condition) {
      id
      imoNo
      localDate
      reportNo
      createdAt
      updatedAt
    }
  }
`;
export const updateSpasDaliy = /* GraphQL */ `
  mutation UpdateSpasDaliy(
    $input: UpdateSpasDaliyInput!
    $condition: ModelSpasDaliyConditionInput
  ) {
    updateSpasDaliy(input: $input, condition: $condition) {
      id
      imoNo
      localDate
      reportNo
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpasDaliy = /* GraphQL */ `
  mutation DeleteSpasDaliy(
    $input: DeleteSpasDaliyInput!
    $condition: ModelSpasDaliyConditionInput
  ) {
    deleteSpasDaliy(input: $input, condition: $condition) {
      id
      imoNo
      localDate
      reportNo
      createdAt
      updatedAt
    }
  }
`;
export const createCodeMaster = /* GraphQL */ `
  mutation CreateCodeMaster(
    $input: CreateCodeMasterInput!
    $condition: ModelCodeMasterConditionInput
  ) {
    createCodeMaster(input: $input, condition: $condition) {
      id
      kbn
      code
      name
      order
      value1
      value2
      value3
      value4
      value5
      memo
      createdAt
      updatedAt
    }
  }
`;
export const updateCodeMaster = /* GraphQL */ `
  mutation UpdateCodeMaster(
    $input: UpdateCodeMasterInput!
    $condition: ModelCodeMasterConditionInput
  ) {
    updateCodeMaster(input: $input, condition: $condition) {
      id
      kbn
      code
      name
      order
      value1
      value2
      value3
      value4
      value5
      memo
      createdAt
      updatedAt
    }
  }
`;
export const deleteCodeMaster = /* GraphQL */ `
  mutation DeleteCodeMaster(
    $input: DeleteCodeMasterInput!
    $condition: ModelCodeMasterConditionInput
  ) {
    deleteCodeMaster(input: $input, condition: $condition) {
      id
      kbn
      code
      name
      order
      value1
      value2
      value3
      value4
      value5
      memo
      createdAt
      updatedAt
    }
  }
`;
export const createSNConfirm = /* GraphQL */ `
  mutation CreateSNConfirm(
    $input: CreateSNConfirmInput!
    $condition: ModelSNConfirmConditionInput
  ) {
    createSNConfirm(input: $input, condition: $condition) {
      version
      confirmFlg
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSNConfirm = /* GraphQL */ `
  mutation UpdateSNConfirm(
    $input: UpdateSNConfirmInput!
    $condition: ModelSNConfirmConditionInput
  ) {
    updateSNConfirm(input: $input, condition: $condition) {
      version
      confirmFlg
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSNConfirm = /* GraphQL */ `
  mutation DeleteSNConfirm(
    $input: DeleteSNConfirmInput!
    $condition: ModelSNConfirmConditionInput
  ) {
    deleteSNConfirm(input: $input, condition: $condition) {
      version
      confirmFlg
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
