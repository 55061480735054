import React from 'react'
import { useAppSelector } from 'src/js/slice/hooks'
import { Avatar, Card, CardHeader } from '@mui/material'
import { Person as PersonIcon } from '@mui/icons-material'

const UserCard = () => {
  // const [cfmOpen, setCfmOpen] = useState<boolean>(false)
  // TODO
  const appUser = useAppSelector((state) => state.app.user)
  // const user = {
  //   userNm: 'userNm',
  //   roleDesc: 'roleDesc',
  // }
  return (
    <Card sx={{ borderRadius: 0, boxShadow: 0 }}>
      <CardHeader
        sx={{ pt: 1, pb: 1 }}
        avatar={
          <Avatar
            aria-label={appUser?.userNm}
            sx={(theme) => ({ bgcolor: theme.palette.info.main })}
          >
            <PersonIcon />
          </Avatar>
        }
        title={appUser?.userNm}
        subheader={appUser?.roleDesc}
      />
    </Card>
  )
}

export default UserCard
