import lo from 'lodash'
import { API, Storage } from 'aws-amplify'

import * as GqlQueries from 'src/graphql/queries'
import * as GqlMutations from 'src/graphql/mutations'

import { MST_VESSEL } from './data/mstVessel'
import { FILE_SUM_INFO } from './data/fileSumInfo'
import { FILE_INFO } from './data/fileInfo'
import { CODE_MASTER } from './data/codeMaster'
import { COMMENT } from './data/comment'
import { EQUIPMENT } from './data/equipment'
import { SETTING } from './data/setting'
import * as GqlType from 'src/API'

// 船舶マスタ
export const initMstVessel = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listMstVessels })
  const vessels = lo.get(rsp, 'data.listMstVessels.items') as GqlType.MstVessel[]
  for (const vessel of vessels) {
    await API.graphql({
      query: GqlMutations.deleteMstVessel,
      variables: { input: { id: vessel.id } },
    })
  }

  // 新データ作成
  for (const vessel of MST_VESSEL) {
    await API.graphql({ query: GqlMutations.createMstVessel, variables: { input: vessel } })
  }
}

// File Sum Info
export const initFileSumInfo = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listFileSumInfos })
  const fileSumInfos = lo.get(rsp, 'data.listFileSumInfos.items') as GqlType.FileSumInfo[]
  for (const info of fileSumInfos) {
    await API.graphql({
      query: GqlMutations.deleteFileSumInfo,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of FILE_SUM_INFO) {
    await API.graphql({ query: GqlMutations.createFileSumInfo, variables: { input: info } })
  }
}

// File Info
export const initFileInfo = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listFileInfos })
  const fileInfos = lo.get(rsp, 'data.listFileInfos.items') as GqlType.FileInfo[]
  for (const info of fileInfos) {
    await API.graphql({
      query: GqlMutations.deleteFileInfo,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of FILE_INFO) {
    await API.graphql({ query: GqlMutations.createFileInfo, variables: { input: info } })
  }
}

// Vessel List
export const initCodeMaster = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listCodeMasters })
  const list = lo.get(rsp, 'data.listCodeMasters.items') as GqlType.CodeMaster[]
  for (const info of list) {
    await API.graphql({
      query: GqlMutations.deleteCodeMaster,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of CODE_MASTER) {
    await API.graphql({ query: GqlMutations.createCodeMaster, variables: { input: info } })
  }
}

// Comment
export const initComment = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listComments })
  const list = lo.get(rsp, 'data.listComments.items') as GqlType.Comment[]
  for (const info of list) {
    await API.graphql({
      query: GqlMutations.deleteComment,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of COMMENT) {
    await API.graphql({ query: GqlMutations.createComment, variables: { input: info } })
  }
}

export const testS3Action = async () => {
  const rsp2 = await Storage.get('test-doc.txt', { download: false })
  console.log(rsp2)
  return
}

// Equipment
export const initEquipment = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listEquipment })
  const list = lo.get(rsp, 'data.listEquipment.items') as GqlType.Equipment[]
  for (const info of list) {
    await API.graphql({
      query: GqlMutations.deleteEquipment,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of EQUIPMENT) {
    await API.graphql({ query: GqlMutations.createEquipment, variables: { input: info } })
  }
}

// Setting
export const initSetting = async () => {
  // 既存データ削除
  const rsp = await API.graphql({ query: GqlQueries.listSettings })
  const list = lo.get(rsp, 'data.listSettings.items') as GqlType.Setting[]
  for (const info of list) {
    await API.graphql({
      query: GqlMutations.deleteSetting,
      variables: { input: { id: info.id } },
    })
  }

  // 新データ作成
  for (const info of SETTING) {
    await API.graphql({ query: GqlMutations.createSetting, variables: { input: info } })
  }
}
