import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'
import * as GqlQueries from 'src/graphql/queries'
import { isMatch } from 'src/js/util/utility'
import { REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS_MSG } from 'src/resource'

import * as BaseType from 'src/js/type/base'
import * as GqlType from 'src/API'
import * as Type from './type'

export const search = async (pFilter: Type.Filter): Promise<GqlType.User[]> => {
  const rsp = await API.graphql({
    query: Queries.listUsers,
    variables: { filter: _makeFilterInput(pFilter) },
  })
  const users = lo.get(rsp, 'data.listUsers.items') as GqlType.User[]

  return !!users ? users : []
}

export const listRoleOptions = async (): Promise<BaseType.Option[]> => {
  // 全ロール取得
  const rsp = await API.graphql({
    query: Queries.listRoles,
  })
  const roles = lo.get(rsp, 'data.listRoles.items') as GqlType.Role[]
  if (!roles) return []

  // オプション作成
  const options = roles.map((role) => ({
    label: role.name,
    value: role.id,
  }))
  return lo.sortBy(options, ['label'])
}

export const findByKey = async (pId: string): Promise<GqlType.User> => {
  const rsp = await API.graphql({ query: GqlQueries.getUser, variables: { id: pId } })
  const user = lo.get(rsp, 'data.getUser') as GqlType.User

  return user
}

export const update = async (input: Type.Input): Promise<GqlType.User> => {
  await API.graphql({
    query: Mutations.updateUser,
    variables: {
      input: {
        id: input.id,
        name: input.name,
        shipManagerShort: input.shipManagerShort,
        mailBox: input.mailBox,
        memo: input.memo,
        targetShipKbn: input.targetShipKbn,
        liveCheckKbn: input.liveCheckKbn,
        roleId: input.roleId,
      },
    },
  })
  return await findByKey(input.id)
}

export const create = async (input: Type.Input): Promise<GqlType.User> => {
  await API.graphql({
    query: Mutations.createUser,
    variables: {
      input: {
        id: input.id,
        name: input.name,
        shipManagerShort: input.shipManagerShort,
        mailBox: input.mailBox,
        memo: input.memo,
        targetShipKbn: input.targetShipKbn,
        liveCheckKbn: input.liveCheckKbn,
        roleId: input.roleId,
      },
    },
  })
  return await findByKey(input.id)
}

export const deleteById = async (pId: string): Promise<void> => {
  await API.graphql({
    query: Mutations.deleteUser,
    variables: { input: { id: pId } },
  })
  return
}

export const validation = async (pInput: Type.Input): Promise<string[]> => {
  const errMsg = [] as string[]

  // Id
  if (!pInput.id) errMsg.push(`User ID:${SYS_MSG.ERRSS001}`)
  if (!!pInput.id && !isMatch(pInput.id, REGEX.HALF_N_E)) errMsg.push(`id:${SYS_MSG.ERRSS005}`)

  // Name
  if (!pInput.name) errMsg.push(`User Name:${SYS_MSG.ERRSS001}`)

  // ロールID
  if (!pInput.roleId) errMsg.push(`Role Id:${SYS_MSG.ERRSS001}`)
  if (!!pInput.roleId && !isMatch(pInput.roleId, REGEX.HALF_N_E))
    errMsg.push(`Role Id:${SYS_MSG.ERRSS005}`)

  // Ship Manager
  if (!pInput.shipManagerShort) errMsg.push(`Ship Manager:${SYS_MSG.ERRSS001}`)

  // Target Ship Kbn
  if (!pInput.targetShipKbn) errMsg.push(`Target Ship Kbn:${SYS_MSG.ERRSS001}`)

  // SN Comfirmed Kbn
  if (!pInput.liveCheckKbn) errMsg.push(`SN Comfirmed Kbn:${SYS_MSG.ERRSS001}`)

  return errMsg
}

const _makeFilterInput = (pFilter: Type.Filter): GqlType.ModelUserFilterInput => {
  const filter: GqlType.ModelUserFilterInput = {}

  if (!!pFilter.roleId) {
    filter.roleId = { contains: pFilter.roleId }
  }

  return filter
}
