import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Footmark from 'src/js/component/Base/App/Footmark'
import Authentication from 'src/js/component/Base/App/Authentication'
import Frame from 'src/js/component/Base/Frame'

// フレームワーク機能
import SYS0010 from 'src/js/component/SYS0010'
import SYS0020 from 'src/js/component/SYS0020'
import SYS0030 from 'src/js/component/SYS0030'
import SYS0040 from 'src/js/component/SYS0040'
import SYS0041 from 'src/js/component/SYS0041'

// 業務
import B0010 from 'src/js/component/B0010'
import B0011 from 'src/js/component/B0011'
import B0012 from 'src/js/component/B0012'
import M0010 from 'src/js/component/M0010'

import { COMP_KBN } from 'src/js/util/constants'
import { Redirect as RedirectType } from 'src/js/type/base'

// 画面遷移用コンフィグ情報
export const redirect: RedirectType[] = [
  // フレームワーク用
  { path: `/SYSERR`, compId: 'SYSERR', compKbn: COMP_KBN.MAIN, label: 'SYSTEM ERROR' },
  { path: `/SYS0010`, compId: 'SYS0010', compKbn: COMP_KBN.MAIN, label: 'SETTING' },
  { path: `/SYS0020`, compId: 'SYS0020', compKbn: COMP_KBN.MAIN, label: 'ROLE' },
  { path: `/SYS0030`, compId: 'SYS0030', compKbn: COMP_KBN.MAIN, label: 'USER' },
  { path: `/SYS0040`, compId: 'SYS0040', compKbn: COMP_KBN.MAIN, label: 'AUTHORITY' },
  { path: `/SYS0041`, compId: 'SYS0041', compKbn: COMP_KBN.SUB, label: 'AUTHORITY DRTAIL' },

  // 業務用
  { path: `/test1`, compId: 'TEST1', compKbn: COMP_KBN.MAIN, label: 'TEST1' },
  { path: `/test2`, compId: 'TEST2', compKbn: COMP_KBN.MAIN, label: 'TEST2' },
  { path: `/MM0010`, compId: 'MM0010', compKbn: COMP_KBN.MAIN, label: 'MM0010' },

  { path: `/`, compId: 'B0010', compKbn: COMP_KBN.MAIN, label: 'VESSEL LIST' },
  { path: `/B0010`, compId: 'B0010', compKbn: COMP_KBN.MAIN, label: 'VESSEL LIST' },
  { path: `/B0011`, compId: 'B0011', compKbn: COMP_KBN.SUB, label: 'DATA LIST' },
  { path: `/B0012`, compId: 'B0012', compKbn: COMP_KBN.SUB, label: 'COMMENT' },
  { path: `/M0010`, compId: 'M0010', compKbn: COMP_KBN.MAIN, label: 'VESSEL MASTER LIST' },
]

/**
 * @memberof Controller
 * @classdesc ルーターコントローラー
 * @class RouterController
 */
// interface Props {
//   history: BrowserHistory
// }
const RouterController = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* 通常 */}
          <Route element={<Authentication />}>
            <Route element={<Footmark />}>
              <Route element={<Frame />}>
                {/* フレームワーク用 */}
                <Route path='/SYS0010' element={<SYS0010 />} />
                <Route path='/SYS0020' element={<SYS0020 />} />
                <Route path='/SYS0030' element={<SYS0030 />} />
                <Route path='/SYS0040' element={<SYS0040 />} />
                <Route path='/SYS0041' element={<SYS0041 />} />

                {/* 業務 */}
                <Route path='/' element={<B0010 />} />
                <Route path='/B0010' element={<B0010 />} />
                <Route path='/B0011' element={<B0011 />} />
                <Route path='/B0012' element={<B0012 />} />
                <Route path='/M0010' element={<M0010 />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default RouterController
