import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { setFilterCount } from 'src/js/slice/App'
import { query, mutation } from 'src/js/util/graphql'
import * as Api from './api'

import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { countFilter } from 'src/js/util/utility'
import * as GqlType from 'src/API'
import * as Type from './type'

// 非同期処理定義
// ヘッダー取得
export const header = createAsyncThunk<GqlType.MstVessel, string, { state: RootState }>(
  `${FUNC.B0012}/header`,
  async (imoNo, thunkAPI) => {
    try {
      return await query<string, GqlType.MstVessel>(
        thunkAPI.dispatch,
        imoNo,
        async (dispatch, imoNo) => {
          return await Api.findMstVesselByKey(imoNo)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 検索処理
export const search = createAsyncThunk<Type.Comment[], string, { state: RootState }>(
  `${FUNC.B0012}/search`,
  async (imoNo, thunkAPI) => {
    try {
      return await query<Type.Filter, Type.Comment[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().B0012.filter,
        async (dispatch, filter) => {
          // フィルターカウンター
          dispatch(setFilterCount(countFilter(filter)))

          // 検索処理
          return await Api.search(imoNo, filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 明細表示
export const input = createAsyncThunk<Type.Comment, string, { state: RootState }>(
  `${FUNC.B0012}/input`,
  async (id, thunkAPI) => {
    try {
      return await query<string, Type.Comment>(thunkAPI.dispatch, id, async (dispath, id) => {
        return await Api.findCommentById(id)
      })
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 更新処理
export const update = createAsyncThunk<void, string, { state: RootState }>(
  `${FUNC.B0012}/update`,
  async (imoNo, thunkAPI) => {
    try {
      return await mutation<{ userId?: string; input: Type.Input }, void>(
        thunkAPI.dispatch,
        { userId: thunkAPI.getState().app.user?.userId, input: thunkAPI.getState().B0012.input },
        async (dispatch, param) => {
          // 有効性チェック
          const errMsg = await Api.validation(imoNo, param.input)
          if (!lo.isEmpty(errMsg)) {
            throw errMsg
          }

          // コメント新規／更新処理
          param.input.mode == 'new'
            ? await Api.create(imoNo, param.userId ? param.userId : '', param.input)
            : await Api.update(param.input)

          // File Sum Info更新処理
          await _updateFileSumInfo(imoNo)
          return
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

const _updateFileSumInfo = async (imoNo: string): Promise<null> => {
  const comment = await Api.getLastComment(imoNo)
  if (comment)
    await Api.updateFileSumInfo(imoNo, comment.id, comment.comment ? comment.comment : '')

  return null
}

// 削除処理
export const deleteById = createAsyncThunk<void, string, { state: RootState }>(
  `${FUNC.B0012}/deleteById`,
  async (imoNo, thunkAPI) => {
    try {
      return await mutation<{ id: string; imoNo: string }, void>(
        thunkAPI.dispatch,
        { id: thunkAPI.getState().B0012.input.id, imoNo: imoNo },
        async (dispatch, param) => {
          await Api.deleteById(param.id)
          await _updateFileSumInfo(param.imoNo)
          return
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// フィルター初期化
const _getInitFilter = (): Type.Filter => {
  // 初期設定
  const filter = getInitialState().filter

  // const range = getFilterDateRange()
  // filter.createDateF = range.start
  // filter.createDateT = range.end

  return filter
}

// Slice定義
const getInitialState = (): Type.State => ({
  // ヘッダー
  header: {
    isLoading: false,
    imoNo: '',
    vesselName: '',
    shipManagerShort: '',
  },

  // 検索条件
  filter: {
    imoNo: '',
    createDateF: '',
    createDateT: '',
    author: '',
  },

  // 明細
  input: {
    isLoading: false,
    mode: 'new',
    id: '',
    userId: '',
    comment: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
  },
})
export const Slice = createSlice({
  name: FUNC.B0012,
  initialState: getInitialState(),
  reducers: {
    initFilter: (state) => {
      state.filter = _getInitFilter()
    },
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    setInput: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.input, id, value)
    },
    initInput: (state) => {
      state.input = getInitialState().input
      state.input.mode = 'new'
    },
    clearFilter: (state) => {
      state.filter = _getInitFilter()
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.header = initState.header
      state.filter = initState.filter
      state.result = initState.result
    },
  },
  extraReducers: (builder) => {
    builder
      // ヘッダー
      .addCase(header.pending, (state) => {
        state.header.isLoading = true
      })
      .addCase(header.fulfilled, (state, action) => {
        state.header.isLoading = false
        const mstVessel = action.payload
        state.header.imoNo = mstVessel.imoNo
        state.header.vesselName = mstVessel.vesselName ? mstVessel.vesselName : ''
        state.header.shipManagerShort = mstVessel.shipManagerShort ? mstVessel.shipManagerShort : ''
      })
      .addCase(header.rejected, (state) => {
        state.header = getInitialState().header
      })

      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // 入力画面初期化
      .addCase(input.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(input.fulfilled, (state, action) => {
        const comment = action.payload
        state.input = {
          isLoading: false,
          mode: 'delete',
          id: comment.id,
          userId: !!comment.userId ? comment.userId : '',
          comment: !!comment.comment ? comment.comment : '',
        }
      })
      .addCase(input.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 入力更新
      .addCase(update.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(update.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.input = getInitialState().input
      })

      // 削除
      .addCase(deleteById.pending, (state) => {
        state.input.isLoading = true
      })
      .addCase(deleteById.fulfilled, (state) => {
        state.input.isLoading = false
      })
      .addCase(deleteById.rejected, (state) => {
        state.input = getInitialState().input
      })
  },
})

export const { initFilter, initInput, setFilter, setInput, clearFilter, cancelFilter, gc } =
  Slice.actions

export default Slice.reducer
