import * as GqlType from 'src/API'

export const FILE_INFO = [
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180353.csv',
  //   filePath: 'M0check_9085637_201712180353.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180353',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-07T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712162013.csv',
  //   filePath: 'M0check_9085637_201712162013.csv',
  //   fileType: 'data',
  //   version: '9085637_201807121103',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-06T08:10:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201811052330.csv',
  //   filePath: 'M0check_9085637_201811052330.csv',
  //   fileType: 'data',
  //   version: '9085637_201811052330',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-05T09:47:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180354.csv',
  //   filePath: 'M0check_9085637_201712180354.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180354',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-04T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180355.csv',
  //   filePath: 'M0check_9085637_201712180355.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180355',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-03T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180356.csv',
  //   filePath: 'M0check_9085637_201712180356.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180356',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-02T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180357.csv',
  //   filePath: 'M0check_9085637_201712180357.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180357',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-12-01T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180358.csv',
  //   filePath: 'M0check_9085637_201712180358.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180358',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-30T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180359.csv',
  //   filePath: 'M0check_9085637_201712180359.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180359',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-29T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180360.csv',
  //   filePath: 'M0check_9085637_201712180360.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180360',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-28T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180361.csv',
  //   filePath: 'M0check_9085637_201712180361.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180361',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-27T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180362.csv',
  //   filePath: 'M0check_9085637_201712180362.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180362',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-26T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180363.csv',
  //   filePath: 'M0check_9085637_201712180363.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180363',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-25T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180364.csv',
  //   filePath: 'M0check_9085637_201712180364.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180364',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-24T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
  // {
  //   imoNo: '9085637',
  //   vesselName: 'DOHA',
  //   fileName: 'M0check_9085637_201712180365.csv',
  //   filePath: 'M0check_9085637_201712180365.csv',
  //   fileType: 'data',
  //   version: '9085637_201712180365',
  //   numberOfRecord: 1375,
  //   startTime: 1666574130,
  //   endTime: 1666574130,
  //   fileDate: '2018-11-05T00:00:00Z',
  //   createdAt: '2022-11-23T08:09:00Z',
  //   sender: '27.109.115.156',
  //   operationStatus: '0',
  //   confirmedFlag: '1',
  //   numOfItems: 0,
  //   countItems: 0,
  //   snFlgUpdateDate: '2018-11-05T00:00:00Z',
  //   reserve1: '',
  //   reserve2: '',
  //   reserve3: '',
  //   fileSumId: '9085637',
  //   fileStatus: '1',
  // },
] as GqlType.FileInfo[]
