import React, { useState } from 'react'
import { Action } from '@reduxjs/toolkit'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import lo from 'lodash'

import { SYS_MSG } from 'src/resource'
import * as Type from 'src/js/type/base'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { DateValidationError } from '@mui/x-date-pickers/internals'

type Props = {
  id: string
  label: string
  refer: Type.Reference // 参照項目
  onChange: (item: { id: string; value: Date | null }) => Action // イベント：値変更
  required?: boolean
  helperText?: string
  disabled?: boolean
  disableFuture?: boolean
  disablePast?: boolean
  disableHighlightToday?: boolean
  onError?: (id: string) => void // イベント：エラー発生
}
const StandardDateRef = ({
  id,
  label,
  refer,
  onChange,
  required = false,
  helperText = ' ',
  disabled = false,
  disableFuture = false,
  disablePast = false,
  disableHighlightToday = false,
  onError,
}: Props) => {
  const [state, setState] = useState<{ isError: boolean; msg: string }>({
    isError: false,
    msg: '',
  })
  const value = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as string
  const dispatch = useAppDispatch()

  const _onChange = (date: Date | null) => {
    //必須入力エラーの場合
    if (required && !date) {
      setState({ isError: true, msg: SYS_MSG.ERRSS001 })
      onError && onError(id)
      dispatch(onChange({ id: id, value: date }))
      return
    }

    //通常
    setState({ isError: false, msg: '' })
    dispatch(onChange({ id: id, value: date }))
  }

  const _onError = (reason: DateValidationError) => {
    if (reason) {
      if (reason == 'invalidDate' && value === '') {
        _onChange(null)
        return
      }
      setState({ isError: true, msg: SYS_MSG.ERRSS009 })
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disableHighlightToday={disableHighlightToday}
          value={value}
          inputFormat='yyyy/MM/dd'
          onChange={_onChange}
          onError={_onError}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: 1 }}
              size='small'
              error={state.isError}
              helperText={state.isError ? state.msg : helperText}
              inputProps={{ ...params.inputProps, readOnly: true }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default StandardDateRef
