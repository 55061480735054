import { Theme } from '@mui/material/styles'

export const toobarBtn = (theme: Theme) =>
  ({
    color: theme.palette.secondary.main,
  } as const)

const drawerWidth = {
  lg: '30%',
  md: '50%',
  sm: '70%',
  xs: '100%',
}
export const drawer = {
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
} as const

export const drawerOpen = {
  width: drawerWidth,
} as const

export const drawerClose = {
  overflowX: 'hidden',
} as const
