import { Theme } from '@mui/material/styles'

export const clpIcon = (theme: Theme) =>
  ({
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 300,
    }),
  } as const)

export const clpOn = { transform: 'rotate(0)' } as const

export const clpOff = { transform: 'rotate(-180deg)' } as const
