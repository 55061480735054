/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listFileSumInfos = /* GraphQL */ `
  query ListFileSumInfos(
    $id: ID
    $filter: ModelFileSumInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileSumInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            comment
            createdAt
            userId
          }
        }

        lastCommentId
        lastComment {
          comment
          createdAt
          userId
        }
        lastCommentContent

        equipmentId
        equipment {
          fmGoVer
          ipadEumsVer
        }
        settingId
        setting {
          systemVersion
        }
        version
        snConfirm {
          confirmFlg
        }
        vesselId
        vessel {
          id
          imoNo
          # vesselName
          classification
          # classificationSub
          businessSection
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
        }
        shipManagerShort
        eumsUnavailableFlag
        aliveStatus
      }
      nextToken
    }
  }
`
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
      }
    }
  }
`
