import React from 'react'

import { setFilter } from 'src/js/slice/SYS0020'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { SYS0020 as RESOURCE } from 'src/resource'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* 名称 */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='name'
          label={RESOURCE.LBL_NAME}
          size='small'
          refer={{ funcId: 'SYS0020', partId: 'filter', itemId: 'name' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* コメント */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='comment'
          label={RESOURCE.LBL_COMMENT}
          size='small'
          refer={{ funcId: 'SYS0020', partId: 'filter', itemId: 'comment' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
