import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'src/js/slice/hooks'

import { RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType } from 'src/js/component/Base/Table'
import Filter from './Filter'

import RemovedCheckBox from './RemovedCheckBox'
import { search, gc, cancelFilter, clearFilter } from 'src/js/slice/M0010'
import { isReadOnly, getFuncAuth } from 'src/js/util/utility'
import { AuthKbn as AuthKbnType } from 'src/js/type/base'

import { M0010 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'

const M0010 = () => {
  const appAuth = useAppSelector((state) => state.app.auth)
  const funcAuth = useMemo(() => getFuncAuth(FUNC.M0010, appAuth), [appAuth]) as AuthKbnType

  const dispatch = useAppDispatch()

  // 初期化
  useEffect(() => {
    ;(async () => {
      // 検索処理
      await dispatch(search())
    })()

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // カラム定義
  const columns = [
    {
      key: 'imoNo',
      label: RESOURCE.LBL_IMO_NO,
      align: 'left',
    },
    { key: 'vesselName', label: RESOURCE.LBL_VESSEL_NAME, align: 'left' },
    { key: 'classification', label: RESOURCE.LBL_TYPE, align: 'left' },
    { key: 'classificationSub', label: RESOURCE.LBL_TYPE_SUB, align: 'left' },
    { key: 'shipManagerShort', label: RESOURCE.LBL_SHIP_MANAGER, align: 'left' },
    { key: 'businessSection', label: RESOURCE.LBL_BUSINESS_SECTION, align: 'left' },
    { key: 'managementScheme', label: RESOURCE.LBL_MANAGERMENT_SCHEME, align: 'left' },
    { key: 'aliveStatus', label: RESOURCE.LBL_ALIVE_STATUS, align: 'left' },
    {
      key: 'eumsUnavailableFlag',
      label: RESOURCE.LBL_EUMS_REMOVED,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => (
        <RemovedCheckBox data={row} disabled={!!isReadOnly(funcAuth)} />
      ),
    },
    { key: 'mailBox', label: RESOURCE.LBL_E_MAIL, align: 'left' },
  ] as TableColumnType[]

  const csvInfo = {
    fileName: 'vesselMaster.csv',
    keys: [
      'imoNo',
      'vesselName',
      'classification',
      'classificationSub',
      'shipManagerShort',
      'businessSection',
      'managementScheme',
      'aliveStatus',
      'eumsUnavailableFlag',
      'mailBox',
    ],
    title: [
      RESOURCE.LBL_IMO_NO,
      RESOURCE.LBL_VESSEL_NAME,
      RESOURCE.LBL_TYPE,
      RESOURCE.LBL_TYPE_SUB,
      RESOURCE.LBL_SHIP_MANAGER,
      RESOURCE.LBL_BUSINESS_SECTION,
      RESOURCE.LBL_MANAGERMENT_SCHEME,
      RESOURCE.LBL_ALIVE_STATUS,
      RESOURCE.LBL_EUMS_REMOVED,
      RESOURCE.LBL_E_MAIL,
    ],
  }

  return (
    <>
      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={RESOURCE.TITLE}
        Filter={<Filter />}
        columns={columns}
        refer={{ funcId: FUNC.M0010, itemId: 'result' }}
        onSearch={() => {
          dispatch(search())
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(clearFilter())
        }}
        filterKey={{ funcId: FUNC.M0010, filterId: 'filter' }}
        displayMode='page'
        tblStickyHeader={true}
        exportFlg={true}
        csv={csvInfo}
      />
    </>
  )
}
export default M0010
