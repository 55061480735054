import lo from 'lodash'
import { API } from 'aws-amplify'

import { Apps as AppsIcon } from '@mui/icons-material'
import { DEFAULT_MENU_CONFIG, MenuIcons } from 'src/config/menu'
import * as Queries from './graphql/queries'
import * as GqlQueries from 'src/graphql/queries'

import * as GqlType from 'src/API'
import * as Type from 'src/js/type/base'

// 権限タイプ：利用不可
const _AUTH_TYPE_NG = 'NG'

// メニュー初期化
export const init = async (pUserId: string): Promise<Type.Menu[]> => {
  if (!pUserId) return DEFAULT_MENU_CONFIG

  let rsp
  // ロールID取得
  rsp = await API.graphql({ query: GqlQueries.getUser, variables: { id: pUserId } })
  const user = lo.get(rsp, 'data.getUser') as GqlType.User
  if (!user?.roleId) return DEFAULT_MENU_CONFIG

  // メインメニューデータ取得
  rsp = await API.graphql({ query: Queries.listMenus })
  const orgiMenus = lo.get(rsp, 'data.listMenus.items') as GqlType.Menu[]
  if (!orgiMenus || lo.isEmpty(orgiMenus)) return DEFAULT_MENU_CONFIG

  // ロール取得
  rsp = await API.graphql({ query: GqlQueries.getRole, variables: { id: user.roleId } })
  const role = lo.get(rsp, 'data.getRole') as GqlType.Role
  if (!role) return DEFAULT_MENU_CONFIG

  // システムメニュー作成
  return _buildMenus(orgiMenus, role)
}

// システムメニュー作成
const _buildMenus = (pGqlMenus: GqlType.Menu[], pRole: GqlType.Role): Type.Menu[] => {
  const menus = lo.cloneDeep(pGqlMenus) as Type.Menu[]

  menus.forEach((menu) => {
    // アイコン設定
    menu.Icon = lo.find(MenuIcons, { 'id': menu.icon })?.Icon || AppsIcon

    // ロールタイプ設定
    menu.menuItem.items.forEach((menuItem) => {
      menuItem.roleType =
        lo.find(pRole.roleAuth?.items, { pageId: menuItem.pageId })?.roleType || _AUTH_TYPE_NG
    })

    menu.menuItem.items = lo.sortBy(menu.menuItem.items, ['order'])
  })

  // 空メニュー削除
  lo.remove(menus, (menu) => {
    return lo.isEmpty(menu.menuItem.items)
  })

  // 権限により、不要のメニュー削除
  menus.forEach((menu) => {
    lo.remove(menu.menuItem.items, (item) => {
      return item.roleType === _AUTH_TYPE_NG
    })
  })

  return menus
}
