import React from 'react'
import { useAppDispatch } from 'src/js/slice/hooks'

import { StdCheckBoxRef } from 'src/js/component/Base/CheckBox'
import { FUNCTION_ID as FUNC, FILE_TYPE, FILE_STATUS } from 'src/js/util/constants'
import { formatDateByJPShort } from 'src/js/util/utility'
import { updateSNConfirmFlag, search } from 'src/js/slice/B0011'

import { FileInfo as FileInfoType } from 'src/js/slice/B0011/type'
import { LIVE_CHECK_KBN } from 'src/js/util/constants'

type Props = {
  data: { [key: string]: string }
  index: number
  userLiveCheckKbn: string | undefined
}
const SNConfirmedLabel = ({ data, index, userLiveCheckKbn }: Props) => {
  const dispatch = useAppDispatch()
  const fileInfo = data as unknown as FileInfoType

  const _onChange = (param: { id: string; value: boolean }) => {
    ;(async () => {
      await dispatch(updateSNConfirmFlag(param))
      await dispatch(search(fileInfo.imoNo))
    })()
  }
  if (fileInfo.fileType === FILE_TYPE.MASTER && fileInfo.fileStatus === FILE_STATUS.COMPLETED) {
    return (
      <StdCheckBoxRef
        // sx={{ mb: '0rem' }}
        id={`confirmedFlag_${index}`}
        label={
          !!fileInfo.snConfirm && fileInfo.snConfirm.confirmFlg === '1' && !!fileInfo.snConfirm.user
            ? `${fileInfo.snConfirm.user.name}-${formatDateByJPShort(fileInfo.snConfirm.updatedAt)}`
            : ''
        }
        refer={{
          funcId: FUNC.B0011,
          partId: `result.data[${index}]`,
          itemId: 'snConfirm.confirmFlg',
        }}
        disabled={userLiveCheckKbn === LIVE_CHECK_KBN.RO}
        dispatchFlg={false}
        onChange={_onChange}
        size='small'
        checkBoxSize='small'
        labelSize='0.875rem'
      />
    )
  }
  return <></>
}

export default SNConfirmedLabel
