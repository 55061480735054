import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/js/slice/hooks'
import lo from 'lodash'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Drawer, Toolbar, Typography, Box, Button } from '@mui/material'
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material'

import { saveFilterSession } from 'src/js/slice/App'
import { OkIconButton, CancelIconButton, ClearIconButton } from 'src/js/component/Base/Button'
import * as Style from './style'
import { SYS_CMN } from 'src/resource'

type TitleProps = {
  onOk: () => void
  onClose: () => void
  onCancel: () => void
  onClear: () => void
}
const TitleBar = ({ onOk, onClose, onCancel, onClear }: TitleProps) => {
  return (
    <Toolbar sx={{ px: 3, justifyContent: 'flex-start' }} variant='dense'>
      <Typography
        sx={{ flexGrow: 1, color: (theme) => theme.palette.primary.main }}
        variant='h6'
        component='div'
      >
        {SYS_CMN.LBL_PANEL_FILTER}
      </Typography>

      <Box>
        <OkIconButton
          onClick={() => {
            onOk()
            onClose()
          }}
        />
        <ClearIconButton onClick={onClear} />
        <CancelIconButton onClick={onCancel} />
      </Box>
    </Toolbar>
  )
}

type ContentsProps = {
  children: React.ReactNode
  onClose: () => void
  onOk: () => void
  onCancel: () => void
  onClear: () => void
}
const DrawerContents = ({ children, onClose, onCancel, onOk, onClear }: ContentsProps) => {
  return (
    <>
      {/* タイトルバー */}
      <TitleBar onOk={onOk} onClose={onClose} onCancel={onCancel} onClear={onClear} />

      {/* フィルター */}
      <Box sx={{ pt: 1, px: 3 }}>{children}</Box>

      {/* フッター */}
      <Box sx={{ pl: 2, pr: 1, justifyContent: 'flex-start' }}>
        <Button
          sx={{ m: 1 }}
          variant='contained'
          color='primary'
          startIcon={<DoneIcon />}
          onClick={() => {
            onOk()
            onClose()
          }}
        >
          {SYS_CMN.BTN_OK}
        </Button>

        <Button
          sx={{ m: 1 }}
          variant='outlined'
          color='secondary'
          startIcon={<CloseIcon />}
          onClick={onCancel}
        >
          {SYS_CMN.BTN_CANCEL}
        </Button>

        {/* {onClear && (
          <Button
            sx={{ m: 1 }}
            variant='outlined'
            color='secondary'
            startIcon={<CloseIcon />}
            onClick={onCancel}
          >
            {SYS_CMN.BTN_CANCEL}
          </Button>
        )} */}
      </Box>
    </>
  )
}

type Props = {
  openFlg: boolean
  setOpenFlg: (flg: boolean) => void
  children: React.ReactNode
  onOk: () => void
  onCancel: (filterPrev: object) => void
  onClear: () => void
  filterKey: { funcId: string; filterId: string }
}
const FilterDrawerRef = ({
  openFlg,
  setOpenFlg,
  children,
  onOk,
  onCancel,
  onClear,
  filterKey,
}: Props) => {
  const dispatch = useAppDispatch()
  const filterPrev = useAppSelector((state) =>
    lo.get(state, `app.filterSession.${filterKey.funcId}`)
  ) as object

  useEffect(() => {
    if (openFlg) {
      // フィルター保存
      dispatch(saveFilterSession(filterKey))
    }
  }, [openFlg])

  return (
    <Drawer
      variant='persistent'
      sx={[Style.drawer, openFlg ? Style.drawerOpen : Style.drawerClose]}
      anchor='right'
      transitionDuration={{ enter: 500 }}
      open={openFlg}
      onClose={() => {
        setOpenFlg(false)
      }}
    >
      <PerfectScrollbar>
        <DrawerContents
          onClose={() => {
            setOpenFlg(false)
          }}
          onOk={onOk}
          onCancel={() => {
            onCancel(filterPrev)
            setOpenFlg(false)
          }}
          onClear={onClear}
        >
          {children}
        </DrawerContents>
      </PerfectScrollbar>
    </Drawer>
  )
}

export default FilterDrawerRef
