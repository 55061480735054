import React from 'react'

import { setInput, setPageOption } from 'src/js/slice/SYS0041'
import { FormDialog } from 'src/js/component/Base/Dialog'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { RegExpTextFieldRef } from 'src/js/component/Base/TextField'
import { StdAutocompleteRef } from 'src/js/component/Base/Autocomplete'
import { StdSelectRef } from 'src/js/component/Base/Select'
import { FUNCTION_ID as FUNC, REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS0040 as RESOURCE, SYS_MSG } from 'src/resource'

import * as Type from 'src/js/type/base'

type Props = {
  open: boolean
  mode: Type.DialogStatus
  onOK: () => void
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}
const Input = ({ open, mode, onOK, onClose, onDelete, isLoading = true }: Props) => {
  // ロールタイプオプション
  const _ROLE_TYPE = [
    { value: 'RO', label: '閲覧権限' },
    { value: 'RW', label: '編集権限' },
    { value: 'NG', label: '閲覧不可' },
  ] as Type.Option[]

  return (
    <FormDialog
      title={RESOURCE.TITLE_INPUT}
      mode={mode}
      isLoading={isLoading}
      open={open}
      onCancel={onClose}
      onOK={onOK}
      onDelete={onDelete}
    >
      <FormCtnGrid>
        {/* ID */}
        <FormItmGrid>
          <RegExpTextFieldRef
            id='id'
            label={RESOURCE.LBL_ID}
            size='small'
            regExp={REGEX.HALF_N_E}
            errMsg={SYS_MSG.ERRSS005}
            refer={{ funcId: FUNC.SYS0041, partId: 'input', itemId: 'id' }}
            onChange={setInput}
            disabled={mode === 'edit'}
            required
          />
        </FormItmGrid>

        {/* ロール */}
        <FormItmGrid>
          <StdAutocompleteRef
            id='pageId'
            label={RESOURCE.LBL_PAGE_ID}
            refer={{ funcId: FUNC.SYS0041, partId: 'input', itemId: 'pageId' }}
            referOpts={{ funcId: FUNC.SYS0041, itemId: 'pageOpts' }}
            onChange={setPageOption}
            required
          />
        </FormItmGrid>

        {/* 権限 */}
        <FormItmGrid>
          <StdSelectRef
            id={'roleType'}
            label={RESOURCE.LBL_ROLE_TYPE}
            options={_ROLE_TYPE}
            hasNoneOpt={false}
            required
            refer={{ funcId: FUNC.SYS0041, partId: 'input', itemId: 'roleType' }}
            onChange={setInput}
          />
        </FormItmGrid>

        {/* オーダー */}
        <FormItmGrid>
          <RegExpTextFieldRef
            id='order'
            label={RESOURCE.LBL_ORDER}
            size='small'
            required
            regExp={REGEX.HALF_N}
            errMsg={SYS_MSG.ERRSS007}
            refer={{ funcId: FUNC.SYS0041, partId: 'input', itemId: 'order' }}
            onChange={setInput}
          />
        </FormItmGrid>
      </FormCtnGrid>
    </FormDialog>
  )
}
export default Input
