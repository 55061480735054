import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import lo from 'lodash'
import { Auth } from 'aws-amplify'

import { RootState } from 'src/js/slice/store'
import { redirect } from 'src/js/router'
import * as Type from 'src/js/type/base'
import { State as StateType } from './type'
import { FUNCTION_ID as FUNC, COMP_KBN } from 'src/js/util/constants'

import * as Api from './api'

// 非同期処理定義
// ユーザー情報取得
export const initUser = createAsyncThunk<Type.User, string>(
  `${FUNC.APP}/initUser`,
  async (userId, thunkAPI) => {
    try {
      return await Api.getUser(userId)
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 権限情報取得
export const initAuth = createAsyncThunk<Type.Auth[], string>(
  `${FUNC.APP}/initAuth`,
  async (userId, thunkAPI) => {
    try {
      return await Api.getAuths(userId)
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// ページセッション保存
export const savePageSession = createAsyncThunk<object, string, { state: RootState }>(
  `${FUNC.APP}/savePageSession`,
  async (funcId, thunkAPI) => {
    try {
      if (!funcId || !lo.has(thunkAPI.getState(), funcId)) return
      return lo.get(thunkAPI.getState(), funcId)
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// フィルターセッション保存
export const saveFilterSession = createAsyncThunk<
  object,
  { funcId: string; filterId: string },
  { state: RootState }
>(`${FUNC.APP}/saveFilterSession`, async (filterKey, thunkAPI) => {
  try {
    if (
      !!!filterKey ||
      !filterKey.funcId ||
      !filterKey.filterId ||
      !lo.has(thunkAPI.getState(), filterKey.funcId) ||
      !lo.has(thunkAPI.getState(), `${filterKey.funcId}.${filterKey.filterId}`)
    )
      return
    return lo.get(thunkAPI.getState(), `${filterKey.funcId}.${filterKey.filterId}`)
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

const _footmark = (state: StateType, action: PayloadAction<string>) => {
  const location = action.payload

  // リダイレクトコンフィグ情報取得
  const config = lo.find(redirect, { 'path': location })
  if (!config) return

  if (config.compKbn === COMP_KBN.MAIN) {
    // メイン画面／メニュー画面の場合

    if (state.footmark.length !== 1 || state.footmark[0].pathname !== location) {
      // セッションクリア
      if (
        !!state.footmark &&
        lo.has(state.footmark[0], 'pathname') &&
        state.footmark[0].pathname !== location
      ) {
        // ページセッションリセット
        _clearPageSession(state)
        // フィルターセッションリセット
        _clearFilterSession(state)
        // フィルタークリア
        _clearFilter(state)
      }

      // Footmarkリセット
      state.footmark = [{ pathname: location, label: config.label }]
    }
  } else if (config.compKbn === COMP_KBN.SUB) {
    // サブ画面の場合
    const curIdx = lo.findIndex(state.footmark, { 'pathname': location })
    if (curIdx === -1) {
      // 次のサブ画面へ遷移する場合
      const mark = lo.cloneDeep(state.footmark)
      mark.push({ pathname: location, label: config.label })
      state.footmark = mark
    } else if (state.footmark.length !== curIdx + 1) {
      // 前の画面へ戻る場合
      state.footmark = lo.take(state.footmark, curIdx + 1)
    }
  }
}

const _systemError = (state: StateType) => {
  // const { code, msg, error } = action.payload;
  state.hasError = true // セッションエラー画面へ遷移
  state.backdropFlg = false
  ;(async () => {
    await Auth.signOut()
  })()
}

const _pushMessage = (state: StateType, action: PayloadAction<Type.MessageNotice>) => {
  state.message = {
    openFlg: true,
    ...action.payload,
  }
}

const _clearPageSession = (state: StateType) => {
  state.pageSession = {}
}

const _clearFilterSession = (state: StateType) => {
  state.filterSession = {}
}

const _clearFilter = (state: StateType) => {
  state.filter = getInitialState().filter
}

// Slice定義
const getInitialState = (): StateType => ({
  user: undefined,
  auth: [],
  hasError: false,
  footmark: [],
  backdropFlg: false,
  message: undefined,
  isLoading: true,
  pageSession: {},
  filterSession: {},
  filter: {
    count: 0,
  },
})
export const Slice = createSlice({
  name: FUNC.APP,
  initialState: getInitialState(),
  reducers: {
    // Footmark関連
    footmark: _footmark,

    // エラー関連
    systemError: _systemError,

    // メッセージ関連
    closeMessage: (state: StateType) => {
      state.message = {
        openFlg: false,
      }
    },
    pushMessage: _pushMessage,

    // Backdrop関連
    backdropOn: (state) => {
      state.backdropFlg = true
    },
    backdropOff: (state) => {
      state.backdropFlg = false
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload
    },

    setFilterCount: (state, action) => {
      state.filter.count = action.payload
    },

    // ページセッション関連
    clearPageSession: _clearPageSession,

    // セッションクリア
    clearSession: (state) => {
      _clearPageSession(state)
      _clearFilterSession(state)
      _clearFilter(state)
    },
  },
  extraReducers: (builder) => {
    builder
      // ユーザ情報取得
      .addCase(initUser.pending, () => {
        // 処理なし
      })
      .addCase(initUser.fulfilled, (state, action) => {
        const { userId, email, roleId, roleDesc, shipManagerShort, targetShipKbn, liveCheckKbn } =
          action.payload
        state.user = {
          userId: userId,
          userNm: userId,
          email: email,
          org: '',
          roleId: roleId,
          roleDesc: roleDesc,
          shipManagerShort: shipManagerShort,
          targetShipKbn: targetShipKbn,
          liveCheckKbn: liveCheckKbn,
        }
      })
      .addCase(initUser.rejected, (state) => {
        // 検索結果ステータスリセット
        state.user = getInitialState().user
      })

      // 権限情報取得
      .addCase(initAuth.pending, () => {
        // 処理なし
      })
      .addCase(initAuth.fulfilled, (state, action) => {
        state.auth = action.payload
      })
      .addCase(initAuth.rejected, (state) => {
        // 検索結果ステータスリセット
        state.auth = getInitialState().auth
      })

      // ページセッション保存
      .addCase(savePageSession.fulfilled, (state, action) => {
        const funcId = action.meta.arg
        if (funcId) lo.set(state.pageSession, funcId, lo.cloneDeep(action.payload))
      })

      // フィルターセッション保存
      .addCase(saveFilterSession.fulfilled, (state, action) => {
        const filterKey = action.meta.arg
        if (filterKey) lo.set(state.filterSession, filterKey.funcId, action.payload)
      })
  },
})

export const {
  footmark,
  systemError,
  pushMessage,
  closeMessage,
  backdropOn,
  backdropOff,
  clearPageSession,
  setLoading,
  setFilterCount,
  clearSession,
} = Slice.actions

export default Slice.reducer
