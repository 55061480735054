import React from 'react'
import { useAppDispatch } from 'src/js/slice/hooks'

import { Box, Link } from '@mui/material'

import { updateFileStatus } from 'src/js/slice/B0011'

import { FileInfo as FileInfoType } from 'src/js/slice/B0011/type'
import { FILE_STATUS } from 'src/js/util/constants'

type Props = {
  index: number
  data: { [key: string]: string }
}
const FileStatusLabel = ({ index, data }: Props) => {
  const dispatch = useAppDispatch()
  const fileInfo = data as unknown as FileInfoType

  const _onClick = () => {
    ;(async () => {
      await dispatch(updateFileStatus({ index: index, id: fileInfo.id, imoNo: fileInfo.imoNo }))
    })()
  }

  if (
    fileInfo.fileStatus === FILE_STATUS.EUMS_ERROR ||
    fileInfo.fileStatus === FILE_STATUS.LIVE_ERROR ||
    fileInfo.fileStatus === FILE_STATUS.WADATSUMI_ERR
  ) {
    return (
      <Link component='button' sx={{ textAlign: 'left' }} onClick={_onClick}>
        {fileInfo.fileStatus}
      </Link>
    )
  }

  return <Box>{fileInfo.fileStatus}</Box>
}

export default FileStatusLabel
