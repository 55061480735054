import React from 'react'

import * as Style from './style'
import { Box, Fade, Typography, colors } from '@mui/material'
import { SYS_CMN } from 'src/resource'

type Props = {
  openFlg: boolean
}
const Toolbar = ({ openFlg }: Props) => {
  return (
    <Box sx={Style.toolbar}>
      <Box component='img' sx={{ width: 30, marginLeft: 0.5 }} src='/image/logo.svg' alt='logo' />
      <Fade in={openFlg} timeout={{ exit: 500 }}>
        <Typography sx={{ color: colors.grey[50], marginLeft: 3 }} variant='h6'>
          {SYS_CMN.APP_DESC}
        </Typography>
      </Fade>
    </Box>
  )
}

export default Toolbar
