import { Dispatch } from '@reduxjs/toolkit'

import { pushMessage, backdropOn, backdropOff } from 'src/js/slice/App'
import { formatDate } from 'src/js/util/utility'
import { SYS_MSG } from 'src/resource'
import * as GqlType from 'src/API'

// 共通処理：検索
export const query = async <ParamType, ReturnType>(
  pDispatch: Dispatch,
  pParam: ParamType,
  pFunc: (pDispatch: Dispatch, pParam: ParamType) => Promise<ReturnType>
): Promise<ReturnType> => {
  let rsp
  try {
    rsp = await pFunc(pDispatch, pParam)
  } catch (err) {
    pDispatch(pushMessage({ type: 'error', error: [SYS_MSG.ERRSS003] }))
    throw err
  }

  return rsp
}

// 共通処理：更新
export const mutation = async <ParamType, ReturnType>(
  pDispatch: Dispatch,
  pParam: ParamType,
  pFunc: (pDispatch: Dispatch, pParam: ParamType) => Promise<ReturnType>
): Promise<ReturnType> => {
  // Backdrop開始
  pDispatch(backdropOn())

  let rsp
  try {
    rsp = await pFunc(pDispatch, pParam)
  } catch (err) {
    pDispatch(pushMessage({ type: 'error', error: Array.isArray(err) ? err : [SYS_MSG.ERRSS003] }))
    throw err
  } finally {
    // Backdrop終了
    pDispatch(backdropOff())
  }

  // 処理通知：成功
  pDispatch(pushMessage({ type: 'success', message: SYS_MSG.INFO_PROC_FINISH }))

  return rsp
}

export const getDateTimeConditionInput = (
  dateF: string,
  dateT: string
): GqlType.ModelStringKeyConditionInput | null => {
  if (!!dateF && !!dateT) {
    return {
      between: [
        formatDate(new Date(dateF)) + 'T00:00:00Z',
        formatDate(new Date(dateT)) + 'T23:59:59Z',
      ],
    }
  }

  if (!!dateF) {
    return {
      ge: formatDate(new Date(dateF)) + 'T00:00:00Z',
    }
  }

  if (!!dateT) {
    return {
      le: formatDate(new Date(dateT)) + 'T23:59:59Z',
    }
  }

  return null
}

export const getStrDateConditionInput = (
  dateF: string,
  dateT: string
): GqlType.ModelStringKeyConditionInput | null => {
  if (!!dateF && !!dateT) {
    return {
      between: [dateF, dateT],
    }
  }

  if (!!dateF) {
    return {
      ge: dateF,
    }
  }

  if (!!dateT) {
    return {
      le: dateT,
    }
  }

  return null
}
