import { Theme } from '@mui/material/styles'

const DRAWER_WIDTH = 240

export const content = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
} as const

export const contentShift = {
  maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
} as const

export const contentClps = (theme: Theme) =>
  ({
    maxWidth: `calc(100% - ${theme.spacing(7)})`,
  } as const)
