import React, { useState } from 'react'

import { Toolbar, Typography, Box, Tooltip, IconButton } from '@mui/material'
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material'
import { OkIconButton } from 'src/js/component/Base/Button'
import { ConfirmDialog } from 'src/js/component/Base/Dialog'

import { SYS_CMN, SYS_MSG } from 'src/resource'

import * as Style from './style'

type Props = {
  title: string // タイトル
  isLoading: boolean // ローディングフラグ
  clpFlg: boolean // 折り畳みボタンフラグ
  onCollapse: (clpFlg: boolean) => void // イベント：折り畳みボタン
  okFlg?: boolean // 実行ボタンフラグ
  onOk?: () => void // イベント：実行ボタン
}
const TitleBar = ({ title, isLoading, clpFlg, onCollapse, okFlg = false, onOk }: Props) => {
  const [cfmState, setCfmState] = useState<{
    open: boolean
    msg: string
    onOK: () => void
  }>({
    open: false,
    msg: SYS_MSG.INFO_SAVE_CONFIRM,
    onOK: () => {
      console.log('')
    },
  })

  const _onClick = (msg: string, func?: () => void) => {
    setCfmState({
      open: true,
      msg: msg,
      onOK: () => {
        setCfmState((prev) => ({ ...prev, open: false }))
        func && func()
      },
    })
  }

  return (
    <Toolbar sx={{ px: 2, justifyContent: 'flex-start' }} variant='dense'>
      {/* タイトル */}
      <Typography
        sx={{ flexGrow: 1, color: (theme) => theme.palette.primary.main }}
        variant='h6'
        component='div'
      >
        {title}
      </Typography>

      {/* ボタンバー */}
      <Box>
        {/* 実行ボタン */}
        {!isLoading && clpFlg && okFlg && (
          <OkIconButton
            onClick={() => {
              _onClick(SYS_MSG.INFO_SAVE_CONFIRM, onOk)
            }}
          />
        )}

        {/* 折り畳みボタン */}
        <Tooltip title={clpFlg ? SYS_CMN.ARA_CLP_ON : SYS_CMN.ARA_CLP_OFF}>
          <IconButton
            sx={[Style.clpIcon, clpFlg ? Style.clpOn : Style.clpOff]}
            aria-label={clpFlg ? SYS_CMN.ARA_CLP_ON : SYS_CMN.ARA_CLP_OFF}
            size={'small'}
            onClick={() => {
              onCollapse(!clpFlg)
            }}
          >
            <ArrowDownIcon fontSize='large' />
          </IconButton>
        </Tooltip>
      </Box>

      {/* 確認ダイアログ */}
      <ConfirmDialog
        isOpen={cfmState.open}
        text={cfmState.msg}
        onOK={cfmState.onOK}
        onClose={() => {
          setCfmState((prev) => ({ ...prev, open: false }))
        }}
      />
    </Toolbar>
  )
}

export default TitleBar
