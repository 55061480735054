/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      name
      order
      icon
      menuItem {
        items {
          id
          menuId
          name
          order
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $id: ID
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenus(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        icon
        menuItem {
          items {
            id
            menuId
            name
            order
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      menuId
      name
      order
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $id: ID
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        menuId
        name
        order
        pageId
        page {
          id
          name
          funcId
          action
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      name
      funcId
      action
      order
      createdAt
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $id: ID
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      comment
      roleAuth {
        items {
          id
          roleId
          pageId
          page {
            id
            name
            funcId
            action
            order
            createdAt
            updatedAt
          }
          roleType
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $id: ID
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        comment
        roleAuth {
          items {
            id
            roleId
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            roleType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoleAuth = /* GraphQL */ `
  query GetRoleAuth($id: ID!) {
    getRoleAuth(id: $id) {
      id
      roleId
      pageId
      page {
        id
        name
        funcId
        action
        order
        createdAt
        updatedAt
      }
      roleType
      order
      createdAt
      updatedAt
    }
  }
`;
export const listRoleAuths = /* GraphQL */ `
  query ListRoleAuths(
    $id: ID
    $filter: ModelRoleAuthFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoleAuths(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roleId
        pageId
        page {
          id
          name
          funcId
          action
          order
          createdAt
          updatedAt
        }
        roleType
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      shipManagerShort
      mailBox
      memo
      targetShipKbn
      liveCheckKbn
      roleId
      role {
        id
        name
        comment
        roleAuth {
          items {
            id
            roleId
            pageId
            page {
              id
              name
              funcId
              action
              order
              createdAt
              updatedAt
            }
            roleType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMstVessel = /* GraphQL */ `
  query GetMstVessel($id: ID!) {
    getMstVessel(id: $id) {
      id
      imoNo
      vesselName
      classification
      classificationSub
      shipManagerShort
      managementScheme
      aliveStatus
      eumsUnavailableFlag
      mailBox
      businessSection
      createdAt
      updatedAt
    }
  }
`;
export const listMstVessels = /* GraphQL */ `
  query ListMstVessels(
    $id: ID
    $filter: ModelMstVesselFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMstVessels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        classification
        classificationSub
        shipManagerShort
        managementScheme
        aliveStatus
        eumsUnavailableFlag
        mailBox
        businessSection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileSumInfo = /* GraphQL */ `
  query GetFileSumInfo($id: ID!) {
    getFileSumInfo(id: $id) {
      id
      imoNo
      vesselName
      operationStatus
      lastCheckedDate
      uploadedDate
      sender
      shipAppFlg
      shipAppVersion
      numberOfRecord
      numberOfError
      confirmedFlag
      comments {
        items {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        nextToken
      }
      lastCommentId
      lastComment {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      lastCommentContent
      equipmentId
      equipment {
        id
        imoNo
        fmGoVer
        ipadEumsVer
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      lastVersion
      vesselId
      vessel {
        id
        imoNo
        vesselName
        classification
        classificationSub
        shipManagerShort
        managementScheme
        aliveStatus
        eumsUnavailableFlag
        mailBox
        businessSection
        createdAt
        updatedAt
      }
      aliveStatus
      businessSection
      shipManagerShort
      classification
      classificationSub
      eumsUnavailableFlag
      createdAt
      updatedAt
    }
  }
`;
export const listFileSumInfos = /* GraphQL */ `
  query ListFileSumInfos(
    $id: ID
    $filter: ModelFileSumInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileSumInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          nextToken
        }
        lastCommentId
        lastComment {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        lastCommentContent
        equipmentId
        equipment {
          id
          imoNo
          fmGoVer
          ipadEumsVer
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        lastVersion
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        aliveStatus
        businessSection
        shipManagerShort
        classification
        classificationSub
        eumsUnavailableFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileInfo = /* GraphQL */ `
  query GetFileInfo($id: ID!) {
    getFileInfo(id: $id) {
      id
      imoNo
      vesselName
      fileName
      fileNameShort
      filePath
      fileType
      numberOfRecord
      startTime
      endTime
      fileDate
      createdAt
      updatedAt
      sender
      shipAppFlg
      shipAppVersion
      fileStatus
      operationStatus
      confirmedFlag
      numOfItems
      countItems
      snFlgUpdateDate
      reserve1
      reserve2
      reserve3
      version
      snConfirm {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumId
      fileSum {
        id
        imoNo
        vesselName
        operationStatus
        lastCheckedDate
        uploadedDate
        sender
        shipAppFlg
        shipAppVersion
        numberOfRecord
        numberOfError
        confirmedFlag
        comments {
          items {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          nextToken
        }
        lastCommentId
        lastComment {
          id
          imoNo
          vesselName
          comment
          createdAt
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          fileSumInfo
          updatedAt
        }
        lastCommentContent
        equipmentId
        equipment {
          id
          imoNo
          fmGoVer
          ipadEumsVer
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        lastVersion
        vesselId
        vessel {
          id
          imoNo
          vesselName
          classification
          classificationSub
          shipManagerShort
          managementScheme
          aliveStatus
          eumsUnavailableFlag
          mailBox
          businessSection
          createdAt
          updatedAt
        }
        aliveStatus
        businessSection
        shipManagerShort
        classification
        classificationSub
        eumsUnavailableFlag
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFileInfos = /* GraphQL */ `
  query ListFileInfos(
    $id: ID
    $filter: ModelFileInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        fileName
        fileNameShort
        filePath
        fileType
        numberOfRecord
        startTime
        endTime
        fileDate
        createdAt
        updatedAt
        sender
        shipAppFlg
        shipAppVersion
        fileStatus
        operationStatus
        confirmedFlag
        numOfItems
        countItems
        snFlgUpdateDate
        reserve1
        reserve2
        reserve3
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumId
        fileSum {
          id
          imoNo
          vesselName
          operationStatus
          lastCheckedDate
          uploadedDate
          sender
          shipAppFlg
          shipAppVersion
          numberOfRecord
          numberOfError
          confirmedFlag
          comments {
            items {
              id
              imoNo
              vesselName
              comment
              createdAt
              userId
              user {
                id
                name
                shipManagerShort
                mailBox
                memo
                targetShipKbn
                liveCheckKbn
                roleId
                role {
                  id
                  name
                  comment
                  roleAuth {
                    items {
                      id
                      roleId
                      pageId
                      page {
                        id
                        name
                        funcId
                        action
                        order
                        createdAt
                        updatedAt
                      }
                      roleType
                      order
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              fileSumInfo
              updatedAt
            }
            nextToken
          }
          lastCommentId
          lastComment {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          lastCommentContent
          equipmentId
          equipment {
            id
            imoNo
            fmGoVer
            ipadEumsVer
            settingId
            setting {
              id
              imoNo
              systemVersion
              applicationVersion
              osVersion
              filePath
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          version
          snConfirm {
            version
            confirmFlg
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          lastVersion
          vesselId
          vessel {
            id
            imoNo
            vesselName
            classification
            classificationSub
            shipManagerShort
            managementScheme
            aliveStatus
            eumsUnavailableFlag
            mailBox
            businessSection
            createdAt
            updatedAt
          }
          aliveStatus
          businessSection
          shipManagerShort
          classification
          classificationSub
          eumsUnavailableFlag
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fileSumInfo
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $id: ID
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listComments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      imoNo
      fmGoVer
      ipadEumsVer
      settingId
      setting {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEquipment = /* GraphQL */ `
  query ListEquipment(
    $id: ID
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEquipment(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        fmGoVer
        ipadEumsVer
        settingId
        setting {
          id
          imoNo
          systemVersion
          applicationVersion
          osVersion
          filePath
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      imoNo
      systemVersion
      applicationVersion
      osVersion
      filePath
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $id: ID
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        systemVersion
        applicationVersion
        osVersion
        filePath
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpasDaliy = /* GraphQL */ `
  query GetSpasDaliy($id: ID!) {
    getSpasDaliy(id: $id) {
      id
      imoNo
      localDate
      reportNo
      createdAt
      updatedAt
    }
  }
`;
export const listSpasDaliys = /* GraphQL */ `
  query ListSpasDaliys(
    $id: ID
    $filter: ModelSpasDaliyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSpasDaliys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        imoNo
        localDate
        reportNo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCodeMaster = /* GraphQL */ `
  query GetCodeMaster($id: ID!) {
    getCodeMaster(id: $id) {
      id
      kbn
      code
      name
      order
      value1
      value2
      value3
      value4
      value5
      memo
      createdAt
      updatedAt
    }
  }
`;
export const listCodeMasters = /* GraphQL */ `
  query ListCodeMasters(
    $id: ID
    $filter: ModelCodeMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodeMasters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        kbn
        code
        name
        order
        value1
        value2
        value3
        value4
        value5
        memo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSNConfirm = /* GraphQL */ `
  query GetSNConfirm($version: String!) {
    getSNConfirm(version: $version) {
      version
      confirmFlg
      userId
      user {
        id
        name
        shipManagerShort
        mailBox
        memo
        targetShipKbn
        liveCheckKbn
        roleId
        role {
          id
          name
          comment
          roleAuth {
            items {
              id
              roleId
              pageId
              page {
                id
                name
                funcId
                action
                order
                createdAt
                updatedAt
              }
              roleType
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSNConfirms = /* GraphQL */ `
  query ListSNConfirms(
    $version: String
    $filter: ModelSNConfirmFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSNConfirms(
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        confirmFlg
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileInfoByImoNo = /* GraphQL */ `
  query GetFileInfoByImoNo(
    $imoNo: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileInfoByImoNo(
      imoNo: $imoNo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imoNo
        vesselName
        fileName
        fileNameShort
        filePath
        fileType
        numberOfRecord
        startTime
        endTime
        fileDate
        createdAt
        updatedAt
        sender
        shipAppFlg
        shipAppVersion
        fileStatus
        operationStatus
        confirmedFlag
        numOfItems
        countItems
        snFlgUpdateDate
        reserve1
        reserve2
        reserve3
        version
        snConfirm {
          version
          confirmFlg
          userId
          user {
            id
            name
            shipManagerShort
            mailBox
            memo
            targetShipKbn
            liveCheckKbn
            roleId
            role {
              id
              name
              comment
              roleAuth {
                items {
                  id
                  roleId
                  pageId
                  page {
                    id
                    name
                    funcId
                    action
                    order
                    createdAt
                    updatedAt
                  }
                  roleType
                  order
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumId
        fileSum {
          id
          imoNo
          vesselName
          operationStatus
          lastCheckedDate
          uploadedDate
          sender
          shipAppFlg
          shipAppVersion
          numberOfRecord
          numberOfError
          confirmedFlag
          comments {
            items {
              id
              imoNo
              vesselName
              comment
              createdAt
              userId
              user {
                id
                name
                shipManagerShort
                mailBox
                memo
                targetShipKbn
                liveCheckKbn
                roleId
                role {
                  id
                  name
                  comment
                  roleAuth {
                    items {
                      id
                      roleId
                      pageId
                      page {
                        id
                        name
                        funcId
                        action
                        order
                        createdAt
                        updatedAt
                      }
                      roleType
                      order
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              fileSumInfo
              updatedAt
            }
            nextToken
          }
          lastCommentId
          lastComment {
            id
            imoNo
            vesselName
            comment
            createdAt
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            fileSumInfo
            updatedAt
          }
          lastCommentContent
          equipmentId
          equipment {
            id
            imoNo
            fmGoVer
            ipadEumsVer
            settingId
            setting {
              id
              imoNo
              systemVersion
              applicationVersion
              osVersion
              filePath
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          settingId
          setting {
            id
            imoNo
            systemVersion
            applicationVersion
            osVersion
            filePath
            createdAt
            updatedAt
          }
          version
          snConfirm {
            version
            confirmFlg
            userId
            user {
              id
              name
              shipManagerShort
              mailBox
              memo
              targetShipKbn
              liveCheckKbn
              roleId
              role {
                id
                name
                comment
                roleAuth {
                  items {
                    id
                    roleId
                    pageId
                    page {
                      id
                      name
                      funcId
                      action
                      order
                      createdAt
                      updatedAt
                    }
                    roleType
                    order
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          lastVersion
          vesselId
          vessel {
            id
            imoNo
            vesselName
            classification
            classificationSub
            shipManagerShort
            managementScheme
            aliveStatus
            eumsUnavailableFlag
            mailBox
            businessSection
            createdAt
            updatedAt
          }
          aliveStatus
          businessSection
          shipManagerShort
          classification
          classificationSub
          eumsUnavailableFlag
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCommentsbyImoNo = /* GraphQL */ `
  query GetCommentsbyImoNo(
    $imoNo: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsbyImoNo(
      imoNo: $imoNo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        user {
          id
          name
          shipManagerShort
          mailBox
          memo
          targetShipKbn
          liveCheckKbn
          roleId
          role {
            id
            name
            comment
            roleAuth {
              items {
                id
                roleId
                pageId
                page {
                  id
                  name
                  funcId
                  action
                  order
                  createdAt
                  updatedAt
                }
                roleType
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        fileSumInfo
        updatedAt
      }
      nextToken
    }
  }
`;
