import React from 'react'

import { Toolbar, Typography, Box, Button, Pagination } from '@mui/material'
import { AddCircle as AddCircleIcon } from '@mui/icons-material'

import { SYS_CMN } from 'src/resource'
import * as Style from './style'
import * as Type from 'src/js/type/base'

type Props = {
  title: string // タイトル
  isLoading: boolean // ローディングフラグ

  paging: Type.Pagging | undefined // ページング対象
  onPaging: (pageNum: number) => void // イベント：ページング

  addFlg?: boolean // 新規イベント有無フラグ
  onAdd?: () => void // イベント：新規

  custBtnFlg?: boolean // カスタマイズボタン有無フラグ
  CustBtn: React.ReactNode // コンポ：カスタマイズボタン
}
const TitleBar = ({
  title,
  isLoading,
  paging,
  onPaging,
  addFlg = false,
  onAdd,
  custBtnFlg = false,
  CustBtn,
}: Props) => {
  return (
    <Toolbar sx={{ px: 2, justifyContent: 'flex-start' }} variant='dense'>
      <Typography
        // sx={{ flex: '1 1 70%', color: (theme) => theme.palette.primary.main }}
        sx={{ flexGrow: 1, color: (theme) => theme.palette.primary.main }}
        variant='h6'
        component='div'
      >
        {title}
      </Typography>

      <Box>
        {/* 新規ボタン */}
        {addFlg && onAdd && (
          <Button
            sx={Style.toobarBtn}
            size='small'
            startIcon={<AddCircleIcon />}
            onClick={() => {
              onAdd()
            }}
          >
            {SYS_CMN.BTN_ADD}
          </Button>
        )}

        {custBtnFlg && CustBtn}
      </Box>

      {/* ページング */}
      <Box>
        {!isLoading && (
          <Pagination
            count={paging?.count}
            page={paging?.page}
            color='primary'
            size='small'
            shape='rounded'
            showFirstButton
            showLastButton
            onChange={(e, page) => {
              onPaging(page)
            }}
          />
        )}
      </Box>
    </Toolbar>
  )
}

export default TitleBar
