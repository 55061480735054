/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      imoNo
      vesselName
      comment
      createdAt
      userId
      user {
        id
        name
      }
      fileSumInfo
      updatedAt
    }
  }
`
export const getMstVessel = /* GraphQL */ `
  query GetMstVessel($id: ID!) {
    getMstVessel(id: $id) {
      id
      imoNo
      vesselName
      classification
      classificationSub
      shipManagerShort
      managementScheme
      aliveStatus
      eumsUnavailableFlag
      mailBox
      createdAt
      updatedAt
    }
  }
`
export const getCommentsbyImoNo = /* GraphQL */ `
  query GetCommentsbyImoNo(
    $imoNo: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsbyImoNo(
      imoNo: $imoNo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imoNo
        vesselName
        comment
        createdAt
        userId
        fileSumInfo
        updatedAt
      }
      nextToken
    }
  }
`
