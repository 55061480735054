import React from 'react'
import { Box, Divider, Typography, Link } from '@mui/material'

import APP_CONFIG from 'src/config/application'

const Copyright = () => {
  const copyright = APP_CONFIG.resource.copyright
  return (
    <Typography sx={{ display: 'block' }} variant='overline' gutterBottom>
      &copy; {copyright.year} Copyright: <Link href={copyright.url}>{copyright.desc}</Link> All
      Rights Reserved.
    </Typography>
  )
}

const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
        <Copyright />
      </Box>
    </Box>
  )
}

export default Footer
