import React from 'react'
import Box from '@mui/material/Box'
import * as Style from './style'

type Props = {
  children: React.ReactNode
}
const MainContent = ({ children }: Props) => {
  return (
    <Box component='main' sx={Style.main}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          height: theme.spacing(7),
        })}
      />
      {children}
    </Box>
  )
}

export default MainContent
