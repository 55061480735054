import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { setFilterCount } from 'src/js/slice/App'
import { query, mutation } from 'src/js/util/graphql'
import * as Api from './api'

import {
  FUNCTION_ID as FUNC,
  TARGET_SHIP_KBN,
  ALIVE_STATUS,
  EUMS_ON_BOARD,
} from 'src/js/util/constants'
import { countFilter } from 'src/js/util/utility'
import * as Type from './type'
import * as GqlType from 'src/API'

// 非同期処理定義
type UpdateOperStatusProps = {
  id: string
  value: string
}
export const updateOperStatus = createAsyncThunk<
  UpdateOperStatusProps,
  UpdateOperStatusProps,
  { state: RootState }
>(`${FUNC.B0010}/updateOperStatus `, async (param, thunkAPI) => {
  try {
    const id = new String(param.id).replace('operationStatus_', '')

    return await mutation<UpdateOperStatusProps, UpdateOperStatusProps>(
      thunkAPI.dispatch,
      { value: param.value, id: id },
      async (dispatch, param) => {
        // 更新処理
        await Api.updateOperStatus(param.id, param.value)
        return param
      }
    )
  } catch (err) {
    console.log(err)
    return thunkAPI.rejectWithValue({})
  }
})

// 更新処理
export const updateEquipment = createAsyncThunk<void, undefined, { state: RootState }>(
  `${FUNC.B0010}/updateEquipment`,
  async (imoNo, thunkAPI) => {
    try {
      return await mutation<Type.Equipment, void>(
        thunkAPI.dispatch,
        thunkAPI.getState().B0010.equipment,
        async (dispatch, param) => {
          await Api.updateEquipment(param)
          return
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 検索処理
export const search = createAsyncThunk<Type.FileSumInfo[], undefined, { state: RootState }>(
  `${FUNC.B0010}/search`,
  async (_, thunkAPI) => {
    try {
      return await query<Type.Filter, Type.FileSumInfo[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().B0010.filter,
        async (dispatch, filter) => {
          // フィルターカウンター
          dispatch(setFilterCount(countFilter(filter)))

          // 検索処理
          return await Api.search(filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// 明細表示
export const inputEquipment = createAsyncThunk<GqlType.Equipment, string, { state: RootState }>(
  `${FUNC.B0010}/inputEquipment`,
  async (id, thunkAPI) => {
    try {
      return await query<string, GqlType.Equipment>(thunkAPI.dispatch, id, async (dispath, id) => {
        return await Api.getEquipmentById(id)
      })
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// ページセッション保存
export const restorePageSession = createAsyncThunk<
  Type.State | undefined,
  undefined,
  { state: RootState }
>(`${FUNC.B0010}/restorePageSession`, async (_, thunkAPI) => {
  try {
    if (lo.has(thunkAPI.getState().app.pageSession, FUNC.B0010))
      return lo.get(thunkAPI.getState().app.pageSession, FUNC.B0010) as Type.State
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

// フィルター初期化
const _getInitFilter = (targetShipKbn: string, shipManagerShort: string): Type.Filter => {
  // 初期設定
  const filter = getInitialState().filter

  // shipManager
  if (TARGET_SHIP_KBN.SELF_ALIVE === targetShipKbn) {
    filter.shipManager = shipManagerShort
  }

  // aliveStatus
  filter.aliveStatus = ALIVE_STATUS.ALIVE

  // eumsOnBoard
  filter.eumsOnBoard = EUMS_ON_BOARD.ON_BOARD

  return filter
}

// Slice定義
const getInitialState = (): Type.State => ({
  // 検索条件
  filter: {
    imoNo: '',
    vesselName: '',
    comment: '',
    shipManager: '',
    uploadedDateF: '',
    uploadedDateT: '',
    checkedDateF: '',
    checkedDateT: '',
    aliveStatus: '',
    eumsOnBoard: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
  },

  // 設備情報
  equipment: {
    isLoading: false,
    mode: 'edit',

    id: '',
    pcOS: '',
    fmProVer: '',
    fmGoVer: '',
    ipadEumsVer: '',
    filePath: '',
  },
})
export const Slice = createSlice({
  name: FUNC.B0010,
  initialState: getInitialState(),
  reducers: {
    initFilter: (state, action) => {
      const { targetShipKbn, shipManagerShort } = action.payload
      state.filter = _getInitFilter(targetShipKbn, shipManagerShort)
    },
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    setEquipment: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.equipment, id, value)
    },
    setOperationStatus: (state, action) => {
      const { id, value } = action.payload
      const index = new String(id).replace('operationStatus_', '')
      const data = lo.cloneDeep(state.result.data)
      data[parseInt(index)].operationStatus = value
      state.result.data = data
    },
    clearFilter: (state, action) => {
      const { targetShipKbn, shipManagerShort } = action.payload
      state.filter = _getInitFilter(targetShipKbn, shipManagerShort)
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.filter = initState.filter
      state.result = initState.result
      state.equipment = initState.equipment
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // ページセッション復元
      .addCase(restorePageSession.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(restorePageSession.fulfilled, (state, action) => {
        if (action.payload) state.filter = action.payload.filter
        state.result.isLoading = false
      })
      .addCase(restorePageSession.rejected, (state) => {
        state.result = getInitialState().result
      })

      // 入力更新
      .addCase(updateOperStatus.pending, () => {
        // 処理なし
      })
      .addCase(updateOperStatus.fulfilled, (state, action) => {
        const { id, value } = action.payload
        const data = lo.cloneDeep(state.result.data)
        const fileSumInfo = lo.find(data, (row) => row.id === id)
        if (fileSumInfo) {
          fileSumInfo.operationStatus = value
        }
        state.result.data = data
      })
      .addCase(updateOperStatus.rejected, () => {
        // 処理なし
      })

      // 入力画面初期化
      .addCase(inputEquipment.pending, (state) => {
        state.equipment.isLoading = true
      })
      .addCase(inputEquipment.fulfilled, (state, action) => {
        const equipment = action.payload
        state.equipment = {
          isLoading: false,
          mode: 'edit',
          id: equipment.id,
          pcOS: equipment.setting?.osVersion ? equipment.setting?.osVersion : '',
          fmProVer: equipment.setting?.applicationVersion
            ? equipment.setting?.applicationVersion
            : '',
          fmGoVer: equipment.fmGoVer ? equipment.fmGoVer : '',
          ipadEumsVer: equipment.ipadEumsVer ? equipment.ipadEumsVer : '',
          filePath: equipment.setting?.filePath ? equipment.setting?.filePath : '',
        }
      })
      .addCase(inputEquipment.rejected, (state) => {
        state.equipment = getInitialState().equipment
      })

      // 入力更新
      .addCase(updateEquipment.pending, (state) => {
        state.equipment.isLoading = true
      })
      .addCase(updateEquipment.fulfilled, (state) => {
        state.equipment.isLoading = false
      })
      .addCase(updateEquipment.rejected, (state) => {
        state.equipment = getInitialState().equipment
      })
  },
})

export const {
  initFilter,
  setFilter,
  setEquipment,
  setOperationStatus,
  clearFilter,
  cancelFilter,
  gc,
} = Slice.actions

export default Slice.reducer
