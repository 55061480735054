import React from 'react'

import {
  Typography,
  Button,
  CircularProgress,
  Box,
  Card,
  CardHeader,
  CardActions,
  CardContent,
} from '@mui/material'

type Props = {
  title: string // タイトル
  subheader: string // サブタイトル
  variant?: 'elevation' | 'outlined' // スタイル
  btnOkNm: string // OKボタン名称
  onOk: () => void // OKボタン
  isLoading?: boolean // ローディングフラグ
  children: React.ReactNode
}
const StandardCard = ({
  title, // タイトル
  subheader,
  variant = 'outlined',
  btnOkNm,
  onOk,
  isLoading = false,
  children,
}: Props) => {
  return (
    <Card sx={{ maxWidth: '16rem' }} variant={variant}>
      {/* ヘッダー */}
      <CardHeader title={title} subheader={subheader} />

      {/* コンテンツ */}
      <CardContent sx={{ height: '8rem' }}>
        {isLoading && (
          <Box sx={{ textAlign: 'center', pt: 1 }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <Typography variant='body2' color='textSecondary' component='p'>
            {children}
          </Typography>
        )}
      </CardContent>

      {/* ボタン */}
      <CardActions>
        <Button size='small' color='primary' onClick={onOk}>
          {btnOkNm}
        </Button>
      </CardActions>
    </Card>
  )
}

export default StandardCard
