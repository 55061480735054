import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'

import * as GqlType from 'src/API'
import { HanyoItem } from 'src/js/type/base'

export const initCodeMaster = async (kbn: string): Promise<HanyoItem> => {
  const rsp = await API.graphql({
    query: Queries.listCodeMasters,
    variables: { filter: { kbn: { eq: kbn } } },
  })
  const codes = lo.get(rsp, 'data.listCodeMasters.items') as GqlType.CodeMaster[]
  if (!!!codes) return []

  return lo.orderBy(codes, ['order']).map((item) => ({
    label: item.name,
    value: item.code,
  }))
}
