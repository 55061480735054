import React, { useState, useEffect } from 'react'
import { Fab, Fade } from '@mui/material'
import { VerticalAlignTopRounded as VerticalAlignTopRoundedIcon } from '@mui/icons-material'

const GotoTopButton = () => {
  const [isVisable, setIsVisable] = useState<boolean>(false)

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', _onSrcoll)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', _onSrcoll)
    }
  }, [])

  const _gotoTop = () => {
    document.getElementById('root')?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const _onSrcoll = () => {
    if (window.pageYOffset !== 0 && !isVisable) setIsVisable(true)
  }

  return (
    <Fade in={isVisable} timeout={{ exit: 500, enter: 500 }}>
      <Fab
        sx={(theme) => ({ position: 'fixed', right: theme.spacing(3), bottom: theme.spacing(3) })}
        color='secondary'
        onClick={_gotoTop}
      >
        <VerticalAlignTopRoundedIcon fontSize='large' />
      </Fab>
    </Fade>
  )
}

export default GotoTopButton
