import lo from 'lodash'
import React, { useState } from 'react'
import { Action } from '@reduxjs/toolkit'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'

import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
} from '@mui/material'

import { SYS_MSG, SYS_CMN } from 'src/resource'
import * as Type from 'src/js/type/base'

type Props = {
  id: string // ID
  label: string // ラベル
  refer: Type.Reference // 参照項目
  onChange: (item: { id: string; value: string }) => Action // イベント：値変更
  options?: { label: string; value: string }[]
  hasNoneOpt?: boolean // すべてオプションありない
  required?: boolean // 必須フラグ
  helperText?: string // ハイパーテキスト
  disabled?: boolean // 有効フラグ
  onError?: (id: string) => void // イベント：エラー発生
}
const StandardSelectRef = ({
  id,
  label,
  refer,
  onChange,
  hasNoneOpt = true,
  options,
  required = false,
  helperText = ' ',
  disabled = false,
  onError,
}: Props) => {
  const [state, setState] = useState<{ isError: boolean; msg: string }>({
    isError: false,
    msg: '',
  })
  const value = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as string
  const dispatch = useAppDispatch()

  const _onChange = (event: SelectChangeEvent<string>) => {
    const input = event.target.value

    // 必須チェックの場合
    if (required && !input) {
      setState({ isError: true, msg: SYS_MSG.ERRSS001 })
      onError && onError(id)
      dispatch(onChange({ id: id, value: input }))
      return
    }

    setState({ isError: false, msg: '' })
    dispatch(onChange({ id: id, value: input }))
  }

  return (
    <FormControl
      fullWidth={true}
      required={required}
      disabled={disabled}
      error={state.isError}
      size='small'
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        sx={{ mb: '0.2rem' }}
        id={id}
        labelId={`${id}-label`}
        value={value}
        onChange={_onChange}
        label={label}
      >
        {hasNoneOpt && (
          <MenuItem value=''>
            <em>{SYS_CMN.LBL_OPT_ALL}</em>
          </MenuItem>
        )}
        {options &&
          options.map((opt, idx) => (
            <MenuItem key={idx} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{state.isError ? state.msg : helperText}</FormHelperText>
    </FormControl>
  )
}

export default StandardSelectRef
