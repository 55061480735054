/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRoleAuth = /* GraphQL */ `
  mutation CreateRoleAuth($input: CreateRoleAuthInput!, $condition: ModelRoleAuthConditionInput) {
    createRoleAuth(input: $input, condition: $condition) {
      id
      roleId
      pageId
      roleType
      order
    }
  }
`
export const updateRoleAuth = /* GraphQL */ `
  mutation UpdateRoleAuth($input: UpdateRoleAuthInput!, $condition: ModelRoleAuthConditionInput) {
    updateRoleAuth(input: $input, condition: $condition) {
      id
      pageId
      roleType
      order
    }
  }
`
export const deleteRoleAuth = /* GraphQL */ `
  mutation DeleteRoleAuth($input: DeleteRoleAuthInput!, $condition: ModelRoleAuthConditionInput) {
    deleteRoleAuth(input: $input, condition: $condition) {
      id
    }
  }
`
