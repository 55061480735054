import React from 'react'

import { FileInfo as FileInfoType } from 'src/js/slice/B0011/type'
import { SendingPGVerLabel as BaseSendPGVerLabel } from 'src/js/component/Common/Label'

type Props = {
  data: { [key: string]: string }
}
const SendingPGVerLabel = ({ data }: Props) => {
  const fileInfo = data as unknown as FileInfoType
  return (
    <BaseSendPGVerLabel
      value={fileInfo && fileInfo.shipAppVersion ? fileInfo.shipAppVersion : ''}
    />
  )
}

export default SendingPGVerLabel
