import React, { useEffect } from 'react'

import { Auth } from 'aws-amplify'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import { Box, CircularProgress } from '@mui/material'
import { init as initMenu } from 'src/js/slice/Menu'
import { initUser, initAuth, setLoading } from 'src/js/slice/App'

import { systemError } from 'src/js/slice/App'

type Props = {
  children: React.ReactNode
}
const Initialization = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const appIsLoading = useAppSelector((state) => state.app.isLoading)

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(setLoading(true))

        // ユーザー初期化
        const { username } = await Auth.currentAuthenticatedUser()
        await dispatch(initUser(username))

        // メニュー初期化
        await dispatch(initAuth(username))

        // メニュー初期化
        await dispatch(initMenu(username))

        await dispatch(setLoading(false))
      } catch (error) {
        dispatch(systemError())
      }
    })()

    // リダイレクト
    // history.replace('/')
  }, [])

  // システムローディング
  if (!!appIsLoading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 45 }}>
        <CircularProgress />
      </Box>
    )

  return <>{children}</>
}
export default Initialization
