import axios, { AxiosResponse } from 'axios'
// import lo from 'lodash'

import { systemError, pushMessage } from 'src/js/slice/App'

import { AsyncThunkConfig } from 'src/js/type/sys'
import AppConfig from 'src/config/application'
import { APP_STATUS } from 'src/js/util/constants'
import { isEmpty } from './utility'

/**
 * API呼び出し（検索処理用）
 */
export const callSearchApi = async (url: string, param: object, thunkAPI: AsyncThunkConfig) => {
  let rsp
  try {
    rsp = await axios.post(`${AppConfig.application.context}${url}`, param)
  } catch (err) {
    thunkAPI.dispatch && thunkAPI.dispatch(systemError())
    throw err
  }

  // TODO 結果バリデーション
  validation(rsp, thunkAPI)

  return rsp
}

// /**
//  * API呼び出し（更新処理用）
//  */
// export const callUpdateApi = async (url, param, thunkAPI) => {

//     const dispatch = thunkAPI.dispatch;

//     // Backdrop開始
//     dispatch(backdropOn());

//     // API通信
//     let rsp;
//     try {
//         rsp = await axios.post(`${AppConfig.application.context}${url}`, param);
//     } catch (error) {
//         dispatch(backdropOff());   // Backdrop終了
//         dispatch(systemError({code: ERR_CD.SYS, error: error}));   // システムエラー遷移
//         throw error;
//     }

//     // 結果バリデーション
//     validation(rsp, thunkAPI);

//     // Backdrop終了
//     dispatch(backdropOff());

//     // 処理メッセージ通知
//     dispatch(pushMessage({ type: MSG_TYPE.SUCCESS, msg: SYS_MSG.INFO_PROC_FINISH }));

//     return rsp;
// }

/**
 * API処理結果チェック
 */
export const validation = (rsp: AxiosResponse, thunkAPI: AsyncThunkConfig) => {
  // パラメータチェック
  if (!thunkAPI || !thunkAPI.dispatch) {
    throw new Error('System Exception.')
  }
  if (!rsp) {
    thunkAPI.dispatch(systemError())
    throw new Error('System Exception.')
  }

  const dispatch = thunkAPI.dispatch

  // Axiosステータスチェック

  if (!rsp.status || rsp.status !== 200) {
    dispatch(systemError())
    throw new Error('System Exception.')
  }

  // 処理ステータスチェック（システムエラー）
  if (
    isEmpty(rsp.data) ||
    isEmpty(rsp.data.status) ||
    rsp.data.status === APP_STATUS.SYSTEM_ERROR
  ) {
    dispatch(systemError())
    throw new Error('System Exception.')
  }

  // 処理ステータスチェック（業務エラー）
  if (
    rsp.data.status === APP_STATUS.BUSINESS_ERROR ||
    rsp.data.status === APP_STATUS.VALIDATE_ERROR ||
    rsp.data.status === APP_STATUS.NODATA
  ) {
    dispatch(pushMessage({ type: 'error', error: rsp.data.error }))
    throw new Error(`Status: ${rsp.data.status}, Error: ${rsp.data.error}`)
  }

  return
}
