import lo from 'lodash'
import { API } from 'aws-amplify'

import * as Queries from './graphql/queries'
import * as Mutations from './graphql/mutations'
import * as GqlQueries from 'src/graphql/queries'
import { isMatch } from 'src/js/util/utility'
import { REGULAR_EXPR as REGEX } from 'src/js/util/constants'
import { SYS_MSG } from 'src/resource'

import * as GqlType from 'src/API'
import * as Type from './type'

export const search = async (pFilter: Type.Filter): Promise<GqlType.Role[]> => {
  const rsp = await API.graphql({
    query: Queries.listRoles,
    variables: { filter: _makeFilterInput(pFilter) },
  })
  const roles = lo.get(rsp, 'data.listRoles.items') as GqlType.Role[]

  return !!roles ? roles : []
}

export const findByKey = async (pId: string): Promise<GqlType.Role> => {
  const rsp = await API.graphql({ query: GqlQueries.getRole, variables: { id: pId } })
  const role = lo.get(rsp, 'data.getRole') as GqlType.Role

  return role
}

export const update = async (pInput: Type.Input): Promise<GqlType.Role> => {
  await API.graphql({
    query: Mutations.updateRole,
    variables: { input: { id: pInput.id, name: pInput.name, comment: pInput.comment } },
  })
  return await findByKey(pInput.id)
}

export const create = async (pInput: Type.Input): Promise<GqlType.Role> => {
  await API.graphql({
    query: Mutations.createRole,
    variables: { input: { id: pInput.id, name: pInput.name, comment: pInput.comment } },
  })
  return await findByKey(pInput.id)
}

export const deleteById = async (pId: string): Promise<void> => {
  await API.graphql({
    query: Mutations.deleteRole,
    variables: { input: { id: pId } },
  })
  return
}

export const validation = async (pInput: Type.Input): Promise<string[]> => {
  const errMsg = [] as string[]

  // Id
  if (!pInput.id) errMsg.push(`id:${SYS_MSG.ERRSS001}`)
  if (!!pInput.id && !isMatch(pInput.id, REGEX.HALF_N_E)) errMsg.push(`id:${SYS_MSG.ERRSS005}`)

  // Name
  if (!pInput.name) errMsg.push(`name:${SYS_MSG.ERRSS001}`)
  if (!!pInput.id && !isMatch(pInput.name, REGEX.HALF_N_E)) errMsg.push(`name:${SYS_MSG.ERRSS005}`)

  // Id重複チェック
  if (pInput.mode === 'new') {
    const role = await findByKey(pInput.id)
    if (!!role) errMsg.push(SYS_MSG.ERRSS006)
  }

  return errMsg
}

const _makeFilterInput = (pFilter: Type.Filter): GqlType.ModelRoleFilterInput => {
  const filter: GqlType.ModelRoleFilterInput = {}

  if (!!pFilter.name) {
    filter.name = { contains: pFilter.name }
  }

  if (!!pFilter.comment) {
    filter.comment = { contains: pFilter.comment }
  }

  return filter
}
