import React from 'react'

import { FileSumInfo as FileInfoSumType } from 'src/js/slice/B0010/type'
import { SendingPGVerLabel as BaseSendPGVerLabel } from 'src/js/component/Common/Label'

type Props = {
  data: { [key: string]: string }
}
const SendingPGVerLabel = ({ data }: Props) => {
  const fileSumInfo = data as unknown as FileInfoSumType
  return (
    <BaseSendPGVerLabel
      value={fileSumInfo && fileSumInfo.shipAppVersion ? fileSumInfo.shipAppVersion : ''}
    />
  )
}

export default SendingPGVerLabel
