import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'

import { TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import { StdPanel, RichTablePanelRef } from 'src/js/component/Base/Panel'
import {
  header,
  detail,
  gc,
  initPageOpts,
  input,
  initInput,
  update,
  deleteById,
} from 'src/js/slice/SYS0041'
import Header from './Header'
import Input from './Input'

import { isReadOnly, getFuncAuth } from 'src/js/util/utility'

import { SYS0040 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { AuthKbn as AuthKbnType } from 'src/js/type/base'

const SYS0041 = () => {
  const [open, setOpen] = useState<boolean>(false)
  const appAuth = useAppSelector((state) => state.app.auth)
  const funcAuth = useMemo(() => getFuncAuth(FUNC.SYS0040, appAuth), [appAuth]) as AuthKbnType

  const location = useLocation()
  const roleId = (location.state as { p1: string }).p1

  const isLoading = useAppSelector((state) => state.SYS0041.header.isLoading)
  const inputLoading = useAppSelector((state) => state.SYS0041.input.isLoading)
  const inputMode = useAppSelector((state) => state.SYS0041.input.mode)
  const dispatch = useAppDispatch()

  // 初期化
  useEffect(() => {
    // ヘッダー情報取得
    dispatch(header(roleId))

    // 明細一覧初期化
    dispatch(detail(roleId))

    // ページオプション初期化
    dispatch(initPageOpts())

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // 編集画面開く
  const _onOpenEdit = (row: TableRowType) => {
    setOpen(true)
    dispatch(input(row['id']))
  }

  // 新規画面開く
  const _onOpenNew = () => {
    dispatch(initInput())
    setOpen(true)
  }

  // 画面クローズ
  const _onClose = () => {
    setOpen(false)
  }

  // 登録
  const _onOK = () => {
    ;(async () => {
      await dispatch(update(roleId)) // 更新
      await dispatch(detail(roleId)) // 明細再検索
    })()
    setOpen(false)
  }

  // 削除
  const _onDelete = () => {
    ;(async () => {
      await dispatch(deleteById()) // 削除
      await dispatch(detail(roleId)) // 明細再検索
    })()
    setOpen(false)
  }

  // カラム定義
  const columns = [
    {
      key: 'id',
      label: RESOURCE.LBL_ID,
      align: 'left',
      type: 'button',
      onClick: (row) => {
        _onOpenEdit(row)
      },
    },
    { key: 'pageId', label: RESOURCE.LBL_PAGE_ID, align: 'left' },
    { key: 'page.name', label: RESOURCE.LBL_PAGE_NM, align: 'left' },
    { key: 'roleType', label: RESOURCE.LBL_ROLE_TYPE, align: 'left' },
  ] as TableColumnType[]

  return (
    <>
      {/* ロール情報 */}
      <StdPanel title={RESOURCE.TITLE_AUTH_HEADER} isLoading={isLoading}>
        <Header />
      </StdPanel>

      {/* ロール権限情報 */}
      <RichTablePanelRef
        title={RESOURCE.TITLE_AUTH_DETAIL}
        columns={columns}
        refer={{ funcId: FUNC.SYS0041, itemId: 'detail' }}
        addFlg={!isReadOnly(funcAuth)}
        onAdd={_onOpenNew}
      />

      {/* 入力ダイアログ */}
      <Input
        open={open}
        onClose={_onClose}
        onOK={_onOK}
        onDelete={_onDelete}
        isLoading={inputLoading}
        mode={isReadOnly(funcAuth) ? 'view' : inputMode}
      />
    </>
  )
}

export default SYS0041
