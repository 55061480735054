import React, { useState } from 'react'
import { Action } from '@reduxjs/toolkit'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import lo from 'lodash'

import { TextField } from '@mui/material'

import { isMatch } from 'src/js/util/utility'
import { SYS_MSG } from 'src/resource'
import * as Type from 'src/js/type/base'

type Props = {
  id: string
  label: string
  refer: Type.Reference // 参照項目
  onChange: (item: { id: string; value: string }) => Action // イベント：値変更
  regExp: string
  errMsg: string
  required?: boolean
  helperText?: string
  size?: 'small' | 'medium'
  disabled?: boolean
  onError?: (id: string) => void // イベント：エラー発生
}
const RegExpTextFieldRef = ({
  id,
  label,
  refer,
  regExp,
  errMsg,
  onChange,
  required = false,
  helperText = ' ',
  size = 'medium',
  disabled = false,
  onError,
}: Props) => {
  const [state, setState] = useState<{ isError: boolean; msg: string }>({
    isError: false,
    msg: '',
  })
  const value = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as string
  const dispatch = useAppDispatch()

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setState({ isError: false, msg: '' })

    //必須入力エラーの場合
    if (required && !input) {
      setState({ isError: true, msg: SYS_MSG.ERRSS001 })
      onError && onError(id)
      dispatch(onChange({ id: id, value: input }))
      return
    }

    //フォーマットチェック
    if (!isMatch(input, regExp)) {
      setState({ isError: true, msg: errMsg })
      onError && onError(id)
      dispatch(onChange({ id: id, value: input }))
      return
    }

    //通常
    setState({ isError: false, msg: '' })
    dispatch(onChange({ id: id, value: input }))
  }

  return (
    <TextField
      error={state.isError}
      required={required}
      id={id}
      label={label}
      helperText={state.isError ? state.msg : helperText}
      size={size}
      disabled={disabled}
      fullWidth={true}
      margin='none'
      value={value}
      onChange={_onChange}
    />
  )
}

export default RegExpTextFieldRef
