import React, { useState } from 'react'
import {
  Zoom,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
} from '@mui/material'
import { Done as DoneIcon, Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { colors } from '@mui/material'

import { CustomButton } from 'src/js/component/Base/Button'
import { ConfirmDialog } from 'src/js/component/Base/Dialog'
import { SYS_CMN, SYS_MSG } from 'src/resource'
import * as Type from 'src/js/type/base'

type Props = {
  title: string // タイトル
  open: boolean // オープンフラグ
  isLoading: boolean // ローディングフラグ
  mode: Type.DialogStatus // ダイアログモード
  children: React.ReactNode // 子ノード
  width?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' // 幅
  onCancel: () => void // イベント：キャンセル
  onOK?: () => void // イベント：OK
  onDelete?: () => void // イベント：削除
  delBtnFlg?: boolean
  updBtnFlg?: boolean
  btnOkLabel?: string // ボタンラベル

  custBtnFlg?: boolean // カスタマイズボタン有無フラグ
  CustBtn?: React.ReactNode // コンポ：カスタマイズボタン
}
const FormDialog = ({
  title,
  open,
  isLoading,
  mode,
  children,
  width = 'sm',
  onCancel,
  onOK,
  onDelete,
  btnOkLabel = SYS_CMN.BTN_UPDATE,
  delBtnFlg = true,
  updBtnFlg = true,

  custBtnFlg = false,
  CustBtn,
}: Props) => {
  const [cfmState, setCfmState] = useState<{
    open: boolean
    msg: string
    onOK: () => void
  }>({
    open: false,
    msg: SYS_MSG.INFO_SAVE_CONFIRM,
    onOK: () => {
      console.log('')
    },
  })

  const _onClick = (msg: string, func?: () => void) => {
    setCfmState({
      open: true,
      msg: msg,
      onOK: () => {
        setCfmState((prev) => ({ ...prev, open: false }))
        func && func()
        onCancel()
      },
    })
  }

  const _onCancel = (msg: string) => {
    if (mode === 'new' || mode === 'edit') {
      setCfmState({
        open: true,
        msg: msg,
        onOK: () => {
          setCfmState((prev) => ({ ...prev, open: false }))
          onCancel()
        },
      })
    } else {
      onCancel()
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') return
          onCancel()
        }}
        disableEscapeKeyDown={true}
        aria-labelledby='form-dialog-title'
        maxWidth={width}
        fullWidth={true}
        TransitionComponent={Zoom}
        transitionDuration={{ enter: 500, exit: 300 }}
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>

        <DialogContent dividers={true}>
          {!isLoading && children}
          {isLoading && (
            <Box sx={{ textAlign: 'center', pt: 1 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>

        <DialogActions disableSpacing={false}>
          {/* カスタマイズボタン */}
          {custBtnFlg && CustBtn}

          {/* 登録ボタン */}
          {(mode === 'new' || (mode === 'edit' && updBtnFlg === true)) && (
            <CustomButton
              sx={{ color: colors.green[600] }}
              label={btnOkLabel}
              onClick={() => {
                _onClick(SYS_MSG.INFO_SAVE_CONFIRM, onOK)
              }}
              variant='text'
              startIcon={<DoneIcon />}
              disabled={isLoading}
            />
          )}

          {/* 削除ボタン */}
          {(mode === 'edit' || mode === 'delete') && delBtnFlg && (
            <CustomButton
              sx={{ color: colors.red[500] }}
              label={SYS_CMN.BTN_DEL}
              onClick={() => {
                _onClick(SYS_MSG.INFO_DELETE_CONFIRM, onDelete)
              }}
              variant='text'
              startIcon={<DeleteIcon />}
              disabled={isLoading}
            />
          )}

          {/* キャンセルボタン */}
          <CustomButton
            sx={{ color: colors.blueGrey[500] }}
            label={SYS_CMN.BTN_CANCEL}
            onClick={() => {
              _onCancel(SYS_MSG.INFO_INPUT_HAIKI)
            }}
            variant='text'
            startIcon={<CloseIcon />}
            disabled={isLoading}
          />
        </DialogActions>
      </Dialog>

      {/* 確認ダイアログ */}
      <ConfirmDialog
        isOpen={cfmState.open}
        text={cfmState.msg}
        onOK={cfmState.onOK}
        onClose={() => {
          setCfmState((prev) => ({ ...prev, open: false }))
        }}
      />
    </>
  )
}

export default FormDialog
