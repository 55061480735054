import React from 'react'
import { useAppSelector } from 'src/js/slice/hooks'

import { CircularProgress, Backdrop as MuiBackdrop } from '@mui/material'

const Backdrop = () => {
  const openFlg = useAppSelector((state) => state.app.backdropFlg)

  // 成功、ワーニング、情報通知の場合
  return (
    <MuiBackdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 9999, color: '#fff' }}
      open={openFlg}
    >
      <CircularProgress color='inherit' />
    </MuiBackdrop>
  )
}
export default Backdrop
