// フレームワーク用
// ダイアログコンポーネントステータス

export const ERR_CD = {
  SYS: 'SYS001',
  AUTH: 'SYS002',
  // SESSION: "SYS003",

  BIZ: 'BIZ001',
}

export const DIALOG_STS = {
  NEW: '1',
  EDIT: '2',
  VIEW: '3',
}

// 処理ステータス
export const APP_STATUS = {
  NODATA: 0, // 処理結果(0：対象無し)
  OK: 1, // 処理結果(1：正常)
  VALIDATE_ERROR: 2, // 処理結果(2：バリデーションエラー)
  BUSINESS_ERROR: 3, // 処理結果(3：業務エラー)
  SESSION_ERROR: 4, // 処理結果(4：セッションエラー)
  ROLE_ERROR: 5, // 処理結果(5：権限エラー)
  INIT_PASSWORD: 6, // 処理結果(6：初期パスワード)
  PASSWORD_EXPIRED: 7, // 処理結果(7：パスワード期限切れ)
  ACCOUNT_LOCKED: 8, // 処理結果(8：アカウントロック中)
  NG: 9, // 処理結果(9：通常エラー)
  COUNT_OVER: 10, // 指定件数オーバー
  SYSTEM_ERROR: 99, // 処理結果(99：システムエラー)
}

export const MSG_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARN: 'warning',
  INFO: 'info',
}

// システム用
// 汎用区分
export const HANYO_KBN = {
  DISC: 'DISC_OPTS',
  ELC_INV: 'ELC_INV_OPTS',
  TM: 'TERMINAL_KBN',
  IMP_FILE: 'IMP_FILE_KBN',
  PRC_STS: 'PROCESS_STATUS',
  IMP_FMT: 'IMP_FMT_KBN',
  IMP_STS: 'IMP_STS',
  CNT_TYPE: 'CNT_TYPE',
  IMP_RLD: 'IMP_RLD',
  SIZE: 'SIZE',
  CUSS: 'CUSS',
  EXTRA: 'EXTRA',

  // 業務用OPERATION_STATUS
  SHIP_MANAGER: '101',
  ALIVE_STATUS: '102',
  OPERATION_STATUS: '103',
  FILE_TYPE: '104',
  FILE_STATUS: '105',
  TARGET_SHIP: '106',
  LIVE_CHECK: '107',
  EUMS_ON_BOARD: '108',
}

// ログインステータス
export const LOGIN_STATUS = {
  NO_CSRF: '0', // CSRFなし
  SUCCESS: '1', // 認証OK
  VALIDITION: '2', // バリデーションエラー
  LOCKED: '3', // アカウントロック
  WRONG_ROLE: '4', // パスワードルール違反
  RIGHT: '5', // 権限エラー
  INIT: '6', // 初期パスワード
  EARLY_DATE: '7', // パスワード期限切れ
  STOPED: '8', // 利用停止
  FAILURE: '9', // 認証エラー
  SYSTEM_ERROR: '99', // システムエラー
}

// エラータイプ
export const ERROR_TYPE = {
  BUSINESS_ERROR: 1,
  SYSTEM_ERROR: 9,
}

// コンボーネットステータス
export const COMP_STATUS = {
  INIT: 1,
  PROCESSING: 2,
  EDITED: 3,
  DISPLAY: 4,
  FINISH: 5,
}

// 管理者フラグ
export const KANRI_USER_FLG = {
  ADMIN: '1', // 管理者
  GENERAL: '0', // 一般者
}

// 機能ID
export const FUNCTION_ID = {
  TEST: 'TEST',
  SYS0010: 'SYS0010',
  SYS0020: 'SYS0020',
  SYS0030: 'SYS0030',
  SYS0040: 'SYS0040',
  SYS0041: 'SYS0041',

  APP: 'APP',
  HANYO: 'HANYO',
  MENU: 'MENU',
  APP_FOOTMARK: 'FOOTMARK',
  MM0010: 'MM0010',
  IE0010: 'IE0010',
  IE0020: 'IE0020',
  IE0021: 'IE0021',

  B0010: 'B0010',
  B0011: 'B0011',
  B0012: 'B0012',
  M0010: 'M0010',
}

// ページング区分
export const PAGING_KBN = {
  FIRST: '1',
  PRE: '2',
  NEXT: '3',
  LAST: '4',
}

// 日付フォーマット
export const DATE_FOMATER = {
  DEFAULT: 'YYYY/MM/DD',
  YYYYMMDD: 'YYYYMMDD',
  YYYYMMDD_HYPHEN: 'YYYY-MM-DD',
}

// 編集モード
export const EDIT_MODE = {
  NEW: 'C', // 新規
  EDIT: 'U', // 編集
  DELETE: 'D', // 削除
  REFER: '4', // 照会
}

// Bool区分
export const BOOL_KBN = {
  TRUE: '1',
  FALSE: '0',
}

// 表頭設定　削除不可フラグ
export const DEL_KBN = {
  DEL: 0, // 通常（削除できます）
  NO_DEL: 1, // 固定項目（削除できません）
}

//企業健康管理状況
export const TAB_ID = {
  TAB_TABLE: '1', //健康管理状況
  TAB_GAUGE: '2', //ゲージチャート
  TAB_RADAR: '3', //radarチャート
  TAB_LINE: '4', //lineチャート
  TAB_BAR: '5', //barチャート
}

// フラグ
export const CMN_FLG = {
  FLG_ON: '1',
  FLG_OFF: '0',
}

// コンポーネント区分
export const COMP_KBN = {
  MAIN: '1',
  SUB: '2',
  POPUP: '3',
}

// target Ship Kbn
export const TARGET_SHIP_KBN = {
  SELF_ALIVE: '0',
  ALL_ALIVE: '1',
  ALL: '2',
}

// Live Check Kbn
export const LIVE_CHECK_KBN = {
  NG: 'NG',
  RO: 'RO',
  RW: 'RW',
}

// File Type
export const FILE_TYPE = {
  CHECK: 'data',
  MASTER: 'config',
}

// File Status
export const FILE_STATUS = {
  RECEIVED: 'Received',
  COMPLETED: 'Completed',
  EUMS_ERROR: 'eUMS Error',
  CONFIRMED_ERROR: 'Confirmed Error',
  LIVE_ERROR: 'LiVE Error',
  WADATSUMI_ERR: 'WADATSUMI Error',
  RECOVERED: 'Received',
  NON: '6',
}

//正規表現
export const REGULAR_EXPR = {
  HALF_N_E_K: '[a-zA-Z0-9｡-ﾟ() +]+', //半角英数カナ
  HALF_N_E: '[a-zA-Z0-9]+', //半角英数
  HALF_N_E_HYPHEN: '[a-zA-Z0-9-_]+', //半角英数ハイフン
  HALF_N: '^[0-9]+$', //半角数字
  HALF_N_FUGO: '^[a-zA-Z0-9!-/:-@¥[-`{-~]+$', //半角英数記号
}

export const GRAPHQL = {
  MAX_CNT: 30000,
}

// alive status
export const ALIVE_STATUS = {
  ALIVE: 'Alive',
  QUIT: 'Quit',
}

// eUMS on board
export const EUMS_ON_BOARD = {
  ON_BOARD: '0',
  REMOVED: '1',
}
