import React, { useEffect, useState, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from 'src/js/slice/hooks'
import { useNavigate } from 'react-router-dom'

import { Box, Alert, AlertTitle } from '@mui/material'
import { RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import Filter from './Filter'
import SNAlert from './SNAlert'
import Equipment from './Equipment'

import OperationStatusSelect from './OperationStatusSelect'
import LastCheckedDateLabel from './LastCheckedDateLabel'
import SenderLabel from './SenderLabel'
import LastCommentLink from './LastCommentLink'
import SeqNoLabel from './SeqNoLabel'
import ImoLabel from './ImoLabel'
import SendingPGVerLabel from './SendingPGVerLabel'

import {
  initFilter,
  clearFilter,
  search,
  gc,
  cancelFilter,
  restorePageSession,
  inputEquipment,
  updateEquipment,
} from 'src/js/slice/B0010'
import { savePageSession } from 'src/js/slice/App'

import { B0010 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { formatDateTimeByUS } from 'src/js/util/utility'
import { isReadOnly, getFuncAuth } from 'src/js/util/utility'
import { AuthKbn as AuthKbnType } from 'src/js/type/base'

const B0010 = () => {
  const [openEquipment, setOpenEquipment] = useState<boolean>(false)
  const user = useAppSelector((state) => state.app.user)
  const appAuth = useAppSelector((state) => state.app.auth)
  const funcAuth = useMemo(() => getFuncAuth(FUNC.B0010, appAuth), [appAuth]) as AuthKbnType

  const equipmentLoading = useAppSelector((state) => state.B0010.equipment.isLoading)
  const equipmentMode = useAppSelector((state) => state.B0010.equipment.mode)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // 初期化
  useEffect(() => {
    ;(async () => {
      // 検索条件初期化
      await dispatch(
        initFilter({ targetShipKbn: user?.targetShipKbn, shipManagerShort: user?.shipManagerShort })
      )

      // ページセッション復元
      await dispatch(restorePageSession())

      // 検索処理
      await dispatch(search())
    })()

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [user?.targetShipKbn, user?.shipManagerShort])

  // 画面遷移（DATA LIST）
  const _gotoB0011 = (row: TableRowType) => {
    // ステータス保存
    dispatch(savePageSession(FUNC.B0010))

    // 画面遷移
    navigate('/B0011', { state: { p1: row.imoNo, p2: row.vesselName } })
  }

  // 画面遷移（COMMENT）
  const _gotoB0012 = (row: TableRowType) => {
    // ステータス保存
    dispatch(savePageSession(FUNC.B0010))

    // 画面遷移
    navigate('/B0012', { state: { p1: row.imoNo } })
  }

  // 画面クローズ
  const _onCloseEquipment = () => {
    setOpenEquipment(false)
  }

  // 編集画面開く
  const _onOpenEquipment = (row: TableRowType) => {
    setOpenEquipment(true)
    dispatch(inputEquipment(row['imoNo']))
  }

  // 登録
  const _onOK = () => {
    ;(async () => {
      await dispatch(updateEquipment()) // 更新
    })()
    setOpenEquipment(false)
  }

  // カラム定義
  const columns = [
    {
      key: 'seqNo',
      label: RESOURCE.LBL_SEQ_NO,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }, index: number) => <SeqNoLabel index={index + 1} />,
      sortable: false,
    },
    {
      key: 'imoNo',
      label: RESOURCE.LBL_IMO_NO,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <ImoLabel data={row} onClick={_gotoB0011} />,
      // sortable: false,
    },
    { key: 'vessel.shipManagerShort', label: RESOURCE.LBL_SHIP_MANAGER, align: 'left' },
    {
      key: 'operationStatus',
      label: RESOURCE.LBL_OPERATION_STATUS,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <OperationStatusSelect data={row} />,
      // sortable: false,
    },
    {
      key: 'lastCheckedDate',
      label: RESOURCE.LBL_LAST_CHECKED_DATE,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <LastCheckedDateLabel data={row} />,
    },
    {
      key: 'uploadedDate',
      label: RESOURCE.LBL_UPLOADED_DATE_UTC,
      align: 'left',
      format: (value: string) => formatDateTimeByUS(value, 'UTC'),
    },
    {
      key: 'sender',
      label: RESOURCE.LBL_SENDER,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <SenderLabel data={row} />,
    },
    { key: 'numberOfRecord', label: RESOURCE.LBL_CHECK_FILE, align: 'left' },
    { key: 'numberOfError', label: RESOURCE.LBL_ERROR, align: 'left' },
    {
      key: 'lastCommentContent',
      label: RESOURCE.LBL_COMMENT,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => (
        <LastCommentLink data={row} onClick={_gotoB0012} />
      ),
    },
    {
      key: 'shipAppVersion',
      label: RESOURCE.LBL_APP_VER,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <SendingPGVerLabel data={row} />,
    },
    { key: 'setting.systemVersion', label: RESOURCE.LBL_PC_EUMS_VER, align: 'left' },
    {
      key: 'equipmentBtn',
      label: RESOURCE.LBL_EQUIPMENT,
      align: 'left',
      type: 'button',
      defaultValue: RESOURCE.LBL_EQUIPMENT_DISP,
      onClick: (row) => {
        _onOpenEquipment(row)
      },
    },
    { key: 'vessel.classification', label: RESOURCE.LBL_VESSEL_TYPE, align: 'left' },
    { key: 'vessel.businessSection', label: RESOURCE.LBL_BUSINESS_SECTION, align: 'left' },
    { key: 'vessel.managementScheme', label: RESOURCE.LBL_MGMT_SCHEME, align: 'left' },
    {
      key: 'confirmedFlag',
      label: RESOURCE.LBL_SN_ALERT,
      align: 'left',
      type: 'custom',
      custom: (row: { [key: string]: string }) => <SNAlert data={row} />,
    },
  ] as TableColumnType[]

  const csvInfo = {
    fileName: 'vesselList.csv',
    keys: [
      'imoNo',
      'vesselName',
      'vessel.shipManagerShort',
      'operationStatus',
      'lastCheckedDate',
      'uploadedDate',
      'sender',
      'numberOfRecord',
      'numberOfError',
      'lastCommentContent',
      'shipAppVersion',
      'setting.systemVersion',
      'vessel.classification',
      'vessel.businessSection',
      'vessel.managementScheme',
      'vessel.aliveStatus',
      'vessel.eumsUnavailableFlag',
    ],
    title: [
      RESOURCE.LBL_IMO_NO,
      RESOURCE.LBL_VESSEL_NAME,
      RESOURCE.LBL_SHIP_MANAGER,
      RESOURCE.LBL_OPERATION_STATUS,
      RESOURCE.LBL_LAST_CHECKED_DATE,
      RESOURCE.LBL_UPLOADED_DATE_UTC,
      RESOURCE.LBL_SENDER,
      RESOURCE.LBL_CHECK_FILE,
      RESOURCE.LBL_ERROR,
      RESOURCE.LBL_COMMENT,
      RESOURCE.LBL_PC_EUMS_VER,
      RESOURCE.LBL_APP_VER,
      RESOURCE.LBL_VESSEL_TYPE,
      RESOURCE.LBL_BUSINESS_SECTION,
      RESOURCE.LBL_MGMT_SCHEME,
      RESOURCE.LBL_ALIVE_STATUS,
      RESOURCE.LBL_EUMS_REMOVED,
    ],
  }

  if (!!!funcAuth || funcAuth === 'NG')
    return (
      <Box>
        <Alert severity='info' variant='outlined'>
          <AlertTitle>CAUTION</AlertTitle>
          <Box sx={{ flexDirection: 'column' }}>
            <p>eUMS Support will contact you once your registration is complete.</p>
            <p>If you have any questions, please contact “NYKJP.S.EUMS_SUPPORT@nykgroup.com”.</p>
          </Box>
        </Alert>
      </Box>
    )

  // 画面描画
  return (
    <>
      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={RESOURCE.TITLE}
        Filter={<Filter targetShipKbn={user?.targetShipKbn} />}
        columns={columns}
        refer={{ funcId: FUNC.B0010, itemId: 'result' }}
        onSearch={() => {
          dispatch(search())
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(
            clearFilter({
              targetShipKbn: user?.targetShipKbn,
              shipManagerShort: user?.shipManagerShort,
            })
          )
        }}
        filterKey={{ funcId: FUNC.B0010, filterId: 'filter' }}
        displayMode='page'
        tblStickyHeader={true}
        exportFlg={true}
        csv={csvInfo}
        pageCount={200}
      />

      {/* 設備ダイアログ */}
      <Equipment
        open={openEquipment}
        onOK={_onOK}
        onClose={_onCloseEquipment}
        isLoading={equipmentLoading}
        mode={isReadOnly(funcAuth) ? 'view' : equipmentMode}
      />
    </>
  )
}
export default B0010
