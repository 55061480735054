import React from 'react'
import {
  Zoom,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material'
import { colors } from '@mui/material'

import { CustomButton } from 'src/js/component/Base/Button'
import { SYS_CMN } from 'src/resource'

type Props = {
  title?: string
  text: string
  isOpen: boolean
  onClose: () => void
  onOK: () => void
}
const ConfirmDialog = ({
  title = SYS_CMN.TITLE_CONFIRM_DLG,
  text,
  isOpen,
  onClose,
  onOK,
}: Props) => {
  // const [cfmOpen, setCfmOpen] = useState<boolean>(false)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      // TODO
      // disableBackdropClick={true}
      TransitionComponent={Zoom}
      transitionDuration={{ enter: 300, exit: 300 }}
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
      </DialogContent>

      <DialogActions disableSpacing={false}>
        <CustomButton
          sx={{ color: colors.green[600] }}
          label={SYS_CMN.BTN_TIP_OK}
          onClick={onOK}
          variant='text'
          startIcon={<DoneIcon />}
        />
        <CustomButton
          sx={{ color: colors.blueGrey[500] }}
          label={SYS_CMN.BTN_CANCEL}
          onClick={onClose}
          variant='text'
          startIcon={<CloseIcon />}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
