import React from 'react'
import { createRoot } from 'react-dom/client'
// import { StyledEngineProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { store } from 'src/js/slice/store'
// TODO
// import { CookiesProvider } from 'react-cookie';
// import Mock from 'src/js/component/Base/App/Mock'
import App from 'src/js/component/Base/App'
import reportWebVitals from './reportWebVitals'

// 外部CSS
import 'src/css/index.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <Provider store={store}>
    {/* <Mock /> */}
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
