import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/js/slice/hooks'

import { Snackbar, AlertTitle, Alert } from '@mui/material'
import { closeMessage } from 'src/js/slice/App'
import { SYS_CMN } from 'src/resource'

const Message = () => {
  const state = useAppSelector((state) => state.app.message)
  const dispatch = useAppDispatch()

  // エラーメッセージの場合
  if (state?.type === 'error') {
    return (
      <Snackbar
        open={state.openFlg}
        autoHideDuration={30000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          dispatch(closeMessage())
        }}
      >
        <Alert
          // variant={"filled"}
          severity={state.type}
          onClose={() => {
            dispatch(closeMessage())
          }}
        >
          <AlertTitle>{SYS_CMN.TITLE_ERR_MSG}</AlertTitle>
          {state.error?.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </Alert>
      </Snackbar>
    )
  }

  // 成功、ワーニング、情報通知の場合
  return (
    <Snackbar
      open={state?.openFlg}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => {
        dispatch(closeMessage())
      }}
    >
      <Alert severity={state?.type}>{state?.message}</Alert>
    </Snackbar>
  )
}
export default Message
