/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listMenus = /* GraphQL */ `
  query listMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        icon
        menuItem {
          items {
            id
            menuId
            name
            order
            pageId
            page {
              id
              name
              funcId
              action
              order
            }
          }
        }
      }
    }
  }
`
