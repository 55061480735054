import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import lo from 'lodash'

import { pushMessage } from 'src/js/slice/App'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { SYS_MSG } from 'src/resource'
import * as Type from 'src/js/type/base'
import { State as StateType } from './type'

import * as Api from './api'

export const test = () => {
  return '2'
}

// 非同期処理定義
export const init = createAsyncThunk<Type.Menu[], string>(
  `${FUNC.MENU}/init`,
  async (userId, thunkAPI) => {
    try {
      // メニュー初期化
      const menus = await Api.init(userId)
      return menus
    } catch (err) {
      // エラー通知
      thunkAPI.dispatch(pushMessage({ type: 'error', error: [SYS_MSG.ERRSS004] }))
      return []
    }
  }
)

// Slice定義
const getInitialState = (): StateType => ({
  list: [],
  isLoading: true,
})
export const MENU = createSlice({
  name: FUNC.MENU,
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(init.pending, (state) => {
        state.isLoading = true
      })
      .addCase(init.fulfilled, (state, action) => {
        const menus = action.payload
        state.list = menus || []
        state.isLoading = false
      })
      .addCase(init.rejected, (state) => {
        const initState = getInitialState()
        state.isLoading = initState.isLoading
        state.list = initState.list
      })
  },
})

// export const { } = MENU.actions;

export default MENU.reducer
