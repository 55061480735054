import React from 'react'

import { setFilter } from 'src/js/slice/M0010'
import { StdTextFieldRef } from 'src/js/component/Base/TextField'
import { HanyoSelectRef } from 'src/js/component/Base/Select'
import { FilterCtnGrid, DrawerItmGrid } from 'src/js/component/Base/Grid'
import { HANYO_KBN } from 'src/js/util/constants'
import { M0010 as RESOURCE } from 'src/resource'

const Filter = () => {
  return (
    <FilterCtnGrid>
      {/* IMO No. */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='imoNo'
          label={RESOURCE.LBL_IMO_NO}
          size='small'
          refer={{ funcId: 'M0010', partId: 'filter', itemId: 'imoNo' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Vessel Name */}
      <DrawerItmGrid>
        <StdTextFieldRef
          id='vesselName'
          label={RESOURCE.LBL_VESSEL_NAME}
          size='small'
          refer={{ funcId: 'M0010', partId: 'filter', itemId: 'vesselName' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Ship Manager */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'shipManager'}
          label={RESOURCE.LBL_SHIP_MANAGER}
          hanyoKbn={HANYO_KBN.SHIP_MANAGER}
          refer={{ funcId: 'M0010', partId: 'filter', itemId: 'shipManager' }}
          onChange={setFilter}
        />
      </DrawerItmGrid>

      {/* Alive Status */}
      <DrawerItmGrid>
        <HanyoSelectRef
          id={'aliveStatus'}
          label={RESOURCE.LBL_ALIVE_STATUS}
          hanyoKbn={HANYO_KBN.ALIVE_STATUS}
          refer={{ funcId: 'M0010', partId: 'filter', itemId: 'aliveStatus' }}
          onChange={setFilter}
          // disabled={targetShipKbn !== TARGET_SHIP_KBN.ALL}
        />
      </DrawerItmGrid>
    </FilterCtnGrid>
  )
}
export default Filter
