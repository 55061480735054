import React from 'react'
import { Drawer, Divider, List, ListItem, Skeleton } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useAppSelector } from 'src/js/slice/hooks'

import Toolbar from './Toolbar'
import Menu from './Menu'

import * as Style from './style'

const MenuLoading = () => {
  const loops = [1, 2, 3, 4, 5]
  return (
    <List>
      {loops.map((props, idxMenu) => (
        <ListItem sx={{ px: 1.5, py: 0 }} key={idxMenu}>
          <Skeleton variant='circular' width={35} height={35} sx={{ mr: 1.5 }} />
          <Skeleton variant='text' width={165} height={60} />
        </ListItem>
      ))}
    </List>
  )
}

type Props = {
  openFlg: boolean
  setOpenFlg: (openFlg: boolean) => void
}
const Sidebar = ({ openFlg, setOpenFlg }: Props) => {
  const isLoading = useAppSelector((state) => state.menu.isLoading)
  const menu = useAppSelector((state) => state.menu.list)

  return (
    <Drawer
      variant='permanent'
      sx={[Style.sidebar, openFlg ? Style.sidebarShift : Style.sidebarClps]}
      PaperProps={{ sx: openFlg ? Style.sidebarShift : Style.sidebarClps }}
    >
      <PerfectScrollbar>
        {/* 折り畳みボタン */}
        <Toolbar openFlg={openFlg} />

        {/* 分割 */}
        <Divider sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} />

        {/* ローディング */}
        {isLoading && <MenuLoading />}

        {/* サイドバーメニューアイテム */}
        {!isLoading && <Menu data={menu} openFlg={openFlg} setOpenFlg={setOpenFlg} />}
      </PerfectScrollbar>
    </Drawer>
  )
}

export default Sidebar
